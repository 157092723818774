import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Parse from "parse";
import Cookies from "js-cookie";

import {AppThunk} from ".";
import {pendingReducer, rejectedReducer} from "./helpers";

type State = {
  isLogged: boolean;
  error: string | null;
  loading: boolean;
};

const initialState: State = {
  isLogged: false,
  error: null,
  loading: false,
};

/**
 * Thunks
 */
export const login = createAsyncThunk(
  "auth/login",
  async ({email, password}: {email: string; password: string}) => {
    const user = await Parse.User.logIn(email, password);
    Cookies.set("authToken", user.getSessionToken());
  },
);

export const logout = (): AppThunk => (dispatch) => {
  Parse.User.logOut();
  Cookies.remove("authToken");
  dispatch(resetAuth());
};

/**
 * Reducers
 */
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetForm(state) {
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(login.pending, pendingReducer);
    builder.addCase(login.rejected, rejectedReducer);
    builder.addCase(login.fulfilled, (state: State) => {
      state.isLogged = true;
      state.loading = false;
      state.error = null;
    });
  },
});

export const {reset: resetAuth, resetForm: resetAuthForm} = authSlice.actions;

export default authSlice.reducer;
