import React, {FC, useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import Parse from "parse";
import {gql} from "@apollo/client";
import {useDispatch} from "react-redux";
import {sortBy} from "lodash";

import Layout from "components/Layout";
import BrowserLayout from "components/Browser/BrowserLayout";
import {BrowserFooter, BrowserHeader} from "components/Browser";
import useSearch from "hooks/useSearch";
import useExportJsonFile from "hooks/useExportJsonFile";
import useQueryFilters from "hooks/useQueryBuilder";
import useBrowserState from "hooks/useBrowserState";
import BrowserContent from "components/Browser/BrowserContent";
import useVocabularyPageData from "./useVocabularyPageData";
import usePageInfoData from "hooks/usePageInfoData";
import Panel, {FormInputs} from "./Panel";
import {
  GetVocabulariesQuery,
  useCreateVocabularyMutation,
  useUpdateVocabularyMutation,
} from "queries";
import exportVocabulary from "./export";
import {updatePresenceStatus, usePresenceSelector} from "state/presence";
import uploadAudio from "helpers/uploadAudio";
import getFrequency from "helpers/getFrequency";

const COLUMNS = [
  {label: "Id", width: 0.1, key: "objectId"},
  {label: "Hanzi", width: 0.1, key: "hanzi"},
  {label: "Pinyin", width: 0.2, key: "pinyin"},
  {label: "Lists", width: 0.2, key: "listsLabel"},
  {label: "English", width: 0.3, key: "english"},
  {label: "Status", width: 0.1, key: "status"},
];

const Vocabulary: FC = () => {
  const {addToast} = useToasts();
  const dispatch = useDispatch();
  const presences = usePresenceSelector("Vocabulary");
  const [updateLoading, setUpdateLoading] = useState(false);
  const {search, onSearch} = useSearch();
  const [audioFile, setAudioFile] = useState<null | any>(null);
  const {isCreateMode, selectedRow, selectRow, createMode} = useBrowserState();
  const {
    loading: exportVocabsMobileLoading,
    exportJsonFile: exportVocabsMobile,
  } = useExportJsonFile("vocabulary-mobile-export");
  const {
    loading: exportListsLoading,
    exportJsonFile: exportListsMobile,
  } = useExportJsonFile("lists-export");
  const {lists, currentUser} = usePageInfoData();

  const {filter, query} = useQueryFilters(
    {
      value: search,
      query: (value: string) => ({
        OR: [{hanzi: {matchesRegex: value}}, {english: {matchesRegex: value}}],
      }),
    },
    [
      {
        value: "status",
        label: "Status",
        query: (value: string) => ({status: {equalTo: value}}),
        values: [
          {value: "approved", label: "🟢‎‎ ‎‎‎ Approved"},
          {value: "rejected", label: "🔴‎ ‎‎ Rejected"},
          {value: "pending", label: "⚪‎ ‎ ‎‎Pending"},
        ],
      },
      {
        value: "audio",
        label: "Audio",
        query: (value: string) => {
          return value ? {audio: {notEqualTo: ""}} : {audio: {equalTo: ""}};
        },
        values: [
          {value: undefined, label: "No audio"},
          {value: true, label: "Has audio"},
        ],
      },
      {
        value: "sentences",
        label: "Sentence",
        query: (value: string) => {
          return value
            ? {
                AND: [
                  {sentences: {notEqualTo: []}},
                  {sentences: {exists: true}},
                ],
              }
            : {OR: [{sentences: {equalTo: []}}, {sentences: {exists: false}}]};
        },
        values: [
          {value: undefined, label: "No sentences"},
          {value: true, label: "Has sentences"},
        ],
      },
      {
        value: "lists",
        label: "List",
        query: (objectId: string) => ({
          lists: {
            contains: {
              __type: "Pointer",
              className: "List",
              objectId,
            },
          },
        }),
        values: lists.map((list: {objectId: any; name: any}) => ({
          value: list.objectId,
          label: list.name,
        })),
      },
    ],
  );

  const {data, loading, loadMore} = useVocabularyPageData(query);

  const [updateVocabulary] = useUpdateVocabularyMutation({});

  const [
    createVocabulary,
    {loading: createLoading},
  ] = useCreateVocabularyMutation({
    update(cache, {data}) {
      const newItem = data?.createVocabulary?.vocabulary;

      const existingItem = cache.readQuery<GetVocabulariesQuery>({
        query: GET_VOCABULARIES,
      });

      if (newItem && existingItem) {
        cache.writeQuery({
          query: GET_VOCABULARIES,
          data: {
            vocabularies: {
              edges: [...existingItem.vocabularies?.edges!, newItem],
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    dispatch(updatePresenceStatus("Vocabulary", ""));
  }, []);

  const onApprovalHandler = (approved: boolean) => {
    updateVocabulary({
      variables: {
        id: data.items[selectedRow!].objectId,
        fields: {
          status: approved ? "approved" : "rejected",
          lastApprovedAt: new Date(),
          approvedBy: {
            link: currentUser?.objectId,
          },
        },
      },
    });
  };

  const getFormattedFields = (form: FormInputs) => {
    const {pinyin, hanzis, english, lists, audio} = form;

    const hanziField = hanzis.map((hanzi) => hanzi.label).join("");

    const hanzisField = hanzis.map((hanzi) => ({
      __type: "Pointer",
      className: "Hanzi",
      objectId: hanzi.val,
    }));

    const pinyinField = pinyin?.map((pin) => pin.val).join(" ") || null;

    const listsField = lists.map((list) => ({
      __type: "Pointer",
      className: "List",
      objectId: list.value,
    }));

    const sortedEnglish = sortBy(
      english,
      (item) => (item.speechPart && item.speechPart.value) || "",
      ["asc"],
    );

    const englishDict = sortedEnglish.reduce((acc, row) => {
      const id = row?.speechPart?.value || null;
      if (!id) return acc;
      acc[id] = acc[id] ? [...acc[id], row.definition] : [row.definition];
      return acc;
    }, {} as any);

    const englishField = Object.values(englishDict)
      .map((row: any) => row.join(", "))
      .join("; ");

    const speechPartField = Object.keys(englishDict);

    return {
      hanzi: hanziField,
      hanzis: hanzisField,
      pinyin: pinyinField,
      lists: listsField,
      english: englishField,
      speechPart: speechPartField,
      audio: audio,
    };
  };

  const onUpdateHandler = async (form: FormInputs) => {
    const {
      hanzi,
      hanzis,
      pinyin,
      lists,
      english,
      speechPart,
      audio,
    } = getFormattedFields(form);
    setUpdateLoading(true);

    let audioUrl = audio;

    if (audioFile) {
      audioUrl = await uploadAudio(
        audioFile,
        `${data.items[selectedRow!].objectId}.aac`,
        "vocabulary",
      );
    }

    updateVocabulary({
      variables: {
        id: data.items[selectedRow!].objectId,
        fields: {
          hanzi,
          hanzis,
          pinyin,
          lists,
          english,
          speechPart,
          audio: audioUrl,
          frequency: getFrequency(hanzi),
          status: "pending",
          lastUpdatedAt: new Date(),
          approvedBy: {
            link: null,
          },
          lastUpdatedBy: {
            link: currentUser?.objectId,
          },
        },
      },
    }).then((res) => {
      if (res.errors) {
        addToast(res.errors[0].message);
        setUpdateLoading(false);
      } else {
        addToast("Updated successfully.");
        setAudioFile(null);
        setUpdateLoading(false);
      }
    });
  };

  const onSaveHandler = async (form: FormInputs) => {
    const {
      hanzi,
      hanzis,
      pinyin,
      lists,
      english,
      speechPart,
      audio,
    } = getFormattedFields(form);
    setUpdateLoading(true);

    createVocabulary({
      variables: {
        fields: {
          hanzi,
          hanzis,
          pinyin,
          lists,
          english,
          speechPart,
          audio,
          lastUpdatedBy: {
            link: currentUser?.objectId,
          },
          lastUpdatedAt: new Date(),
          status: "pending",
          frequency: getFrequency(hanzi),
        },
      },
    }).then(async (res) => {
      if (res.errors) {
        addToast(res.errors[0].message);
        setUpdateLoading(false);
      } else {
        if (audioFile) {
          const objectId = res.data!.createVocabulary!.vocabulary.objectId;

          const audioUrl = await (audioFile
            ? uploadAudio(audioFile, `${objectId}.aac`, "vocabulary")
            : audio);

          updateVocabulary({
            variables: {
              id: objectId,
              fields: {
                audio: audioUrl,
              },
            },
          }).then((res) => {
            if (res.errors) {
              addToast(res.errors[0].message);
              setUpdateLoading(false);
            } else {
              addToast("Updated successfully.");
              selectRow(null);
              setAudioFile(null);
              setUpdateLoading(false);
            }
          });
        } else {
          addToast("Updated successfully.");
          selectRow(null);
          setAudioFile(null);
          setUpdateLoading(false);
        }
      }
    });
  };

  const onExportHandler = async () => {
    await exportVocabulary(exportVocabsMobile, exportListsMobile);
    addToast("Export completed.");
  };

  const onUploadAudioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAudioFile(event.target.files![0]);
  };

  return (
    <Layout>
      <BrowserLayout>
        <BrowserLayout.Browser>
          <BrowserHeader
            title="Vocabulary"
            role={currentUser?.role || null}
            usersPresence={presences}
            search={search}
            filter={filter}
            onSearch={onSearch}
            onCreateClick={() => {
              createMode();
              dispatch(updatePresenceStatus("Vocabulary", ""));
            }}
            onExportClick={onExportHandler}
          />
          <BrowserContent
            selectedRow={selectedRow}
            columns={COLUMNS}
            data={data.items}
            loading={loading}
            onRowClick={(i: number) => {
              dispatch(
                updatePresenceStatus("Vocabulary", data.items[i].objectId),
              );
              selectRow(i);
            }}
            onLoadMore={loadMore}
            hasNextPage={data.hasNextPage}
            count={data.count}
          />
          <BrowserFooter
            loading={loading || exportVocabsMobileLoading || exportListsLoading}
            count={data.count}
          />
        </BrowserLayout.Browser>
        <BrowserLayout.Pane>
          <Panel
            isCreateMode={isCreateMode}
            role={currentUser?.role || null}
            loading={updateLoading || createLoading}
            presences={presences}
            onSave={onSaveHandler}
            onUpdate={onUpdateHandler}
            onApproval={onApprovalHandler}
            onUploadAudio={onUploadAudioHandler}
            data={selectedRow !== null && data.items[selectedRow]}
            lists={lists}
          />
        </BrowserLayout.Pane>
      </BrowserLayout>
    </Layout>
  );
};

export const VOCABULARY_FRAGMENT = gql`
  fragment vocabularyFragment on Vocabulary {
    id
    objectId
    hanzi
    pinyin
    english
    audio
    instagram
    frequency
    hanzis {
      ... on Hanzi {
        objectId
        character
        medians {
          ... on Element {
            value
          }
        }
        strokes {
          ... on Element {
            value
          }
        }
      }
    }
    speechPart {
      ... on Element {
        value
      }
    }
    lists {
      ... on List {
        objectId
        name
        list
        sublist
      }
    }
    sentences {
      ... on Sentence {
        english
        words {
          ... on Element {
            value
          }
        }
        objectId
      }
    }
    status
    lastUpdatedAt
    lastApprovedAt
    approvedBy {
      name
      avatar {
        url
      }
    }
    lastUpdatedBy {
      name
      avatar {
        url
      }
    }
  }
`;

export const GET_VOCABULARIES = gql`
  query getVocabularies($filter: VocabularyWhereInput, $cursor: String) {
    vocabularies(where: $filter, after: $cursor) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...vocabularyFragment
        }
      }
    }
  }
  ${VOCABULARY_FRAGMENT}
`;

export const UPDATE_VOCABULARY = gql`
  mutation updateVocabulary($id: ID!, $fields: UpdateVocabularyFieldsInput) {
    updateVocabulary(input: {id: $id, fields: $fields}) {
      clientMutationId
      vocabulary {
        ...vocabularyFragment
      }
    }
  }
  ${VOCABULARY_FRAGMENT}
`;

export const CREATE_VOCABULARY = gql`
  mutation CreateVocabulary($fields: CreateVocabularyFieldsInput!) {
    createVocabulary(input: {fields: $fields}) {
      clientMutationId
      vocabulary {
        ...vocabularyFragment
      }
    }
  }
  ${VOCABULARY_FRAGMENT}
`;

export default Vocabulary;
