import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";

interface Props {
  label: string;
  className?: string;
}

const FormRow: FC<Props> = ({className, label, children}) => {
  return (
    <div className={concatStyles([styles.container, className])}>
      <span className={styles.label}>{label}</span>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default FormRow;
