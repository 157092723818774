import {useCallback, useEffect, useRef, useState} from "react";

const useAnimation = (duration: number, loop = false) => {
  const [animation, setAnimation] = useState(0);
  const startTime = useRef<any>();
  const value = useRef<any>();
  const requestRef = useRef<any>();

  const ease = (x: number) => -Math.cos(x * Math.PI) / 2 + 0.5;

  const tick = useCallback(
    (timing: number) => {
      const progress = Math.min(1, (timing - startTime.current) / duration);
      const easedProgress = ease(progress);
      value.current = easedProgress;
      setAnimation(value.current);

      if (progress < 1) {
        requestAnimationFrame(tick);
      } else if (progress >= 1 && loop) {
        trigger();
      }
    },
    [duration],
  );

  const trigger = useCallback(() => {
    setAnimation(0);
    startTime.current = performance.now();
    requestAnimationFrame(tick);
  }, [tick]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(tick);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return {animation, trigger};
};

export default useAnimation;
