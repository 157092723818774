export function distance(coordsA: number[], coordsB: number[]) {
  return Math.sqrt(
    Math.pow(coordsA[0] - coordsB[0], 2) + Math.pow(coordsA[1] - coordsB[1], 2),
  );
}

export function line(pointA: number[], pointB: number[]) {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
}

export function controlPoint(
  current: number[],
  previous: number[],
  next: number[],
  reverse = false,
) {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current; // The smoothing ratio
  const smoothing = 0.15; // Properties of the opposed-line
  const o = line(p, n); // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  // The control point position is relative to the current point
  const activation = distance(p, current) < 20 || distance(n, current) < 20;
  const length = o.length * smoothing * (activation ? 0.8 : 1);
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;
  return [x, y];
}

export function bezierCommand(point: number[], i: number, a: number[][]) {
  // start control point
  const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point);
  // end control point
  const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true);
  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`;
}

export function pointsToBezierPath(points: number[][]) {
  return points.reduce((acc, point, i, a) => {
    if (i === 0) return `M ${point[0]} ${point[1]}`;
    return `${acc} ${bezierCommand(point, i, a)}`;
  }, "");
}

export function extendPointSegment(
  pointA: number[],
  pointB: number[],
  distance: number,
): number[] {
  const [x1, y1] = pointA;
  const [x2, y2] = pointB;

  const xSlope = x2 - x1;
  const ySlope = y2 - y1;
  const h = Math.sqrt(Math.pow(xSlope, 2) + Math.pow(ySlope, 2));

  const x3 = x1 - (xSlope * distance) / h;
  const y3 = y1 - (ySlope * distance) / h;

  return [x3, y3];
}

export function extendSegment(
  points: number[][],
  distance: number,
): number[][] {
  try {
    const startPoint = extendPointSegment(points[0], points[1], distance);

    const endPoint = extendPointSegment(
      points[points.length - 1],
      points[points.length - 2],
      distance,
    );

    return [startPoint, ...points, endPoint];
  } catch (_) {
    return points;
  }
}
