import React, {FC} from "react";

import ProgressBadge from "components/ProgressBadge";
import styles from "./index.module.scss";
import {ListItem} from "..";

interface Props {
  list: ListItem[];
  className?: string;
}

const ListStatus: FC<Props> = ({list, className}) => {
  return (
    <div className={className}>
      {list.map((item, i) => (
        <div key={item.char + i} className={styles.row}>
          <ProgressBadge progress={item.progress} />
          <span className={styles.rowLabel}>
            {i + 1}. {i === 0 ? "Vocabulary" : "Hanzi"} - {item.char}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ListStatus;
