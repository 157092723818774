import React, {FC, useState} from "react";
import Sound from "react-sound";

import styles from "./index.module.scss";
import Icon from "components/Icon";
import Spinner from "components/Spinner";
import concatStyles from "helpers/concatStyles";

interface Props {
  url: string;
  className?: string;
}

export const AudioButton: FC<Props> = ({url, className}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const onFinishPlayHandler = () => {
    setIsPlaying(false);
  };

  return (
    <button
      aria-label="Play Pronunciation"
      title="Play Pronunciation"
      onClick={(event: any) => {
        event?.preventDefault();
        setIsPlaying(true);
      }}
      className={concatStyles([styles.audioButton, className])}>
      {isPlaying && !isLoaded ? (
        <Spinner size={24} />
      ) : (
        <Icon
          name="speaker"
          fill={isPlaying ? "#159edd" : "#81919f"}
          size={18}
        />
      )}
      <Sound
        url={url}
        // @ts-ignore
        playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
        onLoad={() => setIsLoaded(true)}
        onFinishedPlaying={onFinishPlayHandler}
        onError={() => {
          setIsPlaying(false);
          setIsLoaded(true);
        }}
        autoLoad
      />
    </button>
  );
};

export default AudioButton;
