import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {gql} from "@apollo/client";
import {useToasts} from "react-toast-notifications";
import Parse from "parse";

import Layout from "components/Layout";
import {
  GetHanzisQuery,
  useCreateHanziMutation,
  useUpdateHanziMutation,
} from "queries";
import {BrowserFooter, BrowserHeader} from "components/Browser";
import BrowserContent from "components/Browser/BrowserContent";
import Panel, {FormInputs} from "./Panel";
import stringToJson from "helpers/stringToJson";
import BrowserLayout from "components/Browser/BrowserLayout";
import useSearch from "hooks/useSearch";
import useExportJsonFile from "hooks/useExportJsonFile";
import useQueryBuilder from "hooks/useQueryBuilder";
import useBrowserState from "hooks/useBrowserState";
import useHanziPageData from "./useHanziPageData";
import {updatePresenceStatus, usePresenceSelector} from "state/presence";
import {useDispatch} from "react-redux";
import HanziEditor from "./HanziEditor";
import {useForm} from "react-hook-form";
import processSvg from "./processSvg";

const COLUMNS = [
  {label: "Id", width: 0.2, key: "objectId"},
  {label: "Character", width: 0.3, key: "character"},
  {label: "Status", width: 0.5, key: "status"},
];

const Hanzi: FC = () => {
  const [viewMode, setViewMode] = useState<"browser" | "medians">("browser");
  const {addToast} = useToasts();
  const presences = usePresenceSelector("Hanzi");
  const dispatch = useDispatch();
  const {search, onSearch} = useSearch();
  const {isCreateMode, selectedRow, selectRow, createMode} = useBrowserState();
  const {loading: exportLoading, exportJsonFile} = useExportJsonFile(
    "hanzis-export",
  );
  const {filter, query} = useQueryBuilder(
    {
      value: search,
      query: (value: string) => ({character: {matchesRegex: value}}),
    },
    [
      {
        value: "status",
        label: "Status",
        query: (value: string) => ({status: {equalTo: value}}),
        values: [
          {value: "approved", label: "🟢‎‎ ‎‎‎ Approved"},
          {value: "rejected", label: "🔴‎ ‎‎ Rejected"},
          {value: "pending", label: "⚪‎ ‎ ‎‎Pending"},
        ],
      },
    ],
  );
  const form = useForm<FormInputs>();
  const {data, loading, loadMore} = useHanziPageData(query);

  const [updateHanzi, {loading: updateLoading}] = useUpdateHanziMutation({});

  const [createHanzi, {loading: createLoading}] = useCreateHanziMutation({
    update(cache, {data}) {
      const newHanzi = data?.createHanzi?.hanzi;
      const existingHanzis = cache.readQuery<GetHanzisQuery>({
        query: GET_HANZIS,
      });

      if (newHanzi && existingHanzis) {
        cache.writeQuery({
          query: GET_HANZIS,
          data: {
            hanzis: {
              edges: [...existingHanzis?.hanzis?.edges!, newHanzi],
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    dispatch(updatePresenceStatus("Hanzi", ""));
  }, []);

  const onUpdateHandler = (form: FormInputs) => {
    const {character, strokes, medians} = form;

    updateHanzi({
      variables: {
        id: data.hanzis.items[selectedRow!].objectId,
        fields: {
          character,
          status: "pending",
          lastUpdatedAt: new Date(),
          approvedBy: {
            link: null,
          },
          lastUpdatedBy: {
            link: data.currentUser?.objectId,
          },
          strokes: stringToJson(strokes) || null,
          medians: stringToJson(medians) || null,
        },
      },
    }).then((res) => {
      if (res.errors) {
        addToast(res.errors[0].message);
      } else {
        addToast("Updated successfully.");
      }
    });
  };

  const onSaveHandler = (form: FormInputs) => {
    const {character, strokes, medians} = form;

    createHanzi({
      variables: {
        fields: {
          character,
          lastUpdatedBy: {
            link: data.currentUser?.objectId,
          },
          lastUpdatedAt: new Date(),
          status: "pending",
          strokes: stringToJson(strokes) || null,
          medians: stringToJson(medians) || null,
        },
      },
    }).then((res) => {
      if (res.errors) {
        addToast(res.errors[0].message);
      } else {
        addToast("Saved successfully.");
        selectRow(null);
      }
    });
  };

  const onApprovalHandler = (approved: boolean) => {
    updateHanzi({
      variables: {
        id: data.hanzis.items[selectedRow!].objectId,
        fields: {
          status: approved ? "approved" : "rejected",
          lastApprovedAt: new Date(),
          approvedBy: {
            link: data.currentUser?.objectId,
          },
        },
      },
    });
  };

  const onExportHandler = async () => {
    try {
      await exportJsonFile(async () => {
        const Hanzi = Parse.Object.extend("Hanzi");
        const query = new Parse.Query(Hanzi)
          .exists("strokes")
          .notEqualTo("strokes", null)
          .exists("medians")
          .notEqualTo("medians", null)
          .exists("character")
          .notEqualTo("character", null)
          .limit(99999999);
        const results = await query.find();

        const data = results.reduce((acc, result) => {
          const id = result.get("character");
          acc[id] = {
            strokes: result.get("strokes"),
            medians: result.get("medians"),
          };
          return acc;
        }, {} as any);

        return data;
      });

      addToast("Export completed.");
    } catch (error) {
      addToast(error.message);
    }
  };

  const onModeClickHandler = (mode: "medians" | "browser") => {
    setViewMode(mode);
  };

  const onUpdateMediansHandler = (medians: number[][][]) => {
    form.setValue("medians", JSON.stringify(medians), {shouldDirty: true});
  };

  const onRowClickHandler = (i: number) => {
    dispatch(updatePresenceStatus("Hanzi", data.hanzis.items[i].objectId));
    selectRow(i);
  };

  const onImportSvgHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const svg = await event.target!.files![0].text();
    const [paths, medians] = processSvg(svg);

    form.setValue("strokes", JSON.stringify(paths), {shouldDirty: true});
    form.setValue("medians", JSON.stringify(medians), {shouldDirty: true});
  };

  const formStrokes = stringToJson(form.watch("strokes")) || [];
  const formMedians = stringToJson(form.watch("medians")) || [];

  return (
    <Layout>
      <BrowserLayout>
        <BrowserLayout.Browser>
          <BrowserHeader
            title="Hanzi"
            usersPresence={presences}
            role={data.currentUser?.role || null}
            search={search}
            filter={filter}
            onSearch={onSearch}
            onCreateClick={() => {
              createMode();
              dispatch(updatePresenceStatus("Hanzi", ""));
            }}
            onExportClick={onExportHandler}
          />
          <BrowserContent
            selectedRow={selectedRow}
            columns={COLUMNS}
            data={data.hanzis.items}
            loading={loading}
            onRowClick={onRowClickHandler}
            onLoadMore={() => loadMore()}
            hasNextPage={data.hanzis.hasNextPage || false}
            count={data.hanzis.count || 0}
          />
          <BrowserFooter
            loading={loading || exportLoading}
            count={data.hanzis.count || 0}
          />
        </BrowserLayout.Browser>
        <BrowserLayout.Pane>
          <Panel
            form={form}
            isCreateMode={isCreateMode}
            role={data.currentUser?.role || null}
            loading={updateLoading || createLoading}
            presences={presences}
            onSave={onSaveHandler}
            onUpdate={onUpdateHandler}
            onApproval={onApprovalHandler}
            onModeClick={() => onModeClickHandler("medians")}
            onUploadSvg={onImportSvgHandler}
            data={selectedRow !== null && data.hanzis.items[selectedRow]}
          />
        </BrowserLayout.Pane>
      </BrowserLayout>
      {viewMode === "medians" && (
        <HanziEditor
          strokes={formStrokes || null}
          medians={formMedians || null}
          onBackClick={() => onModeClickHandler("browser")}
          onUpdateMedians={onUpdateMediansHandler}
        />
      )}
    </Layout>
  );
};

export const HANZI_FRAGMENT = gql`
  fragment hanziFragment on Hanzi {
    id
    objectId
    character
    medians {
      ... on Element {
        value
      }
    }
    strokes {
      ... on Element {
        value
      }
    }
    status
    lastUpdatedAt
    lastApprovedAt
    approvedBy {
      name
      avatar {
        url
      }
    }
    lastUpdatedBy {
      name
      avatar {
        url
      }
    }
  }
`;

export const GET_HANZIS = gql`
  query getHanzis($filter: HanziWhereInput, $cursor: String) {
    viewer {
      user {
        objectId
        role {
          name
        }
      }
    }
    hanzis(where: $filter, after: $cursor) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...hanziFragment
        }
      }
    }
  }
  ${HANZI_FRAGMENT}
`;

export const UPDATE_HANZI = gql`
  mutation updateHanzi($id: ID!, $fields: UpdateHanziFieldsInput) {
    updateHanzi(input: {id: $id, fields: $fields}) {
      clientMutationId
      hanzi {
        ...hanziFragment
      }
    }
  }
  ${HANZI_FRAGMENT}
`;

export const CREATE_HANZI = gql`
  mutation CreateHanzi($fields: CreateHanziFieldsInput!) {
    createHanzi(input: {fields: $fields}) {
      clientMutationId
      hanzi {
        ...hanziFragment
      }
    }
  }
  ${HANZI_FRAGMENT}
`;

export default Hanzi;
