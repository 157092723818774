import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as Parse from "parse";
import {useSelector} from "react-redux";

import {AppThunk, RootState} from "state";

export type UserPresence = {
  id: string;
  avatar: string;
  page: string;
  item: string;
};

interface State {
  users: UserPresence[];
}

const initialState: State = {
  users: [],
};

/**
 * Reducer
 */
const presenceSlice = createSlice({
  name: "presence",
  initialState,
  reducers: {
    update(state, action: PayloadAction<UserPresence>) {
      const {id} = action.payload;

      const index = state.users.findIndex((user) => user.id === id);

      if (index !== -1) {
        state.users[index] = action.payload;
      } else {
        state.users = [...state.users, action.payload];
      }
    },
    remove(state, action: PayloadAction<string>) {
      state.users = [...state.users].filter(
        (user) => user.id !== action.payload,
      );
    },
  },
});

export const {
  update: updatePresence,
  remove: removePresence,
} = presenceSlice.actions;

export default presenceSlice.reducer;

/**
 * Thunks
 */
export const goOnline = (): AppThunk => async (dispatch) => {
  try {
    const currentUser = await Parse.User.currentAsync();

    const results = await new Parse.Query("Presence")
      .equalTo("online", true)
      .notEqualTo("user", currentUser)
      .find();

    results.forEach(async (data) => {
      const user = await data.get("user").fetch();
      const avatar = (user.get("avatar") && user.get("avatar")._url) || "";
      const page = data.get("page");
      const item = data.get("item");

      dispatch(updatePresence({id: user.id, avatar, page, item}));
    });
  } catch (error) {
    console.log(error);
  }
};

export const updatePresenceStatus = (
  page: string,
  item: string,
): AppThunk => async (dispatch) => {
  try {
    const currentUser = await Parse.User.currentAsync();
    const avatar = currentUser!.get("avatar")?._url || "";

    dispatch(updatePresence({id: currentUser!.id, avatar, page, item}));

    const query = new Parse.Query("Presence").equalTo("user", currentUser);
    const userPresence = await query.first();

    userPresence!.set("online", true);
    userPresence!.set("page", page);
    userPresence!.set("item", item);
    userPresence!.save();
  } catch (error) {
    console.log(error);
  }
};

/**
 * Selector
 */

export const usePresenceSelector = (page: string) =>
  useSelector((state: RootState) =>
    state.presence.users.filter((user) => user.page === page),
  );
