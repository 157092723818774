import React, {forwardRef} from "react";

import {ListItem} from "..";
import ListStatus from "../ListStatus";
import styles from "./index.module.scss";

interface Props {
  list: ListItem[];
  hide?: boolean;
}

const Scene = forwardRef<any, Props>(({list, hide}, ref) => {
  return (
    <div
      className={styles.container}
      style={{opacity: hide ? 0 : 1, display: hide ? "none" : "flex"}}>
      <canvas ref={ref} width={1080} height={1080} className={styles.canvas} />
      <div>
        <h1 className={styles.title}>Status</h1>
        <ListStatus list={list} />
      </div>
    </div>
  );
});

export default Scene;
