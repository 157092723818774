import {useGetHanzisQuery} from "queries";
import {useMemo} from "react";

const useHanziPageData = (query: any) => {
  const {data, loading, fetchMore} = useGetHanzisQuery({
    variables: {
      ...query,
      cursor: null,
    },
  });

  const formattedData = useMemo(() => {
    if (!data)
      return {
        currentUser: null,
        hanzis: {count: 0, hasNextPage: false, endCursor: null, items: []},
      };

    const currentUser = {
      objectId: data.viewer.user.objectId,
      role: data.viewer.user.role?.name || "guest",
    };

    const items =
      data?.hanzis?.edges?.map((node: any) => {
        let row = {...node!.node!};
        row.strokes = row.strokes && row.strokes.map((row: any) => row.value);
        row.medians = row.medians && row.medians.map((row: any) => row.value);
        return row;
      }) || [];

    return {
      currentUser,
      hanzis: {
        count: data.hanzis.count,
        hasNextPage: data.hanzis.pageInfo.hasNextPage,
        endCursor: data.hanzis.pageInfo.endCursor,
        items,
      },
    };
  }, [data]);

  const loadMore = () => {
    return fetchMore({
      variables: {
        ...query,
        cursor: formattedData.hanzis.endCursor,
      },
    });
  };

  return {data: formattedData, loading, loadMore};
};

export default useHanziPageData;
