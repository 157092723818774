import React, {FC, useEffect, useState} from "react";
import useDimensions from "react-cool-dimensions";

import Icon from "components/Icon";
import colors from "styles/Color";
import HanziCanva from "./HanziCanva";
import styles from "./index.module.scss";
import PointsPanel from "./PointsPanel";
import StrokesPanel from "./StrokesPanel";
import {insertAt} from "helpers/array";
import useKeyboardEvent from "hooks/useKeyboardEvent";

interface Props {
  strokes: string[] | null;
  medians: number[][][] | null;
  onBackClick: () => void;
  onUpdateMedians: (medians: number[][][]) => void;
}

const HanziEditor: FC<Props> = ({
  strokes,
  medians,
  onBackClick,
  onUpdateMedians,
}) => {
  const [activeStroke, setActiveStroke] = useState(0);
  const [activePoint, setActivePoint] = useState(0);
  const [localMedians, setLocalMedians] = useState(medians);
  const canvaDimensions = useDimensions({});

  useEffect(() => {
    setLocalMedians(medians);
  }, [medians]);

  const size = Math.min(
    Math.min(canvaDimensions.width, canvaDimensions.height),
    1024,
  );

  const onStrokeSelectHandler = (i: number) => {
    setActiveStroke(i);
    setActivePoint(0);
  };

  const onAddHandler = () => {
    if (!localMedians) return;
    const [x, y] = localMedians[activeStroke][activePoint] || [
      1024 / 2,
      1024 / 2,
    ];

    const newMedians = [...localMedians];
    newMedians[activeStroke] = insertAt(
      localMedians[activeStroke],
      [x + 10, y + 10],
      activePoint + 1,
    );

    onUpdateMedians(newMedians);
    setActivePoint((activePoint) => activePoint + 1);
  };

  const onFlipHandler = () => {
    if (!localMedians) return;
    const newMedians = [...localMedians];
    newMedians[activeStroke] = newMedians[activeStroke].reverse();
    onUpdateMedians(newMedians);
  };

  const onRemoveHandler = () => {
    if (!localMedians) return;
    const newMedians = [...localMedians];
    newMedians[activeStroke] = newMedians[activeStroke].filter(
      (_, i) => i !== activePoint,
    );

    onUpdateMedians(newMedians);
  };

  const onPointDragHandler = (x: number, y: number) => {
    if (!localMedians) return;

    const relative = (x: number) => (x * 1024) / size;
    const relativeX = relative(x);
    const relativeY = relative(y);

    const newMedians = [...localMedians];
    const [currentX, currentY] = newMedians[activeStroke][activePoint];
    let newX = currentX - relativeX;
    let newY = currentY - relativeY;
    newX = newX < 6 ? 6 : newX;
    newX = newX > 1024 - 6 ? 1024 - 6 : newX;
    newY = newY < 6 ? 6 : newY;
    newY = newY > 1024 - 6 ? 1024 - 6 : newY;

    newMedians[activeStroke][activePoint] = [newX, newY];

    setLocalMedians(newMedians);
  };

  const onPointDragEndHandler = () => {
    if (!localMedians) return;

    const recRound = (item: any) => {
      if (typeof item !== "number") {
        return item.map((x: any) => recRound(x));
      } else {
        return Math.round(item);
      }
    };
    const roundedMedians = recRound(localMedians);

    onUpdateMedians(roundedMedians);
  };

  useKeyboardEvent(
    "x",
    () => {
      onAddHandler();
    },
    [activePoint, activeStroke],
  );

  useKeyboardEvent(
    "z",
    () => {
      onRemoveHandler();
    },
    [activePoint, activeStroke],
  );

  useKeyboardEvent(
    "a",
    () => {
      if (activeStroke < strokes!.length - 1) {
        onStrokeSelectHandler(activeStroke + 1);
      }
    },
    [activeStroke],
  );

  useKeyboardEvent(
    "q",
    () => {
      if (activeStroke > 0) {
        onStrokeSelectHandler(activeStroke - 1);
      }
    },
    [activeStroke],
  );

  useKeyboardEvent(
    "s",
    () => {
      if (activePoint < medians![activeStroke].length - 1) {
        setActivePoint(activePoint + 1);
      }
    },
    [activePoint, activeStroke],
  );

  useKeyboardEvent(
    "w",
    () => {
      if (activePoint > 0) {
        setActivePoint(activePoint - 1);
      }
    },
    [activePoint],
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div onClick={onBackClick} className={styles.backButton}>
          <Icon name="chevronLeft" size={18} fill={colors.mediumGrey} />
          <span>Back</span>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <StrokesPanel
          onSelect={onStrokeSelectHandler}
          strokesCount={strokes?.length || 0}
          activeStroke={activeStroke}
        />
        <HanziCanva
          ref={canvaDimensions.ref}
          size={size}
          medians={medians || []}
          strokes={strokes || []}
          activeStroke={activeStroke}
          activePoint={activePoint}
          onPointSelect={(i) => setActivePoint(i)}
          onPointDrag={onPointDragHandler}
          onPointDragEnd={onPointDragEndHandler}
        />
        <PointsPanel
          points={medians![activeStroke] || []}
          activePoint={activePoint}
          onSelect={(i) => setActivePoint(i)}
          onAdd={onAddHandler}
          onRemove={onRemoveHandler}
          onFlip={onFlipHandler}
        />
      </div>
    </div>
  );
};

export default HanziEditor;
