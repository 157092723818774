import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";

interface Props {
  height: number;
  className?: string;
}

export const Skeleton: FC<Props> = ({height, className}) => {
  return (
    <div
      className={concatStyles([styles.container, className])}
      style={{height}}
    />
  );
};

export default Skeleton;
