import React, {FC} from "react";
import colors from "styles/Color";

import {ICONS} from "./ICONS";

export type Icons = keyof typeof ICONS;

interface Props {
  name: Icons;
  fill?: string;
  size?: number;
  className?: string;
  onClick?: () => void;
}

export const Icon: FC<Props> = ({
  name,
  fill = colors.black,
  size = 64,
  className,
  onClick,
  ...rest
}) => {
  const path = ICONS[name];

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      {...rest}>
      <path d={path} fill={fill} fillRule="evenodd" />
    </svg>
  );
};

export default Icon;
