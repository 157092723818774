import Icon from "components/Icon";
import React, {FC} from "react";
import colors from "styles/Color";

import styles from "./index.module.scss";

interface Props {
  points: number[][];
  activePoint: number;
  onSelect: (id: number) => void;
  onRemove: () => void;
  onAdd: () => void;
  onFlip: () => void;
}

const PointsPanel: FC<Props> = ({
  points,
  activePoint,
  onSelect,
  onRemove,
  onAdd,
  onFlip,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.topNav}>
        <div className={styles.iconAction}>
          <Icon
            name="trash"
            onClick={onRemove}
            size={16}
            fill={colors.mediumGrey}
            className={styles.icon}
          />
        </div>
        <div className={styles.iconAction}>
          <Icon
            name="plus"
            onClick={onAdd}
            size={16}
            fill={colors.mediumGrey}
            className={styles.icon}
          />
        </div>
        <div className={styles.iconAction}>
          <Icon
            name="shuffle"
            onClick={onFlip}
            size={16}
            fill={colors.mediumGrey}
            className={styles.icon}
          />
        </div>
      </div>
      {points.map((point, i) => (
        <div
          key={`${i}-point-editor`}
          className={`${styles.cellContainer} ${
            activePoint == i ? styles.active : ""
          }`}
          onClick={() => onSelect(i)}>
          <span>Point {i}</span>
          <span>
            ({Math.round(point[0])}, {Math.round(point[1])})
          </span>
        </div>
      ))}
    </div>
  );
};

export default PointsPanel;
