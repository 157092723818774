import React, {FC} from "react";
import {NavLink} from "react-router-dom";

import styles from "./index.module.scss";
import Logo from "components/Logo";
import Icon, {Icons} from "components/Icon";
import colors from "styles/Color";
import concatStyles from "helpers/concatStyles";
import {logout} from "state/auth";
import {useDispatch} from "react-redux";
import {gql} from "@apollo/client";
import {useGetCurrentUserQuery} from "queries";

export const navbarItems: {to: string; title: string; icon: Icons}[] = [
  {to: "/instagram", title: "Instagram", icon: "instagram"},
  {to: "/sentences", title: "Sentences", icon: "feather"},
  {to: "/vocabulary", title: "Vocabulary", icon: "atlas"},
  {to: "/hanzi", title: "Hanzi", icon: "hanzi"},
];

const Navbar: FC = () => {
  const {data} = useGetCurrentUserQuery({});
  const currentUser = data?.viewer.user || undefined;

  const dispatch = useDispatch();

  const onLogoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className={styles.container}>
      <NavLink aria-label="Home" to="/">
        <Logo className={styles.logo} size={32} />
      </NavLink>
      <nav className={styles.nav}>
        <ul>
          {navbarItems.map(({to, title, icon}) => (
            <li key={title}>
              <NavLink
                className={styles.navItem}
                activeClassName={styles.active}
                aria-label={title}
                to={to}>
                <Icon name={icon} fill={colors.white} size={24} />
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.avatar}>
        {currentUser?.avatar?.url && (
          <img alt="avatar" src={currentUser?.avatar?.url} />
        )}
      </div>
      <div
        onClick={onLogoutHandler}
        className={concatStyles([styles.navItem, styles.logout])}>
        <Icon name="logout" fill={colors.white} size={24} />
      </div>
    </div>
  );
};

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    viewer {
      user {
        id
        objectId
        name
        role {
          name
        }
        avatar {
          url
        }
      }
    }
  }
`;

export default Navbar;
