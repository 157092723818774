import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import Icon from "components/Icon";
import colors from "styles/Color";

interface Props {
  action: "add" | "remove";
  className?: string;
  onClick: () => void;
}

const ActionButton: FC<Props> = ({action, className, onClick}) => {
  const icon: any = {
    add: "plus",
    remove: "minus",
  };

  return (
    <div onClick={onClick} className={concatStyles([styles.button, className])}>
      <Icon
        name={icon[action]}
        size={18}
        fill={action === "add" ? colors.primary : colors.mediumGrey}
      />
    </div>
  );
};

export default ActionButton;
