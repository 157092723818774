import Icon from "components/Icon";
import {repeat} from "helpers/array";
import React, {FC} from "react";
import colors from "styles/Color";

import styles from "./index.module.scss";

interface Props {
  strokesCount: number;
  activeStroke: number;
  onSelect: (id: number) => void;
}

const StrokesPanel: FC<Props> = ({strokesCount, activeStroke, onSelect}) => {
  return (
    <div className={styles.container}>
      {repeat(strokesCount, (i) => (
        <div
          key={`${i}-stroke-panel`}
          className={`${styles.cellContainer} ${
            activeStroke == i ? styles.active : ""
          }`}
          onClick={() => onSelect(i)}>
          Stroke {i}
        </div>
      ))}
    </div>
  );
};

export default StrokesPanel;
