import Parse from "parse";

const uploadAudio = async (file: File, name: string, type: string) => {
  const body = await Buffer.from(await file.arrayBuffer()).toString("base64");

  const params = {
    file: body,
    type,
    name,
  };

  return await Parse.Cloud.run("uploadAudio", params);
};

export default uploadAudio;
