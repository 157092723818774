export const uniqueArray = (arr: any[]) => {
  return [...Array.from(new Set(arr))];
};

export const repeat = (n: number, callBack: (index: number) => void) => {
  return [...Array(n)].map((_, i) => callBack(i));
};

export const insertAt = <T>(array: T[], item: T, index: number) => {
  return [...array.slice(0, index), item, ...array.slice(index)];
};

export default {
  unique: uniqueArray,
  repeat,
  insertAt,
};
