import {combineReducers} from "redux";
import {configureStore, getDefaultMiddleware, Action} from "@reduxjs/toolkit";
import {ThunkAction} from "redux-thunk";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {useDispatch} from "react-redux";

import authReducer from "./auth";
import presenceReducer from "./presence";
import instagramReducer from "./instagram";

// Combine to a root reducer with type
const rootReducer = combineReducers({
  auth: authReducer,
  presence: presenceReducer,
  instagram: instagramReducer,
});

// Persist redux
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["presence", "instagram"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

let persistor = persistStore(store);
// persistor.purge()

// Types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

// typed dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();

const state = {
  store,
  persistor,
};

export default state;
