import {useMemo} from "react";

import {useGetVocabulariesQuery} from "queries";

const useVocabularyPageData = (query: any) => {
  const {data, loading, fetchMore} = useGetVocabulariesQuery({
    variables: {
      ...query,
      cursor: null,
    },
  });
  console.log(data);

  const formattedData = useMemo(() => {
    if (!data)
      return {
        count: 0,
        hasNextPage: false,
        endCursor: null,
        items: [],
      };

    const items =
      data?.vocabularies?.edges?.map(({node}: any) => {
        const listsLabel =
          node?.lists?.map((list: any) => list.name).join(", ") || [];

        const speechPart = node.speechPart.map((x: any) => x.value);

        const hanzis = node.hanzis.map((hanzi: any) => {
          const strokes =
            hanzi?.strokes?.map((stroke: any) => stroke.value) || [];
          const medians =
            hanzi?.medians?.map((median: any) => median.value) || [];
          return {
            ...hanzi,
            strokes,
            medians,
          };
        });

        const sentences =
          node.sentences?.map((sentence: any) => {
            const hanzi = sentence.words.map(({value}: any) => value.hanzi);

            const pinyin = sentence.words.map(({value}: any) => {
              return value.pinyins.join(" ");
            });

            return {
              id: sentence.objectId,
              english: sentence.english,
              hanzi,
              pinyin,
            };
          }) || [];

        return {
          ...node,
          lists: node.lists || [],
          listsLabel,
          speechPart,
          hanzis,
          sentences,
        };
      }) || [];

    return {
      count: data.vocabularies.count,
      hasNextPage: data.vocabularies.pageInfo.hasNextPage,
      endCursor: data.vocabularies.pageInfo.endCursor,
      items,
    };
  }, [data]);

  const loadMore = () => {
    return fetchMore({
      variables: {
        ...query,
        cursor: formattedData.endCursor,
      },
    });
  };

  return {data: formattedData, loading, loadMore};
};

export default useVocabularyPageData;
