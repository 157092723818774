import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import Icon, {Icons} from "components/Icon";
import colors from "styles/Color";

interface Props {
  icon: Icons;
  className?: string;
  active?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadInput: FC<Props> = ({
  icon,
  className,
  active = false,
  onChange,
}) => {
  return (
    <div
      className={concatStyles([
        styles.button,
        active ? styles.active : undefined,
        className,
      ])}>
      <input
        width={40}
        height={40}
        className={styles.input}
        type="file"
        onChange={onChange}
      />
      <Icon
        className={styles.icon}
        name={icon}
        fill={active ? colors.primary : colors.mediumGrey}
        size={18}
      />
    </div>
  );
};

export default UploadInput;
