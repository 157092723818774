import React from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";

interface Props {
  placeholder: string;
  name: string;
  type?: string;
  className?: string;
  defaultValue?: any;
  value?: any;
  dark?: boolean;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = React.forwardRef<any, Props>(
  (
    {
      placeholder,
      type = "text",
      name,
      error = false,
      defaultValue,
      className,
      value,
      onChange,
    },
    ref,
  ) => (
    <div className={className}>
      <input
        ref={ref}
        autoComplete="none"
        type={type}
        value={value}
        aria-label={name}
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={concatStyles([styles.input, error ? styles.error : ""])}
      />
      {error && <span className={styles.errorLabel}>{error}</span>}
    </div>
  ),
);

export default TextInput;
