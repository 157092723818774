import {useState, useEffect, useCallback, useRef} from "react";

// from https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface IProps {
  onDrag: (x: number, y: number) => void;
  onDragStart: Function;
  onDragEnd: Function;
}

export function useDrag({onDrag, onDragStart, onDragEnd}: IProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPostion] = useState({x: 0, y: 0});

  const handleMouseMove = useCallback(
    (e) => {
      setPostion({x: e.pageX, y: e.pageY});
      onDrag(position.x - e.pageX, position.y - e.pageY);
    },
    [onDrag],
  );

  const handleMouseUp = useCallback(
    (e) => {
      onDragEnd();
      setPostion({x: 0, y: 0});
      document.removeEventListener("mousemove", handleMouseMove);
      setIsDragging(false);
    },
    [onDragEnd, handleMouseMove],
  );

  const handleMouseDown = useCallback(
    (e) => {
      onDragStart();
      setPostion({x: e.pageX, y: e.pageY});
      setIsDragging(true);
      document.addEventListener("mousemove", handleMouseMove);
    },
    [onDragStart, handleMouseMove],
  );

  const prevMouseMove = usePrevious(handleMouseMove);

  useEffect(() => {
    //@ts-ignore
    document.removeEventListener("mousemove", prevMouseMove);
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
    }
  }, [prevMouseMove, handleMouseMove, isDragging]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mouseup", handleMouseUp);
    }
    return () => document.removeEventListener("mouseup", handleMouseUp);
  }, [isDragging, handleMouseUp]);

  return handleMouseDown;
}

export default useDrag;
