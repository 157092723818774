import {ApolloClient, InMemoryCache} from "@apollo/client";
import {createUploadLink} from "apollo-upload-client";
import Cookies from "js-cookie";
import {relayStylePagination} from "@apollo/client/utilities";
import {useEffect, useState} from "react";

export const useGraphqlClient = () => {
  const [authToken, setToken] = useState("");

  const getToken = async () => {
    const token = (await Cookies.get("authToken")) || "";
    setToken(token);
  };

  useEffect(() => {
    getToken();
  }, []);

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            hanzis: relayStylePagination(),
            vocabularies: relayStylePagination(),
            sentences: relayStylePagination(),
          },
        },
      },
    }),
    link: createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_URL,
      headers: {
        "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID!,
        "X-Parse-Client-Key": process.env.REACT_APP_PARSE_CLIENT_KEY!,
        "X-Parse-Session-Token": authToken ? authToken : "",
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "cache-only",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });
};

export default useGraphqlClient;
