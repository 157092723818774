import {INode, parseSync} from "svgson";
import {
  CpNode,
  findMats,
  getCurveToNext,
  getPathsFromStr,
  Mat,
  toScaleAxis,
  traverseEdges,
} from "flo-mat";
import {distance} from "helpers/svgUtils";
import simplifyPoints from "./simplifyPoints";

// @ts-ignore
const svgPath = require("svgpath");

const MAT_FLATNESS = 3;
const MAT_SCALE = 2.3;
const SIMPLIFY_TOLERANCE = 3;
const CLEAN_POINTS_DISTANCE_THRESHOLD = 15;

const svgToPaths = (svg: INode) => {
  console.log(svg.children.filter((child) => child.name === "g"));

  return svg.children
    .filter((child) => child.name === "g")
    .map((row) => row.children[0].attributes.d);
};

const cleanSvgPath = (d: string) => {
  return svgPath(d).round().rel().toString();
};

const extractMats = (d: string) => {
  // Get loops (representing the shape) from some SVG path.
  const bezierLoops = getPathsFromStr(d);
  // Get MATs from the loops.
  const mats = findMats(bezierLoops, MAT_FLATNESS);
  return mats.map((mat) => toScaleAxis(mat, MAT_SCALE));
};

const extractPoints = (mats: Mat[]) => {
  try {
    let points: any = [];
    let cpNode = mats[0].cpNode;
    if (!cpNode) return;

    traverseEdges(cpNode, (cpNode) => {
      if (cpNode.isTerminating()) return;

      points = [...points, ...getCurveToNext(cpNode)];
    });

    return points;
  } catch {
    return [];
  }
};

const cleanPoints = (points: number[][]) => {
  return points.reduce((acc, point, i) => {
    const next = points[i + 1];
    // check for remaining points too close
    if (next) {
      if (
        distance(point, next) < CLEAN_POINTS_DISTANCE_THRESHOLD &&
        points.length > 2
      )
        return [...acc];
    }

    // check for duplicates
    const duplicates = acc.filter(
      (item) => item[0] === point[0] && item[1] === point[1],
    );

    if (duplicates.length > 0) return [...acc];

    const roundedPoint = [Math.round(point[0]), Math.round(point[1])];

    return [...acc, roundedPoint];
  }, [] as number[][]);
};

const pathToMedial = (path: string) => {
  const mats = extractMats(path);
  const points = extractPoints(mats);
  const simplifiedPoints = simplifyPoints(points, SIMPLIFY_TOLERANCE, true);
  const cleanedPoints = cleanPoints(simplifiedPoints);
  return cleanedPoints;
};

export const processSvg = (svgString: string) => {
  const svg = parseSync(svgString);
  const paths = svgToPaths(svg).reverse();
  const cleanedPaths = paths.map((path) => cleanSvgPath(path));
  const medials = cleanedPaths.map((path) => pathToMedial(path));

  return [cleanedPaths, medials];
};

export default processSvg;
