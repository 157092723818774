import {useState} from "react";

const useBrowserState = () => {
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const selectRow = (index: number | null) => {
    setSelectedRow(index);
    setIsCreateMode(false);
  };

  const createMode = () => {
    setSelectedRow(null);
    setIsCreateMode(true);
  };

  return {isCreateMode, selectedRow, selectRow, createMode};
};

export default useBrowserState;
