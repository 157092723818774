import React, {FC} from "react";

import styles from "./index.module.scss";
import ActionButton from "components/Button/ActionButton";
import {Filter} from "hooks/useFilter";
import SelectInput from "components/Input/SelectInput";

interface Props {
  filter: Filter;
}

const BrowserFilter: FC<Props> = ({filter}) => {
  const {
    filterData,
    selectOptions,
    addFilter,
    removeFilter,
    updateFilter,
  } = filter;

  const onSelectChangeHandler = (field: string) => (index: number) => (
    event: any,
  ) => {
    updateFilter(field, index, event);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>Filter</span>
        <ActionButton onClick={addFilter} action="add" />
      </div>
      {selectOptions.fields.map((row: any, i: number) => {
        const id = filterData.ids[i].value;

        return (
          <div key={`${filterData.ids[i].id}`} className={styles.row}>
            <SelectInput
              className={styles.select}
              options={row}
              onChange={onSelectChangeHandler("field")(i)}
            />
            <SelectInput
              className={styles.select}
              value={filterData.data[id!] || null}
              disabled={!id}
              options={selectOptions.values[id!]}
              onChange={onSelectChangeHandler("value")(i)}
            />
            <ActionButton onClick={() => removeFilter(i)} action="remove" />
          </div>
        );
      })}
    </div>
  );
};

export default BrowserFilter;
