import React, {FC, useEffect} from "react";
import {useDispatch} from "react-redux";

import styles from "./index.module.scss";
import Layout from "components/Layout";
import {BrowserFooter, BrowserHeader} from "components/Browser";
import BrowserContent from "components/Browser/BrowserContent";
import {updatePresenceStatus, usePresenceSelector} from "state/presence";
import useSearch from "hooks/useSearch";
import useQueryFilters from "hooks/useQueryBuilder";
import usePageInfoData from "hooks/usePageInfoData";
import useVocabularyPageData from "features/Vocabulary/useVocabularyPageData";
import {setVocabulary} from "state/instagram";
import {useHistory} from "react-router-dom";

const COLUMNS = [
  {label: "Id", width: 0.1, key: "objectId"},
  {label: "Hanzi", width: 0.1, key: "hanzi"},
  {label: "Pinyin", width: 0.2, key: "pinyin"},
  {label: "Lists", width: 0.2, key: "listsLabel"},
  {label: "English", width: 0.2, key: "english"},
  {label: "Status", width: 0.1, key: "status"},
  {label: "Published", width: 0.1, key: "instagram"},
];

const InstagramBrowse: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {search, onSearch} = useSearch();
  const presences = usePresenceSelector("Instagram");
  const {lists, currentUser} = usePageInfoData();

  const {filter, query} = useQueryFilters(
    {
      value: search,
      query: (value: string) => ({
        OR: [{hanzi: {matchesRegex: value}}, {english: {matchesRegex: value}}],
      }),
    },
    [
      {
        value: "status",
        label: "Status",
        query: (value: string) => ({status: {equalTo: value}}),
        values: [
          {value: "approved", label: "🟢‎‎ ‎‎‎ Approved"},
          {value: "rejected", label: "🔴‎ ‎‎ Rejected"},
          {value: "pending", label: "⚪‎ ‎ ‎‎Pending"},
        ],
      },
      {
        value: "instagram",
        label: "Published",
        query: (value: string) => ({
          instagram: value ? {equalTo: true} : {notEqualTo: true},
        }),
        values: [
          {value: true, label: "Yes"},
          {value: false, label: "No"},
        ],
      },
      {
        value: "lists",
        label: "List",
        query: (objectId: string) => ({
          lists: {
            contains: {
              __type: "Pointer",
              className: "List",
              objectId,
            },
          },
        }),
        values: lists.map((list: {objectId: any; name: any}) => ({
          value: list.objectId,
          label: list.name,
        })),
      },
    ],
  );

  const {data, loading, loadMore} = useVocabularyPageData(query);

  useEffect(() => {
    dispatch(updatePresenceStatus("Instagram", ""));
  }, []);

  const onRowClickHandler = (i: number) => {
    const vocab = data.items[i];
    dispatch(setVocabulary(vocab));
    history.push(`/instagram/creation`);
  };

  return (
    <Layout>
      <div className={styles.container}>
        <BrowserHeader
          title="Instagram"
          role={currentUser?.role || null}
          usersPresence={presences}
          search={search}
          filter={filter}
          onSearch={onSearch}
        />
        <BrowserContent
          columns={COLUMNS}
          data={data.items}
          loading={loading}
          onRowClick={onRowClickHandler}
          onLoadMore={loadMore}
          hasNextPage={data.hasNextPage}
          count={data.count}
        />
        <BrowserFooter loading={loading} count={data.count} />
      </div>
    </Layout>
  );
};

export default InstagramBrowse;
