import {useState} from "react";
import {format} from "date-fns";

import downloadJsonFile from "helpers/downloadJsonFile";

const useExportJsonFile = (name: string) => {
  const [loading, setLoading] = useState(false);
  const filename = `${name}-${format(new Date(), "yyyy-MM-dd")}.json`;

  const exportJsonFile = async (getData: () => Promise<any>) => {
    if (loading) return;

    try {
      setLoading(true);
      const data = await getData();
      downloadJsonFile(data, filename);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return {loading, exportJsonFile};
};

export default useExportJsonFile;
