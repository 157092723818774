import React, {FC} from "react";

import styles from "./index.module.scss";

interface Props {
  className?: string;
}

type SubComponents = {
  Browser: typeof Browser;
  Pane: typeof Pane;
};

const Browser: FC<Props> = ({children}) => {
  return <div className={styles.browser}>{children}</div>;
};

const Pane: FC<Props> = ({children}) => {
  return <div className={styles.pane}>{children}</div>;
};

const BrowserLayout: FC<Props> & SubComponents = ({children}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child) && child.type === Browser) {
            return child;
          }
        })}
      </div>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === Pane) {
          return child;
        }
      })}
    </div>
  );
};

BrowserLayout.Browser = Browser;
BrowserLayout.Pane = Pane;

export default BrowserLayout;
