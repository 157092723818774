import React, {FC} from "react";
import {TransitionState} from "react-toast-notifications";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import Icon from "components/Icon";

interface Props {
  onDismiss: () => void;
  appearance: any;
  transitionState: TransitionState;
}

export const Toast: FC<Props> = ({
  children,
  transitionState,
  appearance,
  onDismiss,
}) => {
  return (
    <div
      className={concatStyles([
        styles.container,
        transitionState === "entering" ? styles.enter : "",
        transitionState === "entered" ? styles.entered : "",
        transitionState === "exiting" ? styles.exit : "",
        appearance === "success" ? styles.success : "",
      ])}>
      {children}
      <div className={styles.close} onClick={onDismiss}>
        <Icon name="wrong" size={12} fill="#e4e6eb" />
      </div>
    </div>
  );
};

export default Toast;
