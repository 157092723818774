import * as path from "svg-path-properties";

import {length, arrayToPoints} from "helpers/geometry";
import {extendSegment} from "helpers/svgUtils";

const STROKE_WIDTH = 100;

export type Point = {x: number; y: number};

const drawPath = (ctx: CanvasRenderingContext2D, points: Point[]) => {
  const [start, ...remainingPoints] = points;
  ctx.beginPath();
  ctx.moveTo(start.x, start.y);
  remainingPoints.forEach((point) => {
    ctx.lineTo(point.x, point.y);
  });
  ctx.stroke();
};

export default class Stroke {
  path2D: Path2D;
  median: Point[];
  extendedMedian: Point[];
  pathLength: number;

  constructor(stroke: string, median: number[][]) {
    this.path2D = new Path2D(stroke);
    this.median = arrayToPoints(median);
    this.extendedMedian = arrayToPoints(extendSegment(median, 110));
    this.pathLength = length(this.extendedMedian);
  }

  getStrokeDashoffset(progress: number) {
    return this.pathLength * 0.999 * (1 - progress);
  }

  renderGuide(ctx: CanvasRenderingContext2D) {
    ctx.save();
    ctx.fillStyle = "#E9EDEF";
    ctx.fill(this.path2D);
    ctx.restore();
  }

  renderStroke(
    ctx: CanvasRenderingContext2D,
    props: {opacity: number; progress: number; color: string},
  ) {
    if (!props.opacity) return;
    ctx.save();
    // mask
    ctx.clip(this.path2D);
    // stroke
    const dashOffset = this.getStrokeDashoffset(props.progress);
    ctx.globalAlpha = props.opacity;
    ctx.strokeStyle = props.color;
    ctx.fillStyle = props.color;
    ctx.lineWidth = STROKE_WIDTH;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.setLineDash([this.pathLength, this.pathLength]);
    ctx.lineDashOffset = dashOffset;
    drawPath(ctx, this.extendedMedian);
    ctx.restore();
  }
}
