import React, {FC, useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import {difference} from "lodash";
import {gql} from "@apollo/client";
import {useDispatch} from "react-redux";
import Parse from "parse";

import Layout from "components/Layout";
import BrowserLayout from "components/Browser/BrowserLayout";
import {BrowserFooter, BrowserHeader} from "components/Browser";
import BrowserContent from "components/Browser/BrowserContent";
import Panel, {FormInputs} from "./Panel";
import useSearch from "hooks/useSearch";
import useBrowserState from "hooks/useBrowserState";
import useExportJsonFile from "hooks/useExportJsonFile";
import useQueryBuilder from "hooks/useQueryBuilder";
import {updatePresenceStatus, usePresenceSelector} from "state/presence";
import useSentencePageData from "./useSentencePageData";
import usePageInfoData from "hooks/usePageInfoData";
import {
  GetSentencesQuery,
  useCreateSentenceMutation,
  useUpdateSentenceMutation,
} from "queries";
import uploadAudio from "helpers/uploadAudio";
import {uniqueArray} from "helpers/array";
import exportSentences from "./export";

const COLUMNS = [
  {label: "Id", width: 0.1, key: "objectId"},
  {label: "Chinese", width: 0.4, key: "chinese"},
  {label: "English", width: 0.4, key: "english"},
  {label: "Status", width: 0.1, key: "status"},
];

const Sentences: FC = () => {
  const {addToast} = useToasts();
  const dispatch = useDispatch();
  const [audioFile, setAudioFile] = useState<null | any>(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const presences = usePresenceSelector("Sentences");
  const {search, onSearch} = useSearch();
  const {isCreateMode, selectedRow, selectRow, createMode} = useBrowserState();
  const {loading: exportLoading, exportJsonFile} = useExportJsonFile(
    "sentences-export",
  );

  const {lists, currentUser} = usePageInfoData();
  const {filter, query} = useQueryBuilder(
    {
      value: search,
      query: (value: string) => ({
        OR: [
          {vocabularies: {have: {hanzi: {matchesRegex: value}}}},
          {english: {matchesRegex: value}},
        ],
      }),
    },
    [
      {
        value: "status",
        label: "Status",
        query: (value: string) => ({status: {equalTo: value}}),
        values: [
          {value: "approved", label: "🟢‎‎ ‎‎‎ Approved"},
          {value: "rejected", label: "🔴‎ ‎‎ Rejected"},
          {value: "pending", label: "⚪‎ ‎ ‎‎Pending"},
        ],
      },
      {
        value: "audio",
        label: "Audio",
        query: (value: string) => {
          return value ? {audio: {notEqualTo: ""}} : {audio: {equalTo: ""}};
        },
        values: [
          {value: undefined, label: "No audio"},
          {value: true, label: "Has audio"},
        ],
      },
      {
        value: "lists",
        label: "List",
        query: (objectId: string) => ({
          vocabularies: {
            have: {
              lists: {
                contains: {
                  __type: "Pointer",
                  className: "List",
                  objectId,
                },
              },
            },
          },
        }),
        values: [
          {value: undefined, label: "None"},
          ...lists.map((list: {objectId: any; name: any}) => ({
            value: list.objectId,
            label: list.name,
          })),
        ],
      },
    ],
  );

  const {data, loading, loadMore} = useSentencePageData(query);

  const [updateSentence] = useUpdateSentenceMutation({});

  const [createSentence, {loading: createLoading}] = useCreateSentenceMutation({
    update(cache, {data}) {
      const newItem = data?.createSentence?.sentence;

      const existingItem = cache.readQuery<GetSentencesQuery>({
        query: GET_SENTENCES,
      });

      if (newItem && existingItem) {
        cache.writeQuery({
          query: GET_SENTENCES,
          data: {
            sentences: {
              edges: [...existingItem.sentences?.edges!, newItem],
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    dispatch(updatePresenceStatus("Sentences", ""));
  }, []);

  const getFormattedFields = (form: FormInputs) => {
    const {audio, english, words} = form;

    // get relation field for vocabularies
    const originalVocabularies = data?.items[selectedRow!]?.vocabularies || [];
    const newVocabularies = uniqueArray(
      words.map((word) => word?.vocab?.objectId).filter((x) => x),
    );
    const vocabulariesToAdd = difference(newVocabularies, originalVocabularies);
    const vocabulariesToRemove = difference(
      originalVocabularies,
      newVocabularies,
    );

    let vocabulariesField = {} as any;

    if (vocabulariesToAdd.length > 0) {
      vocabulariesField.add = vocabulariesToAdd;
    }

    if (vocabulariesToRemove.length > 0) {
      vocabulariesField.remove = vocabulariesToRemove;
    }

    if (newVocabularies.length <= 0 && originalVocabularies.length > 0) {
      vocabulariesField.remove = originalVocabularies;
    }

    if (Object.keys(vocabulariesField).length <= 0) {
      vocabulariesField = undefined;
    }

    // format words
    const wordsFormatted = words.map((word) => {
      const pinyins = word.pinyins
        ? word.pinyins.map((pinyin) => pinyin.label)
        : [word.label];

      return {
        hanzi: word.label,
        pinyins,
        vocabulary: word.vocab && word.vocab.objectId,
      };
    });

    return {
      audio,
      english,
      words: wordsFormatted,
      vocabularies: vocabulariesField,
    };
  };

  const updateVocabularyRelations = async (
    objectId: string,
    vocabularies: any,
  ) => {
    const updateItems = async (type: "remove" | "add") => {
      for await (const id of vocabularies[type]) {
        const query = new Parse.Query("Vocabulary");
        const vocabulary = await query.get(id);
        const sentences = vocabulary.get("sentences") || [];

        if (type === "add") {
          vocabulary.set(
            "sentences",
            uniqueArray([
              ...sentences,
              {
                __type: "Pointer",
                className: "Sentence",
                objectId,
              },
            ]),
          );
        } else if (type === "remove") {
          vocabulary.set(
            "sentences",
            sentences.filter((sentence: any) => sentence.id !== objectId),
          );
        }

        await vocabulary.save();
      }
    };

    if (vocabularies.remove) await updateItems("remove");
    if (vocabularies.add) await updateItems("add");
  };

  const onSaveComplete = () => {
    addToast("Updated successfully.");
    setAudioFile(null);
    setUpdateLoading(false);
  };

  const saveValidation = (res: any) => {
    if (res.errors) {
      setUpdateLoading(false);
      addToast(res.errors[0].message);
      return false;
    }
    return true;
  };

  const onUpdateHandler = async (form: FormInputs) => {
    const {audio, english, words, vocabularies} = getFormattedFields(form);

    setUpdateLoading(true);

    let audioUrl = audio;

    if (audioFile) {
      audioUrl = await uploadAudio(
        audioFile,
        `${data.items[selectedRow!].objectId}.aac`,
        "sentence",
      );
    }

    updateSentence({
      variables: {
        id: data.items[selectedRow!].objectId,
        fields: {
          audio: audioUrl,
          english,
          words,
          vocabularies,
          status: "pending",
          lastUpdatedAt: new Date(),
          approvedBy: {
            link: null,
          },
          lastUpdatedBy: {
            link: currentUser?.objectId,
          },
        },
      },
    }).then(async (res) => {
      if (saveValidation(res)) {
        if (vocabularies) {
          await updateVocabularyRelations(
            data.items[selectedRow!].objectId,
            vocabularies,
          );
        }
        onSaveComplete();
      }
    });
  };

  const onSaveHandler = (form: FormInputs) => {
    const {audio, english, words, vocabularies} = getFormattedFields(form);
    setUpdateLoading(true);

    createSentence({
      variables: {
        fields: {
          audio,
          english,
          words,
          vocabularies,
          lastUpdatedBy: {
            link: currentUser?.objectId,
          },
          lastUpdatedAt: new Date(),
          status: "pending",
        },
      },
    }).then(async (res) => {
      if (saveValidation(res)) {
        const objectId = res.data!.createSentence!.sentence.objectId;

        if (vocabularies) {
          await updateVocabularyRelations(objectId, vocabularies);
        }

        if (audioFile) {
          const audioUrl = await (audioFile
            ? uploadAudio(audioFile, `${objectId}.aac`, "sentence")
            : audio);

          updateSentence({
            variables: {
              id: objectId,
              fields: {
                audio: audioUrl,
              },
            },
          }).then((res) => {
            if (saveValidation(res)) {
              onSaveComplete();
              selectRow(null);
            }
          });
        } else {
          onSaveComplete();
          selectRow(null);
        }
      }
    });
  };

  const onApprovalHandler = (approved: boolean) => {
    updateSentence({
      variables: {
        id: data.items[selectedRow!].objectId,
        fields: {
          status: approved ? "approved" : "rejected",
          lastApprovedAt: new Date(),
          approvedBy: {
            link: currentUser?.objectId,
          },
        },
      },
    });
  };

  const onExportHandler = async () => {
    try {
      await exportSentences(exportJsonFile);
      addToast("Export completed.");
    } catch (error) {
      addToast(error.message);
    }
  };

  const onUploadAudioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAudioFile(event.target.files![0]);
  };

  return (
    <Layout>
      <BrowserLayout>
        <BrowserLayout.Browser>
          <BrowserHeader
            title="Sentences"
            role={currentUser?.role || null}
            usersPresence={presences}
            search={search}
            filter={filter}
            onSearch={onSearch}
            onCreateClick={() => {
              createMode();
              dispatch(updatePresenceStatus("Sentences", ""));
            }}
            onExportClick={onExportHandler}
          />
          <BrowserContent
            selectedRow={selectedRow}
            columns={COLUMNS}
            data={data.items}
            loading={loading}
            onRowClick={(i: number) => {
              dispatch(
                updatePresenceStatus("Sentences", data.items[i].objectId),
              );
              selectRow(i);
            }}
            onLoadMore={loadMore}
            hasNextPage={data.hasNextPage}
            count={data.count}
          />
          <BrowserFooter
            loading={loading || exportLoading}
            count={data.count}
          />
        </BrowserLayout.Browser>
        <BrowserLayout.Pane>
          <Panel
            lists={lists}
            isCreateMode={isCreateMode}
            role={currentUser?.role || null}
            loading={updateLoading || createLoading}
            presences={presences}
            onSave={onSaveHandler}
            onUpdate={onUpdateHandler}
            onApproval={onApprovalHandler}
            onUploadAudio={onUploadAudioHandler}
            data={selectedRow !== null && data.items[selectedRow]}
          />
        </BrowserLayout.Pane>
      </BrowserLayout>
    </Layout>
  );
};

export const SENTENCE_FRAGMENT = gql`
  fragment sentenceFragment on Sentence {
    id
    objectId
    english
    audio
    status
    words {
      ... on Element {
        value
      }
    }
    vocabularies {
      edges {
        node {
          hanzi
          objectId
          english
          pinyin
          lists {
            ... on List {
              objectId
              name
            }
          }
        }
      }
    }
    lastUpdatedAt
    lastApprovedAt
    approvedBy {
      name
      avatar {
        url
      }
    }
    lastUpdatedBy {
      name
      avatar {
        url
      }
    }
  }
`;

export const GET_SENTENCES = gql`
  query getSentences($filter: SentenceWhereInput, $cursor: String) {
    sentences(where: $filter, after: $cursor) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...sentenceFragment
        }
      }
    }
  }
  ${SENTENCE_FRAGMENT}
`;

export const UPDATE_SENTENCE = gql`
  mutation updateSentence($id: ID!, $fields: UpdateSentenceFieldsInput) {
    updateSentence(input: {id: $id, fields: $fields}) {
      clientMutationId
      sentence {
        ...sentenceFragment
      }
    }
  }
  ${SENTENCE_FRAGMENT}
`;

export const CREATE_SENTENCE = gql`
  mutation CreateSentence($fields: CreateSentenceFieldsInput!) {
    createSentence(input: {fields: $fields}) {
      clientMutationId
      sentence {
        ...sentenceFragment
      }
    }
  }
  ${SENTENCE_FRAGMENT}
`;

export default Sentences;
