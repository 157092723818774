import {FC} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import usePresence from "hooks/usePresence";
import NotFound from "features/NotFound";
import Home from "features/Home";
import Vocabulary from "features/Vocabulary";
import Sentences from "features/Sentences";
import Hanzi from "features/Hanzi";
import InstagramBrowse from "features/Instagram/Browse";
import InstagramVideoCreation from "features/Instagram/VideoCreation";
import instagramPost from "features/Instagram/Post";

export const PrivateRoutes: FC = () => {
  usePresence();

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/vocabulary" component={Vocabulary} />
        <Route exact path="/sentences" component={Sentences} />
        <Route exact path="/hanzi" component={Hanzi} />
        <Route exact path="/instagram" component={InstagramBrowse} />
        <Route exact path="/instagram/post" component={instagramPost} />
        <Route
          exact
          path="/instagram/creation"
          component={InstagramVideoCreation}
        />
        <Redirect exact from="/login" to="/" />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default PrivateRoutes;
