import getKeyValue from "./getKeyValue";

const pinyins = {
  a: ["a", "ā", "á", "ǎ", "à"],
  o: ["o", "ō", "ó", "ǒ", "ò"],
  e: ["e", "ē", "é", "ě", "è"],
  ai: ["ai", "āi", "ái", "ǎi", "ài"],
  ei: ["ei", "ēi", "éi", "ěi", "èi"],
  ao: ["ao", "āo", "áo", "ǎo", "ào"],
  ou: ["ou", "ōu", "óu", "ǒu", "òu"],
  an: ["an", "ān", "án", "ǎn", "àn"],
  en: ["en", "ēn", "én", "ěn", "èn"],
  ang: ["ang", "āng", "áng", "ǎng", "àng"],
  eng: ["eng", "ēng", "éng", "ěng", "èng"],
  er: ["er", "ēr", "ér", "ěr", "èr"],
  yi: ["yi", "yī", "yí", "yǐ", "yì"],
  ya: ["ya", "yā", "yá", "yǎ", "yà"],
  ye: ["ye", "yē", "yé", "yě", "yè"],
  yao: ["yao", "yāo", "yáo", "yǎo", "yào"],
  you: ["you", "yōu", "yóu", "yǒu", "yòu"],
  yan: ["yan", "yān", "yán", "yǎn", "yàn"],
  yin: ["yin", "yīn", "yín", "yǐn", "yìn"],
  ying: ["ying", "yīng", "yíng", "yǐng", "yìng"],
  yang: ["yang", "yāng", "yáng", "yǎng", "yàng"],
  yong: ["yong", "yōng", "yóng", "yǒng", "yòng"],
  wu: ["wu", "wū", "wú", "wǔ", "wù"],
  wa: ["wa", "wā", "wá", "wǎ", "wà"],
  wo: ["wo", "wō", "wó", "wǒ", "wò"],
  wai: ["wai", "wāi", "wái", "wǎi", "wài"],
  wei: ["wei", "wēi", "wéi", "wěi", "wèi"],
  wan: ["wan", "wān", "wán", "wǎn", "wàn"],
  wen: ["wen", "wēn", "wén", "wěn", "wèn"],
  wang: ["wang", "wāng", "wáng", "wǎng", "wàng"],
  weng: ["weng", "wēng", "wéng", "wěng", "wèng"],
  yu: ["wu", "yū", "yú", "yǔ", "yù"],
  yue: ["yue", "yuē", "yué", "yuě", "yuè"],
  yuan: ["yuan", "yuān", "yuán", "yuǎn", "yuàn"],
  yun: ["yun", "yūn", "yún", "yǔn", "yùn"],
  ba: ["ba", "bā", "bá", "bǎ", "bà"],
  bo: ["bo", "bō", "bó", "bǒ", "bò"],
  bai: ["bai", "bāi", "bái", "bǎi", "bài"],
  bei: ["bei", "bēi", "béi", "běi", "bèi"],
  bao: ["bao", "bāo", "báo", "bǎo", "bào"],
  ban: ["ban", "bān", "bán", "bǎn", "bàn"],
  ben: ["ben", "bēn", "bén", "běn", "bèn"],
  bang: ["bang", "bāng", "báng", "bǎng", "bàng"],
  beng: ["beng", "bēng", "béng", "běng", "bèng"],
  bi: ["bi", "bī", "bí", "bǐ", "bì"],
  bie: ["bie", "biē", "bié", "biě", "biè"],
  biao: ["biao", "biāo", "biáo", "biǎo", "biào"],
  bian: ["bian", "biān", "bián", "biǎn", "biàn"],
  bin: ["bin", "bīn", "bín", "bǐn", "bìn"],
  bing: ["bing", "bīng", "bíng", "bǐng", "bìng"],
  biang: ["biang", "biāng", "biáng", "biǎng", "biàng"],
  bu: ["bu", "bū", "bú", "bǔ", "bù"],
  pa: ["pa", "pā", "pá", "pǎ", "pà"],
  po: ["po", "pō", "pó", "pǒ", "pò"],
  pai: ["pai", "pāi", "pái", "pǎi", "pài"],
  pei: ["pei", "pēi", "péi", "pěi", "pèi"],
  pao: ["pao", "pāo", "páo", "pǎo", "pào"],
  pou: ["pou", "pōu", "póu", "pǒu", "pòu"],
  pan: ["pan", "pān", "pán", "pǎn", "pàn"],
  pen: ["pen", "pēn", "pén", "pěn", "pèn"],
  pang: ["pang", "pāng", "páng", "pǎng", "pàng"],
  peng: ["peng", "pēng", "péng", "pěng", "pèng"],
  pi: ["pi", "pī", "pí", "pǐ", "pì"],
  pie: ["pie", "piē", "pié", "piě", "piè"],
  piao: ["piao", "piāo", "piáo", "piǎo", "piào"],
  pian: ["pian", "piān", "pián", "piǎn", "piàn"],
  pin: ["pin", "pīn", "pín", "pǐn", "pìn"],
  ping: ["ping", "pīng", "píng", "pǐng", "pìng"],
  pu: ["pu", "pū", "pú", "pǔ", "pù"],
  ma: ["ma", "mā", "má", "mǎ", "mà"],
  mo: ["mo", "mō", "mó", "mǒ", "mò"],
  me: ["me", "mē", "mé", "mě", "mè"],
  mai: ["mai", "māi", "mái", "mǎi", "mài"],
  mei: ["mei", "mēi", "méi", "měi", "mèi"],
  mao: ["mao", "māo", "máo", "mǎo", "mào"],
  mou: ["mou", "mōu", "móu", "mǒu", "mòu"],
  man: ["man", "mān", "mán", "mǎn", "màn"],
  men: ["men", "mēn", "mén", "měn", "mèn"],
  mang: ["mang", "māng", "máng", "mǎng", "màng"],
  meng: ["meng", "mēng", "méng", "měng", "mèng"],
  mi: ["mi", "mī", "mí", "mǐ", "mì"],
  mie: ["mie", "miē", "mié", "miě", "miè"],
  miao: ["miao", "miāo", "miáo", "miǎo", "miào"],
  miu: ["miu", "miū", "miú", "miǔ", "miù"],
  mian: ["mian", "miān", "mián", "miǎn", "miàn"],
  min: ["min", "mīn", "mín", "mǐn", "mìn"],
  ming: ["ming", "mīng", "míng", "mǐng", "mìng"],
  mu: ["mu", "mū", "mú", "mǔ", "mù"],
  fa: ["fa", "fā", "fá", "fǎ", "fà"],
  fo: ["fo", "fō", "fó", "fǒ", "fò"],
  fei: ["fei", "fēi", "féi", "fěi", "fèi"],
  fou: ["fou", "fōu", "fóu", "fǒu", "fòu"],
  fan: ["fan", "fān", "fán", "fǎn", "fàn"],
  fen: ["fen", "fēn", "fén", "fěn", "fèn"],
  fang: ["fang", "fāng", "fáng", "fǎng", "fàng"],
  feng: ["feng", "fēng", "féng", "fěng", "fèng"],
  fiao: ["fiao", "fiāo", "fiáo", "fiǎo", "fiào"],
  fu: ["fu", "fū", "fú", "fǔ", "fù"],
  da: ["da", "dā", "dá", "dǎ", "dà"],
  de: ["de", "dē", "dé", "dě", "dè"],
  dai: ["dai", "dāi", "dái", "dǎi", "dài"],
  dei: ["dei", "dēi", "déi", "děi", "dèi"],
  dao: ["dao", "dāo", "dáo", "dǎo", "dào"],
  dou: ["dou", "dōu", "dóu", "dǒu", "dòu"],
  dan: ["dan", "dān", "dán", "dǎn", "dàn"],
  den: ["den", "dēn", "dén", "děn", "dèn"],
  dang: ["dang", "dāng", "dáng", "dǎng", "dàng"],
  deng: ["deng", "dēng", "déng", "děng", "dèng"],
  dong: ["dong", "dōng", "dóng", "dǒng", "dòng"],
  di: ["di", "dī", "dí", "dǐ", "dì"],
  dia: ["dia", "diā", "diá", "diǎ", "dià"],
  die: ["die", "diē", "dié", "diě", "diè"],
  diao: ["diao", "diāo", "diáo", "diǎo", "diào"],
  diu: ["diu", "diū", "diú", "diǔ", "diù"],
  dian: ["dian", "diān", "dián", "diǎn", "diàn"],
  ding: ["ding", "dīng", "díng", "dǐng", "dìng"],
  diang: ["diang", "diāng", "diáng", "diǎng", "diàng"],
  du: ["du", "dū", "dú", "dǔ", "dù"],
  duo: ["duo", "duō", "duó", "duǒ", "duò"],
  dui: ["dui", "duī", "duí", "duǐ", "duì"],
  duan: ["duan", "duān", "duán", "duǎn", "duàn"],
  dun: ["dun", "dūn", "dún", "dǔn", "dùn"],
  ta: ["ta", "tā", "tá", "tǎ", "tà"],
  te: ["te", "tē", "té", "tě", "tè"],
  tai: ["tai", "tāi", "tái", "tǎi", "tài"],
  tao: ["tao", "tāo", "táo", "tǎo", "tào"],
  tou: ["tou", "tōu", "tóu", "tǒu", "tòu"],
  tan: ["tan", "tān", "tán", "tǎn", "tàn"],
  tang: ["tang", "tāng", "táng", "tǎng", "tàng"],
  teng: ["teng", "tēng", "téng", "těng", "tèng"],
  tong: ["tong", "tōng", "tóng", "tǒng", "tòng"],
  ti: ["ti", "tī", "tí", "tǐ", "tì"],
  tie: ["tie", "tiē", "tié", "tiě", "tiè"],
  tiao: ["tiao", "tiāo", "tiáo", "tiǎo", "tiào"],
  tian: ["tian", "tiān", "tián", "tiǎn", "tiàn"],
  ting: ["ting", "tīng", "tíng", "tǐng", "tìng"],
  tu: ["tu", "tū", "tú", "tǔ", "tù"],
  tuo: ["tuo", "tuō", "tuó", "tuǒ", "tuò"],
  tui: ["tui", "tuī", "tuí", "tuǐ", "tuì"],
  tuan: ["tuan", "tuān", "tuán", "tuǎn", "tuàn"],
  tun: ["tun", "tūn", "tún", "tǔn", "tùn"],
  na: ["na", "nā", "ná", "nǎ", "nà"],
  ne: ["ne", "nē", "né", "ně", "nè"],
  nai: ["nai", "nāi", "nái", "nǎi", "nài"],
  nei: ["nei", "nēi", "néi", "něi", "nèi"],
  nao: ["nao", "nāo", "náo", "nǎo", "nào"],
  nou: ["nou", "nōu", "nóu", "nǒu", "nòu"],
  nan: ["nan", "nān", "nán", "nǎn", "nàn"],
  nen: ["nen", "nēn", "nén", "něn", "nèn"],
  nang: ["nang", "nāng", "náng", "nǎng", "nàng"],
  neng: ["neng", "nēng", "néng", "něng", "nèng"],
  nong: ["nong", "nōng", "nóng", "nǒng", "nòng"],
  ni: ["ni", "nī", "ní", "nǐ", "nì"],
  nia: ["nia", "niā", "niá", "niǎ", "nià"],
  nie: ["nie", "niē", "nié", "niě", "niè"],
  niao: ["niao", "niāo", "niáo", "niǎo", "niào"],
  niu: ["niu", "niū", "niú", "niǔ", "niù"],
  nian: ["nian", "niān", "nián", "niǎn", "niàn"],
  nin: ["nin", "nīn", "nín", "nǐn", "nìn"],
  ning: ["ning", "nīng", "níng", "nǐng", "nìng"],
  niang: ["niang", "niāng", "niáng", "niǎng", "niàng"],
  nu: ["nu", "nū", "nú", "nǔ", "nù"],
  nuo: ["nuo", "nuō", "nuó", "nuǒ", "nuò"],
  nuan: ["nuan", "nuān", "nuán", "nuǎn", "nuàn"],
  nun: ["nun", "nūn", "nún", "nǔn", "nùn"],
  nü: ["nü", "nǖ", "nǘ", "nǚ", "nǜ"],
  nv: ["nü", "nǖ", "nǘ", "nǚ", "nǜ"],
  nüe: ["nüe", "nüē", "nüé", "nüě", "nüè"],
  nve: ["nüe", "nüē", "nüé", "nüě", "nüè"],
  la: ["la", "lā", "lá", "lǎ", "là"],
  lo: ["lo", "lō", "ló", "lǒ", "lò"],
  le: ["le", "lē", "lé", "lě", "lè"],
  lai: ["lai", "lāi", "lái", "lǎi", "lài"],
  lei: ["lei", "lēi", "léi", "lěi", "lèi"],
  lao: ["lao", "lāo", "láo", "lǎo", "lào"],
  lou: ["lou", "lōu", "lóu", "lǒu", "lòu"],
  lan: ["lan", "lān", "lán", "lǎn", "làn"],
  lang: ["lang", "lāng", "láng", "lǎng", "làng"],
  leng: ["leng", "lēng", "léng", "lěng", "lèng"],
  long: ["long", "lōng", "lóng", "lǒng", "lòng"],
  li: ["li", "lī", "lí", "lǐ", "lì"],
  lia: ["lia", "liā", "liá", "liǎ", "lià"],
  lie: ["lie", "liē", "lié", "liě", "liè"],
  liao: ["liao", "liāo", "liáo", "liǎo", "liào"],
  liu: ["liu", "liū", "liú", "liǔ", "liù"],
  lian: ["lian", "liān", "lián", "liǎn", "liàn"],
  lin: ["lin", "līn", "lín", "lǐn", "lìn"],
  ling: ["ling", "līng", "líng", "lǐng", "lìng"],
  liang: ["liang", "liāng", "liáng", "liǎng", "liàng"],
  lu: ["lu", "lū", "lú", "lǔ", "lù"],
  luo: ["luo", "luō", "luó", "luǒ", "luò"],
  luan: ["luan", "luān", "luán", "luǎn", "luàn"],
  lun: ["lun", "lūn", "lún", "lǔn", "lùn"],
  lü: ["lü", "lǖ", "lǘ", "lǚ", "lǜ"],
  lv: ["lü", "lǖ", "lǘ", "lǚ", "lǜ"],
  lüe: ["lüe", "lüē", "lüé", "lüě", "lüè"],
  lve: ["lüe", "lüē", "lüé", "lüě", "lüè"],
  lüan: ["lüan", "lüān", "lüán", "lüǎn", "lüàn"],
  lvan: ["lüan", "lüān", "lüán", "lüǎn", "lüàn"],
  lün: ["lün", "lǖn", "lǘn", "lǚn", "lǜn"],
  lvn: ["lün", "lǖn", "lǘn", "lǚn", "lǜn"],
  ga: ["ga", "gā", "gá", "gǎ", "gà"],
  ge: ["ge", "gē", "gé", "gě", "gè"],
  gai: ["gai", "gāi", "gái", "gǎi", "gài"],
  gei: ["gei", "gēi", "géi", "gěi", "gèi"],
  gao: ["gao", "gāo", "gáo", "gǎo", "gào"],
  gou: ["gou", "gōu", "góu", "gǒu", "gòu"],
  gan: ["gan", "gān", "gán", "gǎn", "gàn"],
  gen: ["gen", "gēn", "gén", "gěn", "gèn"],
  gang: ["gang", "gāng", "gáng", "gǎng", "gàng"],
  geng: ["geng", "gēng", "géng", "gěng", "gèng"],
  gong: ["gong", "gōng", "góng", "gǒng", "gòng"],
  gu: ["gu", "gū", "gú", "gǔ", "gù"],
  gua: ["gua", "guā", "guá", "guǎ", "guà"],
  guo: ["guo", "guō", "guó", "guǒ", "guò"],
  guai: ["guai", "guāi", "guái", "guǎi", "guài"],
  gui: ["gui", "guī", "guí", "guǐ", "guì"],
  guan: ["guan", "guān", "guán", "guǎn", "guàn"],
  gun: ["gun", "gūn", "gún", "gǔn", "gùn"],
  guang: ["guang", "guāng", "guáng", "guǎng", "guàng"],
  ka: ["ka", "kā", "ká", "kǎ", "kà"],
  ke: ["ke", "kē", "ké", "kě", "kè"],
  kai: ["kai", "kāi", "kái", "kǎi", "kài"],
  kei: ["kei", "kēi", "kéi", "kěi", "kèi"],
  kao: ["kao", "kāo", "káo", "kǎo", "kào"],
  kou: ["kou", "kōu", "kóu", "kǒu", "kòu"],
  kan: ["kan", "kān", "kán", "kǎn", "kàn"],
  ken: ["ken", "kēn", "kén", "kěn", "kèn"],
  kang: ["kang", "kāng", "káng", "kǎng", "kàng"],
  keng: ["keng", "kēng", "kéng", "kěng", "kèng"],
  kong: ["kong", "kōng", "kóng", "kǒng", "kòng"],
  ku: ["ku", "kū", "kú", "kǔ", "kù"],
  kua: ["kua", "kuā", "kuá", "kuǎ", "kuà"],
  kuo: ["kuo", "kuō", "kuó", "kuǒ", "kuò"],
  kuai: ["kuai", "kuāi", "kuái", "kuǎi", "kuài"],
  kui: ["kui", "kuī", "kuí", "kuǐ", "kuì"],
  kuan: ["kuan", "kuān", "kuán", "kuǎn", "kuàn"],
  kun: ["kun", "kūn", "kún", "kǔn", "kùn"],
  kuang: ["kuang", "kuāng", "kuáng", "kuǎng", "kuàng"],
  he: ["he", "hē", "hé", "hě", "hè"],
  ha: ["ha", "hā", "há", "hǎ", "hà"],
  hai: ["hai", "hāi", "hái", "hǎi", "hài"],
  hei: ["hei", "hēi", "héi", "hěi", "hèi"],
  hao: ["hao", "hāo", "háo", "hǎo", "hào"],
  hou: ["hou", "hōu", "hóu", "hǒu", "hòu"],
  han: ["han", "hān", "hán", "hǎn", "hàn"],
  hen: ["hen", "hēn", "hén", "hěn", "hèn"],
  hang: ["hang", "hāng", "háng", "hǎng", "hàng"],
  heng: ["heng", "hēng", "héng", "hěng", "hèng"],
  hong: ["hong", "hōng", "hóng", "hǒng", "hòng"],
  hu: ["hu", "hū", "hú", "hǔ", "hù"],
  hua: ["hua", "huā", "huá", "huǎ", "huà"],
  huo: ["huo", "huō", "huó", "huǒ", "huò"],
  huai: ["huai", "huāi", "huái", "huǎi", "huài"],
  hui: ["hui", "huī", "huí", "huǐ", "huì"],
  huan: ["huan", "huān", "huán", "huǎn", "huàn"],
  hun: ["hun", "hūn", "hún", "hǔn", "hùn"],
  huang: ["huang", "huāng", "huáng", "huǎng", "huàng"],
  ji: ["ji", "jī", "jí", "jǐ", "jì"],
  jia: ["jia", "jiā", "jiá", "jiǎ", "jià"],
  jie: ["jie", "jiē", "jié", "jiě", "jiè"],
  jiao: ["jiao", "jiāo", "jiáo", "jiǎo", "jiào"],
  jiu: ["jiu", "jiū", "jiú", "jiǔ", "jiù"],
  jian: ["jian", "jiān", "jián", "jiǎn", "jiàn"],
  jin: ["jin", "jīn", "jín", "jǐn", "jìn"],
  jing: ["jing", "jīng", "jíng", "jǐng", "jìng"],
  jiang: ["jiang", "jiāng", "jiáng", "jiǎng", "jiàng"],
  jiong: ["jiong", "jiōng", "jióng", "jiǒng", "jiòng"],
  ju: ["ju", "jū", "jú", "jǔ", "jù"],
  jue: ["jue", "juē", "jué", "juě", "juè"],
  juan: ["juan", "juān", "juán", "juǎn", "juàn"],
  jun: ["jun", "jūn", "jún", "jǔn", "jùn"],
  qi: ["qi", "qī", "qí", "qǐ", "qì"],
  qia: ["qia", "qiā", "qiá", "qiǎ", "qià"],
  qie: ["qie", "qiē", "qié", "qiě", "qiè"],
  qiao: ["qiao", "qiāo", "qiáo", "qiǎo", "qiào"],
  qiu: ["qiu", "qiū", "qiú", "qiǔ", "qiù"],
  qian: ["qian", "qiān", "qián", "qiǎn", "qiàn"],
  qin: ["qin", "qīn", "qín", "qǐn", "qìn"],
  qing: ["qing", "qīng", "qíng", "qǐng", "qìng"],
  qiang: ["qiang", "qiāng", "qiáng", "qiǎng", "qiàng"],
  qiong: ["qiong", "qiōng", "qióng", "qiǒng", "qiòng"],
  qu: ["qu", "qū", "qú", "qǔ", "qù"],
  que: ["que", "quē", "qué", "quě", "què"],
  quan: ["quan", "quān", "quán", "quǎn", "quàn"],
  qun: ["qun", "qūn", "qún", "qǔn", "qùn"],
  xi: ["xi", "xī", "xí", "xǐ", "xì"],
  xia: ["xia", "xiā", "xiá", "xiǎ", "xià"],
  xie: ["xie", "xiē", "xié", "xiě", "xiè"],
  xiao: ["xiao", "xiāo", "xiáo", "xiǎo", "xiào"],
  xiu: ["xiu", "xiū", "xiú", "xiǔ", "xiù"],
  xian: ["xian", "xiān", "xián", "xiǎn", "xiàn"],
  xin: ["xin", "xīn", "xín", "xǐn", "xìn"],
  xing: ["xing", "xīng", "xíng", "xǐng", "xìng"],
  xiang: ["xiang", "xiāng", "xiáng", "xiǎng", "xiàng"],
  xiong: ["xiong", "xiōng", "xióng", "xiǒng", "xiòng"],
  xu: ["xu", "xū", "xú", "xǔ", "xù"],
  xue: ["xue", "xuē", "xué", "xuě", "xuè"],
  xuan: ["xuan", "xuān", "xuán", "xuǎn", "xuàn"],
  xun: ["xun", "xūn", "xún", "xǔn", "xùn"],
  zhi: ["zhi", "zhī", "zhí", "zhǐ", "zhì"],
  zha: ["zha", "zhā", "zhá", "zhǎ", "zhà"],
  zhe: ["zhe", "zhē", "zhé", "zhě", "zhè"],
  zhai: ["zhai", "zhāi", "zhái", "zhǎi", "zhài"],
  zhei: ["zhei", "zhēi", "zhéi", "zhěi", "zhèi"],
  zhao: ["zhao", "zhāo", "zháo", "zhǎo", "zhào"],
  zhou: ["zhou", "zhōu", "zhóu", "zhǒu", "zhòu"],
  zhan: ["zhan", "zhān", "zhán", "zhǎn", "zhàn"],
  zhen: ["zhen", "zhēn", "zhén", "zhěn", "zhèn"],
  zhang: ["zhang", "zhāng", "zháng", "zhǎng", "zhàng"],
  zheng: ["zheng", "zhēng", "zhéng", "zhěng", "zhèng"],
  zhong: ["zhong", "zhōng", "zhóng", "zhǒng", "zhòng"],
  zhu: ["zhu", "zhū", "zhú", "zhǔ", "zhù"],
  zhua: ["zhua", "zhuā", "zhuá", "zhuǎ", "zhuà"],
  zhuo: ["zhuo", "zhuō", "zhuó", "zhuǒ", "zhuò"],
  zhuai: ["zhuai", "zhuāi", "zhuái", "zhuǎi", "zhuài"],
  zhui: ["zhui", "zhuī", "zhuí", "zhuǐ", "zhuì"],
  zhuan: ["zhuan", "zhuān", "zhuán", "zhuǎn", "zhuàn"],
  zhun: ["zhun", "zhūn", "zhún", "zhǔn", "zhùn"],
  zhuang: ["zhuang", "zhuāng", "zhuáng", "zhuǎng", "zhuàng"],
  chi: ["chi", "chī", "chí", "chǐ", "chì"],
  cha: ["cha", "chā", "chá", "chǎ", "chà"],
  che: ["che", "chē", "ché", "chě", "chè"],
  chai: ["chai", "chāi", "chái", "chǎi", "chài"],
  chao: ["chao", "chāo", "cháo", "chǎo", "chào"],
  chou: ["chou", "chōu", "chóu", "chǒu", "chòu"],
  chan: ["chan", "chān", "chán", "chǎn", "chàn"],
  chen: ["chen", "chēn", "chén", "chěn", "chèn"],
  chang: ["chang", "chāng", "cháng", "chǎng", "chàng"],
  cheng: ["cheng", "chēng", "chéng", "chěng", "chèng"],
  chong: ["chong", "chōng", "chóng", "chǒng", "chòng"],
  chu: ["chu", "chū", "chú", "chǔ", "chù"],
  chua: ["chua", "chuā", "chuá", "chuǎ", "chuà"],
  chuo: ["chuo", "chuō", "chuó", "chuǒ", "chuò"],
  chuai: ["chuai", "chuāi", "chuái", "chuǎi", "chuài"],
  chui: ["chui", "chuī", "chuí", "chuǐ", "chuì"],
  chuan: ["chuan", "chuān", "chuán", "chuǎn", "chuàn"],
  chun: ["chun", "chūn", "chún", "chǔn", "chùn"],
  chuang: ["chuang", "chuāng", "chuáng", "chuǎng", "chuàng"],
  shi: ["shi", "shī", "shí", "shǐ", "shì"],
  sha: ["sha", "shā", "shá", "shǎ", "shà"],
  she: ["she", "shē", "shé", "shě", "shè"],
  shai: ["shai", "shāi", "shái", "shǎi", "shài"],
  shei: ["shei", "shēi", "shéi", "shěi", "shèi"],
  shao: ["shao", "shāo", "sháo", "shǎo", "shào"],
  shou: ["shou", "shōu", "shóu", "shǒu", "shòu"],
  shan: ["shan", "shān", "shán", "shǎn", "shàn"],
  shen: ["shen", "shēn", "shén", "shěn", "shèn"],
  shang: ["shang", "shāng", "sháng", "shǎng", "shàng"],
  sheng: ["sheng", "shēng", "shéng", "shěng", "shèng"],
  shong: ["shong", "shōng", "shóng", "shǒng", "shòng"],
  shu: ["shu", "shū", "shú", "shǔ", "shù"],
  shua: ["shua", "shuā", "shuá", "shuǎ", "shuà"],
  shuo: ["shuo", "shuō", "shuó", "shuǒ", "shuò"],
  shuai: ["shuai", "shuāi", "shuái", "shuǎi", "shuài"],
  shui: ["shui", "shuī", "shuí", "shuǐ", "shuì"],
  shuan: ["shuan", "shuān", "shuán", "shuǎn", "shuàn"],
  shun: ["shun", "shūn", "shún", "shǔn", "shùn"],
  shuang: ["shuang", "shuāng", "shuáng", "shuǎng", "shuàng"],
  ri: ["ri", "rī", "rí", "rǐ", "rì"],
  re: ["re", "rē", "ré", "rě", "rè"],
  rao: ["rao", "rāo", "ráo", "rǎo", "rào"],
  rou: ["rou", "rōu", "róu", "rǒu", "ròu"],
  ran: ["ran", "rān", "rán", "rǎn", "ràn"],
  ren: ["ren", "rēn", "rén", "rěn", "rèn"],
  rang: ["rang", "rāng", "ráng", "rǎng", "ràng"],
  reng: ["reng", "rēng", "réng", "rěng", "rèng"],
  rong: ["rong", "rōng", "róng", "rǒng", "ròng"],
  ru: ["ru", "rū", "rú", "rǔ", "rù"],
  rua: ["rua", "ruā", "ruá", "ruǎ", "ruà"],
  ruo: ["ruo", "ruō", "ruó", "ruǒ", "ruò"],
  rui: ["rui", "ruī", "ruí", "ruǐ", "ruì"],
  ruan: ["ruan", "ruān", "ruán", "ruǎn", "ruàn"],
  run: ["run", "rūn", "rún", "rǔn", "rùn"],
  zi: ["zi", "zī", "zí", "zǐ", "zì"],
  za: ["za", "zā", "zá", "zǎ", "zà"],
  ze: ["ze", "zē", "zé", "zě", "zè"],
  zai: ["zai", "zāi", "zái", "zǎi", "zài"],
  zei: ["zei", "zēi", "zéi", "zěi", "zèi"],
  zao: ["zao", "zāo", "záo", "zǎo", "zào"],
  zou: ["zou", "zōu", "zóu", "zǒu", "zòu"],
  zan: ["zan", "zān", "zán", "zǎn", "zàn"],
  zen: ["zen", "zēn", "zén", "zěn", "zèn"],
  zang: ["zang", "zāng", "záng", "zǎng", "zàng"],
  zeng: ["zeng", "zēng", "zéng", "zěng", "zèng"],
  zong: ["zong", "zōng", "zóng", "zǒng", "zòng"],
  zu: ["zu", "zū", "zú", "zǔ", "zù"],
  zuo: ["zuo", "zuō", "zuó", "zuǒ", "zuò"],
  zui: ["zui", "zuī", "zuí", "zuǐ", "zuì"],
  zuan: ["zuan", "zuān", "zuán", "zuǎn", "zuàn"],
  zun: ["zun", "zūn", "zún", "zǔn", "zùn"],
  ci: ["ci", "cī", "cí", "cǐ", "cì"],
  ca: ["ca", "cā", "cá", "cǎ", "cà"],
  ce: ["ce", "cē", "cé", "cě", "cè"],
  cai: ["cai", "cāi", "cái", "cǎi", "cài"],
  cao: ["cao", "cāo", "cáo", "cǎo", "cào"],
  cou: ["cou", "cōu", "cóu", "cǒu", "còu"],
  can: ["can", "cān", "cán", "cǎn", "càn"],
  cen: ["cen", "cēn", "cén", "cěn", "cèn"],
  cang: ["cang", "cāng", "cáng", "cǎng", "càng"],
  ceng: ["ceng", "cēng", "céng", "cěng", "cèng"],
  cong: ["cong", "cōng", "cóng", "cǒng", "còng"],
  cu: ["cu", "cū", "cú", "cǔ", "cù"],
  cuo: ["cuo", "cuō", "cuó", "cuǒ", "cuò"],
  cui: ["cui", "cuī", "cuí", "cuǐ", "cuì"],
  cuan: ["cuan", "cuān", "cuán", "cuǎn", "cuàn"],
  cun: ["cun", "cūn", "cún", "cǔn", "cùn"],
  si: ["si", "sī", "sí", "sǐ", "sì"],
  sa: ["sa", "sā", "sá", "sǎ", "sà"],
  se: ["se", "sē", "sé", "sě", "sè"],
  sai: ["sai", "sāi", "sái", "sǎi", "sài"],
  sei: ["sei", "sēi", "séi", "sěi", "sèi"],
  sao: ["sao", "sāo", "sáo", "sǎo", "sào"],
  sou: ["sou", "sōu", "sóu", "sǒu", "sòu"],
  san: ["san", "sān", "sán", "sǎn", "sàn"],
  sen: ["sen", "sēn", "sén", "sěn", "sèn"],
  sang: ["sang", "sāng", "sáng", "sǎng", "sàng"],
  seng: ["seng", "sēng", "séng", "sěng", "sèng"],
  song: ["song", "sōng", "sóng", "sǒng", "sòng"],
  su: ["su", "sū", "sú", "sǔ", "sù"],
  suo: ["suo", "suō", "suó", "suǒ", "suò"],
  sui: ["sui", "suī", "suí", "suǐ", "suì"],
  suan: ["suan", "suān", "suán", "suǎn", "suàn"],
  sun: ["sun", "sūn", "sún", "sǔn", "sùn"],
  r: ["r"],
};

export const getTones = (pinyinsStr: string) => {
  const exceptions = {
    nüe: "nüe",
    nüē: "nüe",
    nüé: "nüe",
    nüě: "nüe",
    nüè: "nüe", //
    nü: "nü",
    nǖ: "nü",
    nǘ: "nü",
    nǚ: "nü",
    nǜ: "nü", //
    lüe: "lüe",
    lüē: "lüe",
    lüé: "lüe",
    lüě: "lüe",
    lüè: "lüe", //
    lü: "lü",
    lǖ: "lü",
    lǘ: "lü",
    lǚ: "lü",
    lǜ: "lü", //
    lün: "lün",
    lǖn: "lün",
    lǘn: "lün",
    lǚn: "lün",
    lǜn: "lün",
  };

  try {
    return pinyinsStr.split(" ").map((pinyin: string) => {
      const tonelessPinyin = getKeyValue(exceptions, pinyin as any)
        ? getKeyValue(exceptions, pinyin as any)
        : pinyin.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      const row = getKeyValue(pinyins, tonelessPinyin as any) as string[];

      const tone = row.findIndex((x) => x === pinyin);
      if (tone === -1) throw Error(`Tone not found for pinyin: ${pinyin}`);
      return tone;
    });
  } catch (error) {
    throw Error(`${error.message}: ${pinyinsStr}`);
  }
};

export default pinyins;
