import React, {FC} from "react";

import styles from "./index.module.scss";
import Status from "components/Status";
import getKeyValue from "helpers/getKeyValue";
import {Columns} from "components/Browser";
import concatStyles from "helpers/concatStyles";
import SkeletonRow from "components/Browser/SkeletonRow";

interface Props {
  data: {
    columns: Columns;
    data: any;
    selectedRow: number | null;
    isRowLoaded: (index: number) => boolean;
    onClick: (index: number) => void;
  };
  index: number;
  style: any;
}

const BrowserRow: FC<Props> = ({
  data: {data, columns, selectedRow, isRowLoaded, onClick},
  index,
  style,
}) => {
  const isSelected = selectedRow === index;

  if (!isRowLoaded(index)) {
    return <SkeletonRow style={style} columns={columns} />;
  }

  const renderColumn = (key: string) => {
    switch (key) {
      case "status":
        return (
          <Status
            status={data[index] && getKeyValue(data[index], key as any)}
          />
        );
      case "instagram":
        const value =
          data[index] && getKeyValue(data[index], key as any) ? "✅" : "";

        return <span className={styles.cell}>{value}</span>;
      default:
        return (
          <span className={styles.cell}>
            {data[index] && getKeyValue(data[index], key as any)}
          </span>
        );
    }
  };

  return (
    <div
      onClick={() => onClick(index)}
      style={style}
      className={concatStyles([
        styles.container,
        isSelected ? styles.active : undefined,
      ])}>
      {columns.map((column) => (
        <div
          className={styles.column}
          key={column.key}
          style={{width: `${column.width * 100}%`}}>
          {renderColumn(column.key)}
        </div>
      ))}
    </div>
  );
};

export default BrowserRow;
