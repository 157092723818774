import React, {FC, memo} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";

interface Props {
  className?: string;
  usersPresence: {id: string; avatar: string; page: string; item: string}[];
}

const Presence: FC<Props> = ({className, usersPresence}) => {
  return (
    <div
      style={{width: 26 * usersPresence.length}}
      className={concatStyles([styles.container, className])}>
      {usersPresence.map((userPresence) => (
        <img
          key={userPresence.id}
          alt="avatar"
          className={concatStyles([styles.avatar, styles.animate])}
          src={userPresence.avatar}
        />
      ))}
    </div>
  );
};

export default memo(Presence);
