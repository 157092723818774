import React, {FC} from "react";

import styles from "./index.module.scss";
import {Button} from "components/Button";
import UserMeta from "components/UserMeta";
import Approval from "components/Approval";
import {UserPresence} from "state/presence";
import Presence from "components/Presence";

interface Props {
  data: any;
  role: string | null;
  loading: boolean;
  isCreateMode: boolean;
  buttonDisabled: boolean;
  presences: UserPresence[];
  onApproval: (approved: boolean) => void;
}

const Panel: FC<Props> = ({
  data,
  role,
  loading = false,
  isCreateMode = false,
  buttonDisabled = false,
  presences,
  onApproval,
}) => {
  return (
    <>
      <div className={styles.actionBar}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{isCreateMode ? "New" : "Edit"}</span>
          <span className={styles.id}>{data && data.objectId}</span>
        </div>
        {!isCreateMode && role === "admin" && (
          <Approval status={data.status} onClick={onApproval} />
        )}
        <Button
          disabled={!buttonDisabled}
          loading={loading}
          className={styles.button}
          text={isCreateMode ? "Save" : "Update"}
        />
      </div>
      {!isCreateMode && (
        <div className={styles.userMeta}>
          {data.lastUpdatedBy && (
            <UserMeta
              className={styles.lastUpdatedBy}
              date={new Date(data.lastUpdatedAt)}
              avatar={
                data.lastUpdatedBy.avatar && data.lastUpdatedBy.avatar.url
              }
              title={`Updated By ${data.lastUpdatedBy.name}`}
            />
          )}
          {data.approvedBy && (
            <UserMeta
              date={new Date(data.lastApprovedAt)}
              avatar={data.approvedBy.avatar && data.approvedBy.avatar.url}
              title={`${data.status} By ${data.approvedBy.name}`}
            />
          )}
          <Presence className={styles.presence} usersPresence={presences} />
        </div>
      )}
    </>
  );
};

export default Panel;
