import {FC} from "react";

import styles from "./index.module.scss";

interface Props {
  className?: string;
  size?: number;
}

export const Spinner: FC<Props> = ({className, size = 32}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className={className}
      data-testid="spinner">
      <circle className={styles.circle} fill="#128DC7" r="16" cx="16" cy="16" />
      <path
        className={styles.path}
        d="M13,32 L13,12 C21.6666667,14.6666667 26,17.3333333 26,20 C26,24 19,26 16,26 C13,26 6,24 6,16 C6,8 12,6 16,6 C20,6 26,9 26,12 C26,15 18,19 16,20"
        id="path"
        strokeWidth="1.5"
        stroke="#FFFFFF"
        fillOpacity="0"
        strokeLinecap="round"
        strokeLinejoin="round"></path>
    </svg>
  );
};

export default Spinner;
