import * as d3 from "d3-interpolate";
import Confetti from "./CanvaConfetti";

import Stroke from "./CanvaStroke";

const ease = (x: number) => -Math.cos(x * Math.PI) / 2 + 0.5;

type Hanzi = {
  strokes: string[];
  medians: [number, number][][];
};

interface State {
  currentStroke: number;
  duration: number;
  strokesCompleted: boolean;
  strokes: {
    color: string;
    opacity: number;
    progress: number;
  }[];
}

class Engine {
  startTime?: number;
  frameHandle?: number;
  renderFrame!: (state: State) => void;
  state: State;

  constructor(strokesCount: number) {
    this.state = {
      currentStroke: 0,
      strokesCompleted: false,
      duration: 1000,
      strokes: [...Array(strokesCount)].map((_, i) => {
        return {opacity: i === 0 ? 1 : 0, progress: 0, color: "black"};
      }),
    };
  }

  run() {
    this.startTime = performance.now();
    this.frameHandle = requestAnimationFrame(this.tick);
  }

  tick = (timing: number) => {
    const progress = this.getProgress(timing, this.state.duration);
    const easedProgress = ease(progress);

    if (!this.state.strokesCompleted) {
      this.state.strokes[this.state.currentStroke].progress = easedProgress;
      // active stroke color
      this.state.strokes[this.state.currentStroke].color = "rgb(46,177,236)";
      // prev stroke color fading to black
      if (this.state.currentStroke > 0) {
        this.state.strokes[
          this.state.currentStroke - 1
        ].color = d3.interpolateRgb("rgb(46,177,236)", "black")(easedProgress);
      }
      if (progress >= 1) {
        this.startTime = performance.now();
        if (this.state.currentStroke === this.state.strokes.length - 1) {
          // finished strokes
          this.state.duration = 1000;
          this.state.strokesCompleted = true;
        } else {
          this.state.currentStroke++;
        }
        this.state.strokes[this.state.currentStroke].opacity = 1;
      }
    } else {
      if (progress < 1) {
        this.state.strokes = this.state.strokes.map(() => ({
          progress: 1,
          opacity: 1,
          color: d3.interpolateRgb(
            "black",
            "rgba(233,237,239,1)",
          )(easedProgress),
        }));
      }
    }

    this.renderFrame({...this.state});
    this.frameHandle = requestAnimationFrame(this.tick);
  };

  getProgress(timing: number, duration: number) {
    return Math.min(1, (timing - this.startTime!) / duration);
  }
}

export const useStrokesAnimation = () => {
  const run = (canvas: HTMLCanvasElement, hanzi: Hanzi, paint: boolean) => {
    const ctx = canvas.getContext("2d")!;
    const {width, height} = canvas;
    const center = width / 2;

    const strokes = hanzi.strokes.map(
      (stroke, i) => new Stroke(stroke, hanzi!.medians[i]),
    );

    const confetti = new Confetti(canvas, {
      startVelocity: 30,
      spread: 360,
      ticks: 200,
      decay: 0.95,
      scalar: 1.6,
      particleCount: 200,
    });
    const confetti2 = new Confetti(canvas, {
      startVelocity: 50,
      spread: 360,
      ticks: 200,
      scalar: 2.4,
      decay: 0.98,
      particleCount: 200,
    });

    const engine = new Engine(strokes.length);
    engine.renderFrame = (state) => {
      renderBackground();
      renderGuide();
      if (state.strokesCompleted) {
        confetti.render(ctx);
        confetti2.render(ctx);
      }
      renderHanzi(state);
    };

    const scale = (factor: number) => {
      const translation = (width - factor * width) / 2;
      ctx.translate(translation + 20, translation + 20);
      ctx.scale(factor, factor);
    };

    const renderHanzi = (state: State) => {
      ctx.save();
      scale(1.2);
      strokes.forEach((stroke, i) => {
        stroke.renderStroke(ctx, state.strokes[i]);
      });
      ctx.restore();
    };

    const renderBackground = () => {
      ctx.save();
      // white
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
      // guide
      ctx.strokeStyle = "#E6E6E6";
      ctx.setLineDash([14, 14]);
      ctx.lineWidth = 3;
      // vertical guide
      ctx.beginPath();
      ctx.moveTo(center, 0);
      ctx.lineTo(center, height);
      ctx.stroke();
      // horizontal guide
      ctx.beginPath();
      ctx.moveTo(0, center);
      ctx.lineTo(width, center);
      ctx.stroke();
      // diagonal left guide
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(width, height);
      ctx.stroke();
      // diagonal right guide
      ctx.beginPath();
      ctx.moveTo(width, 0);
      ctx.lineTo(0, height);
      ctx.stroke();
      // center gap
      const gapSize = 32;
      ctx.fillStyle = "#fff";
      ctx.fillRect(
        center - gapSize / 2,
        center - gapSize / 2,
        gapSize,
        gapSize,
      );
    };

    const renderGuide = () => {
      ctx.save();
      scale(1.2);
      strokes.forEach((stroke) => {
        stroke.renderGuide(ctx);
      });
      ctx.restore();
    };

    if (paint) {
      renderBackground();
      renderGuide();
    } else {
      engine.run();
    }
  };

  return {
    run,
  };
};

export default useStrokesAnimation;
