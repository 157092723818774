import * as d3 from "d3-interpolate";

const GRADIENTS = [
  ["rgb(97,186,255)", "rgb(154,243,255)"],
  ["rgb(64,193,182)", "rgb(154,255,161)"],
  ["rgb(181,97,255)", "rgb(255,154,194)"],
  ["rgb(164,179,195)", "rgb(203,241,246)"],
  ["rgb(255,188,97)", "rgb(255,238,154)"],
  ["rgb(255,140,107)", "rgb(255,184,114)"],
];

const levelTable = ["一", "二", "三", "四", "五", "六"];

const PADDING = 32;
const HANZI_VIEWPORT_SIZE = 1024;

interface State {
  karaoke: number;
  karaokeActiveColor: string;
}

const roundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
};

class Engine {
  startTime?: number;
  frameHandle?: number;
  duration: number;
  state: State;
  renderFrame!: (state: State) => void;

  constructor(duration: number) {
    this.duration = duration;
    this.state = {
      karaoke: 0,
      karaokeActiveColor: "rgb(0,0,0)",
    };
  }

  run() {
    this.startTime = performance.now();
    this.frameHandle = requestAnimationFrame(this.tick);
  }

  tick = (timing: number) => {
    if (timing >= this.startTime! + this.duration) return;
    const karaokeProgress = this.getProgress(
      timing,
      this.duration - 3 * 1000,
      1000,
    );
    this.state.karaoke = karaokeProgress;
    this.renderFrame(this.state);
    const karaokeColorProgress = this.getProgress(
      timing,
      500,
      this.duration - 2000,
    );
    this.state.karaokeActiveColor =
      karaokeColorProgress < 0.5
        ? d3.interpolateRgb(
            "black",
            "rgb(46,177,236)",
          )(karaokeColorProgress * 2)
        : d3.interpolateRgb(
            "rgb(46,177,236)",
            "white",
          )(karaokeColorProgress * 2 - 0.5);
    this.frameHandle = requestAnimationFrame(this.tick);
  };

  getProgress(timing: number, duration: number, delay = 0) {
    const elapsed = timing - this.startTime!;
    if (elapsed < delay) {
      return 0;
    }
    return Math.min(1, (elapsed - delay) / duration);
  }
}

export const useVocabAnimation = () => {
  const run = (
    canvas: HTMLCanvasElement,
    vocab: any,
    duration: number,
    paint: boolean,
  ) => {
    const ctx = canvas.getContext("2d")!;
    const {width, height} = canvas || 0;
    const center = width / 2;
    const {lists, hanzi, pinyin, english, strokes} = vocab;
    const hsk = lists.hsk[0];
    const hanzis = hanzi.split("");
    const pinyins = pinyin.split(" ");
    const chars: {
      hanzi: string;
      pinyin: string;
      strokes: string[];
    }[] = hanzis.reduce((acc: any[], hanzi: string, i: number) => {
      const char = {hanzi, pinyin: pinyins[i], strokes: strokes[i].strokes};
      return [...acc, char];
    }, []);

    const engine = new Engine(duration);
    engine.renderFrame = (state) => {
      renderBackground();
      renderBadge();
      renderDefinition();
      renderChars(state);
    };

    const renderBackground = () => {
      // white background
      ctx.save();
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, width, height);
      // gradient background
      const gradient = ctx.createLinearGradient(0.5, 0, width / 2, height);
      gradient.addColorStop(0, GRADIENTS[hsk - 1][0]);
      gradient.addColorStop(1, GRADIENTS[hsk - 1][1]);
      ctx.fillStyle = gradient;
      ctx.fillRect(PADDING, PADDING, width - PADDING * 2, height - PADDING * 2);
      ctx.restore();
    };

    const renderBadge = () => {
      ctx.save();
      //badge
      ctx.shadowColor = "rgba(0,0,0, 0.05)";
      ctx.shadowBlur = 20;
      ctx.shadowOffsetY = 2;
      const radius = 24;
      const badgeWidth = 110;
      const badgeHeight = 80;
      const x = width / 2 - badgeWidth / 2;
      const y = 16;
      const gradient = ctx.createLinearGradient(
        x,
        y,
        x + badgeWidth,
        badgeHeight,
      );
      gradient.addColorStop(0, GRADIENTS[hsk - 1][0]);
      gradient.addColorStop(1, GRADIENTS[hsk - 1][1]);
      ctx.fillStyle = gradient;
      roundRect(ctx, x, y, badgeWidth, badgeHeight, radius);
      // label
      ctx.font = `normal 500 64px AdobeKaitiStd-Regular`;
      ctx.fillStyle = "#fff";
      ctx.textAlign = "center";
      ctx.fillText(levelTable[hsk - 1], center, PADDING + 64 / 2 + 16);
      ctx.restore();
    };

    const renderDefinition = () => {
      ctx.save();
      // initial values
      let fontSize = 72;
      const maxWidth = width - 4 * PADDING;
      ctx.font = `normal 500 ${fontSize}px HiraMaruProN-W4`;
      ctx.fillStyle = "#363837";
      ctx.textAlign = "center";
      // split the string into lines that fit the max width
      let totalWidth = 0;
      const textLines = [""];
      english.split(" ").forEach((word: string) => {
        const wordPadded = word + " ";
        const wordWidth = Math.round(ctx.measureText(wordPadded).width);
        totalWidth += wordWidth;
        const currentLine = Math.floor(totalWidth / maxWidth);
        textLines[currentLine] =
          textLines[currentLine] !== undefined
            ? textLines[currentLine] + wordPadded
            : wordPadded;
      });

      // reajust font size if needed
      if (textLines.length > 1) {
        fontSize = 56;
        ctx.font = `normal 700 ${fontSize}px HiraMaruProN-W4`;
      }
      // text
      textLines.forEach((text, i) => {
        const distFromBottom = textLines.length * (fontSize + 16) + PADDING;
        const y = height - distFromBottom + i * (fontSize + 16);
        ctx.fillText(text, center, y);
      });
      ctx.restore();
    };

    const renderChars = (state: State) => {
      const maxWidth = width - 4 * PADDING;
      const charWidth = maxWidth / chars.length;
      // karaoke gradient
      const karaokeGradient = ctx.createLinearGradient(0, 0, height, 0);
      karaokeGradient.addColorStop(
        Math.min(state.karaoke, 1),
        state.karaokeActiveColor,
      );
      karaokeGradient.addColorStop(Math.min(state.karaoke + 0.05, 1), "#fff");
      // draw chars
      const pinyinPaddingY = chars.length === 1 ? 40 : 0;
      const y = 200 + (chars.length - 1) * 40;
      let fontSize = 72;
      chars.forEach((char, i) => {
        const leftMargin = PADDING * 2 + charWidth * i;
        const x = leftMargin + charWidth / 2;
        ctx.save();
        // draw pinyin
        ctx.font = `normal 600 ${fontSize}px HiraMaruProN-W4`;
        ctx.fillStyle = karaokeGradient;
        ctx.textAlign = "center";
        ctx.fillText(char.pinyin, x, y + pinyinPaddingY);
        // draw hanzi
        const hanziSize = Math.min(charWidth + 120, 800);
        const hanziScale = (1 * hanziSize) / HANZI_VIEWPORT_SIZE;
        const hanziX = x - hanziSize / 2;
        const hanziY = chars.length === 1 ? y - 40 : y;
        const p = new Path2D();
        const m = document
          .createElementNS("http://www.w3.org/2000/svg", "svg")
          .createSVGMatrix();
        const t = m.translate(hanziX, hanziY).scale(hanziScale);
        char.strokes.forEach((d) => {
          const path = new Path2D(d);
          p.addPath(path, t);
        });
        ctx.fillStyle = "#fff";
        ctx.shadowColor = "rgba(0,0,0, 0.2)";
        ctx.shadowBlur = 30;
        ctx.shadowOffsetY = 30;
        ctx.fill(p, "nonzero");
        ctx.restore();
      });
    };

    if (paint) {
      renderBackground();
      renderBadge();
      renderDefinition();
      renderChars({karaoke: 0, karaokeActiveColor: "rgb(0,0,0)"});
    } else {
      engine.run();
    }
  };

  return {
    run,
  };
};

export default useVocabAnimation;
