import {gql} from "@apollo/client";
import {startCase} from "lodash";
import {useMemo} from "react";

import {useGetPageInfoQuery} from "queries";

const usePageInfoData = () => {
  const {data} = useGetPageInfoQuery();

  const formattedData = useMemo(() => {
    if (!data)
      return {
        currentUser: null,
        lists: [] as {
          objectId: string;
          name: string;
          list: string;
          sublist: string;
        }[],
      };

    const currentUser = {
      objectId: data.viewer.user.objectId,
      role: data.viewer.user.role?.name || "guest",
    };

    const lists =
      data?.lists?.edges?.map(({node}: any) => ({
        objectId: node.objectId as string,
        name: node.name as string,
        list: startCase(node.list) as string,
        sublist: startCase(node.sublist) as string,
      })) || [];

    return {
      currentUser,
      lists: lists,
    };
  }, [data]);

  return formattedData;
};

export const GET_PAGE_INFO = gql`
  query getPageInfo {
    viewer {
      user {
        objectId
        role {
          name
        }
      }
    }
    lists {
      edges {
        node {
          objectId
          name
          list
          sublist
        }
      }
    }
  }
`;

export default usePageInfoData;
