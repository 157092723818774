import React, {FC, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";

import styles from "./index.module.scss";
import Logo from "components/Logo";
import {RootState} from "state";
import {login, resetAuthForm} from "state/auth";
import {emailRegex} from "helpers/validateEmail";
import {Button} from "components/Button";
import TextInput from "./TextInput";

type FormInputs = {
  email: string;
  password: string;
};

const Login: FC = () => {
  const dispatch = useDispatch();
  const {register, handleSubmit, errors} = useForm<FormInputs>();
  const {error: loginError, loading} = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    return () => {
      dispatch(resetAuthForm());
    };
  }, [dispatch]);

  const onSubmitHandler = ({email, password}: FormInputs) => {
    dispatch(login({email, password}));
  };

  return (
    <div className={styles.container}>
      <Logo />
      <form onSubmit={handleSubmit(onSubmitHandler)} className={styles.form}>
        <TextInput
          name="email"
          placeholder="Email"
          type="email"
          className={styles.field}
          error={errors.email?.message}
          ref={register({
            required: "Email is missing.",
            pattern: {value: emailRegex, message: "Invalid email."},
          })}
        />
        <TextInput
          name="password"
          placeholder="Password"
          type="password"
          className={styles.field}
          error={errors.password?.message}
          ref={register({required: "Password missing."})}
        />
        <Button dark loading={loading} error={loginError} text="Login" />
        {loginError && <span className={styles.errorLabel}>{loginError}</span>}
      </form>
    </div>
  );
};

export default Login;
