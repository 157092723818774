import React, {FC} from "react";
import {formatDistanceToNow} from "date-fns";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";

interface Props {
  title: string;
  date: Date;
  avatar?: string;
  className?: string;
}

const UserMeta: FC<Props> = ({className, title, date, avatar}) => {
  return (
    <div className={concatStyles([styles.container, className])}>
      <img alt="avatar" className={styles.avatar} src={avatar} />
      <div className={styles.meta}>
        <span className={styles.title}>{title}</span>
        <span className={styles.date}>{formatDistanceToNow(date)}</span>
      </div>
    </div>
  );
};

export default UserMeta;
