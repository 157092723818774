import React, {FC} from "react";

import styles from "./index.module.scss";
import {Columns} from "components/Browser";
import concatStyles from "helpers/concatStyles";
import Skeleton from "components/Skeleton";

interface Props {
  columns: Columns;
  style: any;
}

const BrowserRow: FC<Props> = ({columns, style}) => {
  return (
    <div style={style} className={concatStyles([styles.container])}>
      {columns.map((column) => (
        <div key={column.key} style={{width: `${column.width * 100}%`}}>
          <Skeleton height={24} className={styles.skeleton} />
        </div>
      ))}
    </div>
  );
};

export default BrowserRow;
