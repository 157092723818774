import React, {FC} from "react";
import Parse from "parse";

import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "state";
import {logout} from "state/auth";

export const Routes: FC = () => {
  const dispatch = useDispatch();
  const currentUser = Parse.User.current();
  const {isLogged} = useSelector((state: RootState) => state.auth);

  if (isLogged) {
    if (currentUser) {
      return <PrivateRoutes />;
    } else {
      dispatch(logout());
    }
  }

  return <PublicRoutes />;
};

export default Routes;
