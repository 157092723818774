import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import Icon from "components/Icon";
import colors from "styles/Color";

interface Props {
  className?: string;
  status: "pending" | "approved" | "rejected";
  onClick: (approved: boolean) => void;
}

const Approval: FC<Props> = ({className, status, onClick}) => {
  return (
    <div className={concatStyles([styles.container, className])}>
      <div
        onClick={() => onClick(true)}
        style={{
          opacity: status === "approved" || status === "pending" ? 1 : 0.6,
        }}
        className={concatStyles([styles.cell, styles.left])}>
        <Icon
          name="checkmark"
          size={22}
          fill={
            status === "approved" || status === "pending"
              ? colors.success
              : colors.mediumGrey
          }
        />
      </div>
      <div
        onClick={() => onClick(false)}
        style={{
          opacity: status === "rejected" || status === "pending" ? 1 : 0.6,
        }}
        className={concatStyles([styles.cell, styles.right])}>
        <Icon
          name="wrong"
          size={16}
          fill={
            status === "rejected" || status === "pending"
              ? colors.error
              : colors.mediumGrey
          }
        />
      </div>
    </div>
  );
};

export default Approval;
