import {useEffect, useRef} from "react";

const FPS = 60;

export const useRecorder = (finishRecording: (event: BlobEvent) => void) => {
  const recorder = useRef<MediaRecorder>();

  useEffect(() => {
    return () => {
      if (recorder.current) {
        recorder.current.removeEventListener("dataavailable", finishRecording);
      }
    };
  }, []);

  const init = (canvas: HTMLCanvasElement, audio: any) => {
    const audioCtx = new AudioContext();
    const dest = audioCtx.createMediaStreamDestination();
    const sourceNode = audioCtx.createMediaElementSource(audio);
    sourceNode.connect(dest);
    sourceNode.connect(audioCtx.destination);
    const audioTrack = dest.stream.getAudioTracks()[0];
    // @ts-ignore
    const stream = canvas.captureStream(FPS);
    stream.addTrack(audioTrack);
    recorder.current = new MediaRecorder(stream, {
      mimeType: "video/webm; codecs=h264",
    });
    recorder.current.addEventListener("dataavailable", finishRecording);
  };

  return {
    init,
    recorder,
  };
};

export default useRecorder;
