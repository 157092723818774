import Parse from "parse";

const exportSentences = async (
  exportSentencesJsonFile: (getData: () => Promise<any>) => Promise<void>,
) => {
  await exportSentencesJsonFile(async () => {
    const query = new Parse.Query("Sentence").limit(999999);
    const results = await query.find();

    const data = results.map((result) => {
      const id = result.id;
      const english = result.get("english");
      const audio = result.get("audio");

      const words = result.get("words").map((word: any) => ({
        hanzi: word.hanzi,
        pinyin: word.pinyins.join(" "),
        vocab: word.vocabulary,
      }));

      return {
        id,
        english,
        audio,
        words,
      };
    });

    return data;
  });
};

export default exportSentences;
