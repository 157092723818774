export const hashtags = [
  "#中文",
  "#chinesecharacters",
  "#learnchinese",
  "#learnchinesecharacters",
  "#hskwords",
  "#hskwordoftheday",
  "#chinese",
  "#studychinese",
  "#汉语",
  "#chinesepronunciation",
  "#studyingmandarin",
  "#学中文",
  "#chineselanguage",
  "#learnmandarin",
  "#汉字",
  "#chinesewords",
  "#pinyin",
  "#学习中文",
  "#chinesevocabulary",
  "#learningmandarin",
  "#汉语学习",
  "#mandarinlearning",
  "#dailychinese",
  "#chinesestudygram",
  "#chineseexam",
  "#汉语水平",
];

export const baseHashtags = ["#hanpath", "#hsk"];
