// prettier-ignore

export const captionTemplate = 
`📚  ‎Today's word is {{vocab.hanzi}} {{vocab.pinyin}}. Swipe to see how to write it 👉

HSK: {{hsk}}
Hanzi: {{vocab.hanzi}}
Pinyin: {{vocab.pinyin}}
Definition: 
{{#definitions}}
⠀  •【{{speechPart}}】{{definition}}
{{/definitions}}

Try to make a sentence with {{vocab.hanzi}} in the comments 💬  We will help you to correct it ✅ 

{{#sentence}}
📖   ‎Example sentence:
{{sentence.pinyin}}
{{sentence.hanzi}}
{{sentence.english}}

{{/sentence}}
👍   ‎Like & Follow us ❤️  to learn a new word everyday

{{hashtags}}
`;
