interface Options {
  particleCount?: number;
  angle?: number;
  startVelocity?: number;
  spread?: number;
  decay?: number;
  gravity?: number;
  colors?: {r: number; g: number; b: number}[];
  ticks?: number;
  scalar?: number;
}

export default class Confetti {
  fettis: any[] = [];

  constructor(canvas: HTMLCanvasElement, opts: Options) {
    const particleCount = opts.particleCount || 50;
    const angle = opts.angle || 90;
    const startVelocity = opts.startVelocity || 45;
    const spread = opts.spread || 45;
    const decay = opts.decay || 0.9;
    const gravity = opts.gravity || 1;
    const colors = opts.colors || [
      {r: 21, g: 158, b: 221},
      {r: 218, g: 67, b: 79},
      {r: 255, g: 164, b: 111},
    ];
    const ticks = opts.ticks || 200;
    const scalar = opts.scalar || 1;

    let temp = particleCount;

    var startX = canvas.width * 0.5;
    var startY = canvas.height * 0.5;

    while (temp--) {
      this.fettis.push(
        this.randomPhysics({
          x: startX,
          y: startY,
          angle: angle,
          spread: spread,
          startVelocity: startVelocity,
          color: colors[temp % colors.length],
          ticks: ticks,
          decay: decay,
          gravity: gravity,
          scalar: scalar,
        }),
      );
    }
  }

  randomPhysics(opts: any) {
    var radAngle = opts.angle * (Math.PI / 180);
    var radSpread = opts.spread * (Math.PI / 180);

    return {
      x: opts.x,
      y: opts.y,
      wobble: Math.random() * 10,
      velocity: opts.startVelocity * 0.5 + Math.random() * opts.startVelocity,
      angle2D: -radAngle + (0.5 * radSpread - Math.random() * radSpread),
      tiltAngle: Math.random() * Math.PI,
      color: opts.color,
      shape: opts.shape,
      tick: 0,
      totalTicks: opts.ticks,
      decay: opts.decay,
      random: Math.random() + 5,
      tiltSin: 0,
      tiltCos: 0,
      wobbleX: 0,
      wobbleY: 0,
      gravity: opts.gravity * 3,
      ovalScalar: 0.6,
      scalar: opts.scalar,
    };
  }

  render(context: CanvasRenderingContext2D) {
    const animatingFettis = this.fettis.slice();
    animatingFettis.forEach((fetti) => this.renderFetti(context, fetti));
  }

  renderFetti(context: CanvasRenderingContext2D, fetti: any) {
    const ease = (x: number) => -Math.cos(x * Math.PI) / 2 + 0.5;

    fetti.x += Math.cos(fetti.angle2D) * fetti.velocity;
    fetti.y += Math.sin(fetti.angle2D) * fetti.velocity + fetti.gravity;
    fetti.wobble += 0.1;
    fetti.velocity *= fetti.decay;
    fetti.tiltAngle += 0.1;
    fetti.tiltSin = Math.sin(fetti.tiltAngle);
    fetti.tiltCos = Math.cos(fetti.tiltAngle);
    fetti.random = Math.random() + 5;
    fetti.wobbleX = fetti.x + 10 * fetti.scalar * Math.cos(fetti.wobble);
    fetti.wobbleY = fetti.y + 10 * fetti.scalar * Math.sin(fetti.wobble);

    const progress = Math.min(fetti.tick++ / fetti.totalTicks, 1);

    const x1 = fetti.x + fetti.random * fetti.tiltCos;
    const y1 = fetti.y + fetti.random * fetti.tiltSin;
    const x2 = fetti.wobbleX + fetti.random * fetti.tiltCos;
    const y2 = fetti.wobbleY + fetti.random * fetti.tiltSin;

    context.fillStyle =
      "rgba(" +
      fetti.color.r +
      ", " +
      fetti.color.g +
      ", " +
      fetti.color.b +
      ", " +
      (1 - ease(progress)) +
      ")";
    context.beginPath();

    context.moveTo(Math.floor(fetti.x), Math.floor(fetti.y));
    context.lineTo(Math.floor(fetti.wobbleX), Math.floor(y1));
    context.lineTo(Math.floor(x2), Math.floor(y2));
    context.lineTo(Math.floor(x1), Math.floor(fetti.wobbleY));

    context.closePath();
    context.fill();

    return fetti.tick < fetti.totalTicks;
  }
}
