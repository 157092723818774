import React, {FC, useEffect, useRef, useState} from "react";
import {useTimer} from "use-timer";
import {useHistory} from "react-router-dom";

import {RootState, useAppDispatch} from "state";
import styles from "./index.module.scss";
import Scene from "./Scene";
import Start from "./Start";
import useRecorder from "./useRecorder";
import useStrokesAnimation from "./useStrokesAnimation";
import useVocabAnimation from "./useVocabAnimation";
import Spinner from "components/Spinner";
import {useSelector} from "react-redux";
import {addVideoUrl, resetVideoUrls} from "state/instagram";

export type Hanzi = {
  strokes: string[];
  medians: [number, number][][];
};

export type ListItem = {
  type: "hanzi" | "vocab";
  progress: number;
  char: string;
};

const AUDIO_PADDING = 1000;

const VideoCreation: FC = () => {
  const dispatch = useAppDispatch();
  const canvas = useRef<any>();
  const vocabAudio = useRef<any>();
  const {run: runVocabAnimation} = useVocabAnimation();
  const {run: runStrokesAnimation} = useStrokesAnimation();
  const [userReady, setUserReady] = useState(false);
  const [vocabAudioReady, setVocabAudioReady] = useState(false);
  const [list, setList] = useState<ListItem[]>([]);
  const [current, setCurrent] = useState(0);
  const [duration, setDuration] = useState(0);
  const history = useHistory();
  const vocab = useSelector((state: RootState) => state.instagram.vocab);
  const {
    time,
    start: startTimer,
    pause: pauseTimer,
    reset: resetTimer,
  } = useTimer({
    interval: 100,
    step: 100,
  });

  const finishRecording = (event: BlobEvent) => {
    const videoData = [event.data];
    const blob = new Blob(videoData, {type: "video/webm; codecs=h264"});
    const videoURL = URL.createObjectURL(blob);
    dispatch(addVideoUrl(videoURL));
  };

  const {init: initRecorder, recorder} = useRecorder(finishRecording);

  useEffect(() => {
    dispatch(resetVideoUrls());
  }, []);

  useEffect(() => {
    if (vocab) {
      const firstItem: ListItem = {
        type: "vocab",
        progress: 0,
        char: vocab.hanzi,
      };
      const strokes: ListItem[] = vocab.hanzi
        .split("")
        .map((char: string) => ({type: "hanzi", progress: 0, char}));
      setList([firstItem, ...strokes]);
    }
  }, [vocab]);

  useEffect(() => {
    if (list.length > 0) {
      const progress = Math.min(time / duration, 1);
      updateProgress(progress);
      if (progress === 1) {
        pauseTimer();
        recorder!.current!.stop();
        if (current < list.length - 1) {
          resetTimer();
          setCurrent(current + 1);
          recordStrokes();
        } else {
          finish();
        }
      }
    }
  }, [time]);

  const finish = () => {
    history.push(`/instagram/post`);
  };

  const updateProgress = (progress: number) => {
    const _list = [...list];
    _list[current].progress = progress;
    setList(_list);
  };

  const onStartPressHandler = () => {
    setUserReady(true);
    initRecorder(canvas.current, vocabAudio.current);
    recordVocab();
  };

  const recordVocab = () => {
    const audioDuration = vocabAudio.current.duration * 1000;
    const duration = audioDuration + 3 * AUDIO_PADDING;
    setDuration(audioDuration + 3 * AUDIO_PADDING);
    runVocabAnimation(canvas.current, vocab, 1, true);

    startTimer();
    runVocabAnimation(canvas.current, vocab, duration, true);
    runVocabAnimation(canvas.current, vocab, duration, false);
    recorder!.current!.start();

    setTimeout(() => {
      vocabAudio.current.play();
    }, AUDIO_PADDING);
  };

  const recordStrokes = () => {
    const hanzi = vocab!.strokes[current];
    const duration = hanzi.strokes.length * 1000 + 3000;
    setDuration(duration);
    startTimer();
    runStrokesAnimation(canvas.current, hanzi, true);
    runStrokesAnimation(canvas.current, hanzi, false);
    recorder!.current!.start();
  };

  return (
    <div className={styles.container}>
      {!vocab || (!vocabAudioReady && <Spinner size={124} />)}
      {vocab && !userReady && vocabAudioReady && (
        <Start onStartPress={onStartPressHandler} list={list} />
      )}
      <Scene hide={!userReady} list={list} ref={canvas} />
      {vocab && (
        <audio
          ref={vocabAudio}
          preload="auto"
          crossOrigin="anonymous"
          onLoadedData={() => setVocabAudioReady(true)}
          src={vocab.audio}
        />
      )}
    </div>
  );
};

export default VideoCreation;
