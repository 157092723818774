import React, {FC, useEffect} from "react";
import Parse from "parse";
import {v4 as uuid} from "uuid";

import styles from "./index.module.scss";
import {Controller, useFieldArray, useForm, useWatch} from "react-hook-form";
import {TextInput} from "components/Input";
import BrowserPanelHeader from "components/Browser/BrowserPanelHeader";
import FormRow from "components/FormRow";
import ActionButton from "components/Button/ActionButton";
import SelectInput from "components/Input/SelectInput";
import speechParts from "helpers/speechParts";
import pinyins from "helpers/pinyin";
import getKeyValue from "helpers/getKeyValue";
import {sortBy} from "lodash";
import VocabularyPreview from "./VocabularyPreview";
import {ActionMeta} from "react-select";
import concatStyles from "helpers/concatStyles";
import UploadInput from "components/Input/UploadInput";
import {UserPresence} from "state/presence";

export type FormInputs = {
  pinyin: {
    value: string;
    val: string;
    label: string;
  }[];
  english: {
    speechPart: {value: string; label: string};
    definition: string;
  }[];
  lists: {
    value: string;
    label: string;
  }[];
  hanzis: {
    value: string;
    val: string;
    label: string;
  }[];
  audio: string;
};

interface Props {
  data: any;
  lists: {
    objectId: string;
    name: string;
    list: string;
    sublist: string;
  }[];
  role: string | null;
  loading?: boolean;
  isCreateMode?: boolean;
  presences: UserPresence[];
  onUpdate: (form: FormInputs) => void;
  onSave: (form: FormInputs) => void;
  onApproval: (approved: boolean) => void;
  onUploadAudio: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Panel: FC<Props> = ({
  data,
  role,
  lists,
  loading = false,
  isCreateMode = false,
  presences,
  onSave,
  onUpdate,
  onApproval,
  onUploadAudio,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState,
  } = useForm<FormInputs>();

  const {fields, append, remove} = useFieldArray({
    control,
    name: "english",
  });

  const formData = useWatch({
    control,
  });

  const decodeDefinition = (str: string, speechParts: string[]) => {
    if (!str || !speechParts) return [];

    const parts = str.split("; ").map((x) => x.split(", "));

    const formattedParts = parts
      .map((part, i) => {
        const speechPart = speechParts[i];
        return part.map((definition) => ({
          speechPart: {value: speechPart, label: speechPart},
          definition,
        }));
      })
      .flat();

    return sortBy(formattedParts, (item) => item?.speechPart?.value || "", [
      "asc",
    ]);
  };

  useEffect(() => {
    reset({
      pinyin:
        data?.pinyin?.split(" ").map((value: string) => ({
          value: uuid(),
          val: value,
          label: value,
        })) || [],
      english: (data && decodeDefinition(data.english, data.speechPart)) || [],
      lists:
        data?.lists?.map((list: any) => ({
          value: list.objectId,
          label: list.name,
        })) || [],
      hanzis:
        data?.hanzis?.map((hanzi: any) => ({
          value: uuid(),
          val: hanzi.objectId,
          label: hanzi.character,
          strokes: hanzi.strokes,
        })) || [],
      audio:
        data?.audio && data?.audio !== ""
          ? data.audio + `?v=${+new Date()}`
          : "",
    });
  }, [data, reset]);

  const onSubmitHandler = (form: FormInputs) => {
    if (isCreateMode) {
      onSave(form);
    } else {
      onUpdate(form);
    }
  };

  const englishOptions = Object.keys(speechParts).map((value) => ({
    value,
    label: value,
  }));

  const onLoadOptionsHandler = async (value: string, callback: any) => {
    if (!value || value === "") return callback(undefined);

    const query = new Parse.Query("Hanzi").equalTo("character", value);
    const hanzi = await query.first();

    const option = hanzi
      ? [
          {
            value: uuid(),
            val: hanzi.id,
            label: hanzi.get("character"),
            strokes: hanzi.get("strokes"),
          },
        ]
      : undefined;

    return callback(option);
  };

  const onLoadPinyinOptionsHandler = async (value: string, callback: any) => {
    const pinyin = (getKeyValue(pinyins, value as any) as string[]) || [];
    callback(
      pinyin.map((item: string) => ({value: uuid(), val: item, label: item})),
    );
  };

  const onMultiSelectChange = (onChange: any, value: any) => (
    _: any,
    option: ActionMeta<any>,
  ) => {
    if (option.action === "select-option") {
      const options = [...value, {...option.option, value: uuid()}];
      onChange(options);
    } else if (
      option.action === "remove-value" ||
      option.action === "pop-value"
    ) {
      const options = value.filter(
        (opt: any) => opt.value !== option.removedValue.value,
      );
      onChange(options);
    }
  };

  const activePresences =
    (data && presences.filter((presence) => presence.item === data.objectId)) ||
    [];

  if (!data && !isCreateMode) {
    return <div className={styles.container}></div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <BrowserPanelHeader
        data={data}
        role={role}
        presences={activePresences}
        onApproval={onApproval}
        loading={loading}
        buttonDisabled={formState.isDirty}
        isCreateMode={isCreateMode}
      />
      <VocabularyPreview data={formData} className={styles.preview} />
      <FormRow label="Hanzi">
        <Controller
          defaultValue={""}
          control={control}
          name="hanzis"
          render={({onChange, value}) => (
            <SelectInput
              async
              placeholder="Type..."
              hideSelector
              isMulti
              hideSelectedOptions={false}
              value={value}
              loadOptions={onLoadOptionsHandler}
              onChange={onMultiSelectChange(onChange, value)}
              onSort={onChange}
            />
          )}
        />
      </FormRow>
      <FormRow label="Pinyin">
        <Controller
          defaultValue={""}
          control={control}
          name="pinyin"
          render={({onChange, value}) => (
            <SelectInput
              async
              isMulti
              placeholder="Type..."
              hideSelector
              value={value}
              hideSelectedOptions={false}
              loadOptions={onLoadPinyinOptionsHandler}
              onChange={onMultiSelectChange(onChange, value)}
              onSort={onChange}
            />
          )}
        />
      </FormRow>
      <FormRow label="Audio">
        <Controller
          defaultValue={""}
          control={control}
          name="audio"
          render={({onChange, value}) => (
            <div className={styles.audioRow}>
              <TextInput
                className={concatStyles([styles.input, styles.audioInput])}
                name="audio"
                placeholder="Audio"
                value={value}
                onChange={onChange}
              />
              <UploadInput
                icon="audioFile"
                onChange={(event) => {
                  onChange(URL.createObjectURL(event.target!.files![0]));
                  onUploadAudio(event);
                }}
              />
            </div>
          )}
        />
      </FormRow>
      <FormRow label="Lists">
        <Controller
          defaultValue={""}
          control={control}
          name="lists"
          render={({onChange, value}) => (
            <SelectInput
              value={value}
              isMulti
              options={lists.map((list) => ({
                value: list.objectId,
                label: list.name,
              }))}
              onChange={onChange}
              onSort={onChange}
            />
          )}
        />
      </FormRow>
      <FormRow label="English">
        {fields.map((field, i) => (
          <div key={field.id} className={styles.englishRow}>
            <Controller
              defaultValue={field.speechPart || ""}
              control={control}
              name={`english[${i}].speechPart`}
              render={({onChange, value}) => (
                <SelectInput
                  value={value}
                  className={styles.select}
                  options={englishOptions}
                  onChange={onChange}
                />
              )}
            />
            <TextInput
              className={styles.inputRow}
              name={`english[${i}].definition`}
              placeholder="Definition"
              defaultValue={field.definition || ""}
              ref={register({})}
            />
            <ActionButton onClick={() => remove(i)} action="remove" />
          </div>
        ))}
        <div className={styles.addRow}>
          <ActionButton
            onClick={() =>
              append({definition: "", speechPart: {value: "-", label: "-"}})
            }
            action="add"
          />
        </div>
      </FormRow>
    </form>
  );
};

export default Panel;
