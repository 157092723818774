import {useEffect, useRef} from "react";
import Parse from "parse";
import {useAppDispatch} from "state";
import {goOnline, removePresence, updatePresence} from "state/presence";

const usePresence = () => {
  const dispatch = useAppDispatch();
  const subscription = useRef<any>(null);

  const subscribe = async () => {
    const currentUser = await Parse.User.currentAsync();

    const query = new Parse.Query("Presence");
    subscription.current = await query.subscribe();

    subscription.current.on("update", async (data: any) => {
      if (data.get("user").id !== currentUser!.id) {
        const user = await data.get("user").fetch();
        const avatar = (user.get("avatar") && user.get("avatar")._url) || "";
        const page = data.get("page");
        const item = data.get("item");

        if (data.get("online")) {
          dispatch(updatePresence({id: user.id, avatar, page, item}));
        } else {
          dispatch(removePresence(user.id));
        }
      }
    });
  };

  useEffect(() => {
    dispatch(goOnline());
    subscribe();

    return () => {
      subscription.current.unsubscribe();
      // @ts-ignore
      Parse.LiveQuery.close();
    };
  }, []);
};

export default usePresence;
