import React, {FC} from "react";

import styles from "./index.module.scss";
import Layout from "components/Layout";

const Home: FC = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <h1 className={styles.title}>Home</h1>
      </div>
    </Layout>
  );
};

export default Home;
