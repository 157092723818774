import {differenceBy} from "lodash";
import {useMemo, useState} from "react";
import {v4 as uuid} from "uuid";

export type FilterOption = {
  value: string;
  label: string;
  query: any;
  values: {value: any; label: string}[];
};

type FilterData = {
  data: {[id: string]: {value: any; label: string}};
  ids: {id: string; value: string | null}[];
};

const useFilter = (options: FilterOption[]) => {
  const [filterData, setFilterData] = useState<FilterData>({data: {}, ids: []});

  const addFilter = () => {
    if (filterData.ids.length >= options.length) return;

    setFilterData({
      ...filterData,
      ids: [...filterData.ids, {id: uuid(), value: null}],
    });
  };

  const removeFilter = (index: number) => {
    const data = {...filterData.data};
    const id = filterData.ids[index].value;
    id && delete data[id];
    const ids = filterData.ids.filter((_, i) => i !== index);

    setFilterData({
      data,
      ids,
    });
  };

  const updateFilter = (
    field: string,
    index: number,
    event: {value: any; label: string},
  ) => {
    const {value} = event;
    const id = filterData.ids[index].value!;

    if (field === "field") {
      const ids = [...filterData.ids];
      const data = {
        ...filterData.data,
      };
      delete data[id];
      ids[index] = {...ids[index], value};

      setFilterData({
        data,
        ids,
      });
    } else {
      setFilterData({
        ...filterData,
        data: {
          ...filterData.data,
          [id]: event,
        },
      });
    }
  };

  const selectOptions = useMemo(() => {
    const remainingOptions = differenceBy(options, filterData.ids, "value");

    const values = options.reduce((acc, option) => {
      acc[option.value] = option.values;
      return acc;
    }, {} as {[id: string]: {value: any; label: string}[]});

    const fields = filterData.ids.map(() => {
      return remainingOptions.map((option) => {
        return {value: option.value, label: option.label};
      });
    });

    return {fields, values};
  }, [filterData, options]);

  return {filterData, selectOptions, addFilter, removeFilter, updateFilter};
};

export type Filter = ReturnType<typeof useFilter>;

export default useFilter;
