import React, {FC} from "react";
import {Provider as ReduxProvider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {ApolloProvider} from "@apollo/client";
import {ToastProvider} from "react-toast-notifications";

import configureStore from "state";
import Routes from "routes";
import useGraphqlClient from "hooks/useGraphqlClient";
import Toast from "components/Toast";

const Parse = require("parse");

Parse.initialize(
  process.env.REACT_APP_PARSE_APP_ID!,
  process.env.REACT_APP_PARSE_JS_KEY!,
);
Parse.serverURL = process.env.REACT_APP_PARSE_URL!;

export const App: FC = () => {
  const apolloClient = useGraphqlClient();
  const {store, persistor} = configureStore;

  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <ToastProvider autoDismiss placement="bottom-left" components={{Toast}}>
          <ApolloProvider client={apolloClient}>
            <Routes />
          </ApolloProvider>
        </ToastProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

export default App;
