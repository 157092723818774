import {FC} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Login from "features/Login";
import NotFound from "features/NotFound";

export const PublicRoutes: FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Redirect exact from="/" to="/login" />
        <Redirect exact from="/vocabulary" to="/login" />
        <Redirect exact from="/sentences" to="/login" />
        <Redirect exact from="/hanzi" to="/login" />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default PublicRoutes;
