import React, {FC} from "react";
import AsyncSelect from "react-select/async";
import {SortableContainer, SortableElement} from "react-sortable-hoc";

import colors from "styles/Color";
import Select, {
  ActionMeta,
  components,
  GroupedOptionsType,
  InputActionMeta,
  OptionsType,
  OptionTypeBase,
  Styles,
} from "react-select";

interface Props {
  value?: any;
  disabled?: boolean;
  async?: boolean;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  hideSelector?: boolean;
  defaultOptions?:
    | boolean
    | OptionsType<OptionTypeBase>
    | GroupedOptionsType<OptionTypeBase>;
  isMulti?: boolean;
  hideSelectedOptions?: boolean;
  options?: GroupedOptionsType<any> | OptionsType<any>;
  onSort?: (value: any) => void;
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsType<any>) => void,
  ) => void | Promise<any>;
  onChange?: (value: any, action: ActionMeta<any>) => void;
  onInputChange?:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
  onTagClick?: (option: OptionsType<any>) => void;
}

const arrayMove = (array: any[], from: number, to: number) => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};

const SortableAsyncSelect = SortableContainer(AsyncSelect);
const SortableSelect = SortableContainer(Select);

const SelectInput: FC<Props> = ({
  value,
  disabled = false,
  options,
  className,
  async = false,
  placeholder,
  isMulti = false,
  isClearable = false,
  hideSelectedOptions = true,
  hideSelector = false,
  defaultOptions,
  loadOptions,
  onChange,
  onSort,
  onInputChange,
  onTagClick,
}) => {
  const SortableMultiValue = SortableElement((props: any) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <components.MultiValue
        {...props}
        innerProps={{
          ...props.innerProps,
          onMouseDown,
          onClick: (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            onTagClick && onTagClick(props.data);
          },
        }}
      />
    );
  });

  const selectStyles: Partial<Styles> = {
    control: (provided) => ({
      ...provided,
      borderRadius: 8,
      borderColor: colors.border,
      minHeight: 40,
      boxShadow: "none",
      "&:hover": {borderColor: "#B3B3B3"},
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: hideSelector ? "none" : "flex",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: hideSelector ? "none" : "flex",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? colors.primary : colors.white,
      "&:hover": {
        backgroundColor: state.isSelected ? colors.primary : "#F9F9F9",
      },
    }),
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const sortedValues = arrayMove(value, oldIndex, newIndex);
    if (onSort) {
      onSort(sortedValues);
    }
  };

  if (async) {
    return (
      <SortableAsyncSelect
        axis="xy"
        components={{
          // @ts-ignore
          MultiValue: SortableMultiValue,
        }}
        onSortEnd={onSortEnd}
        distance={4}
        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
        getHelperDimensions={({node}) => node.getBoundingClientRect()}
        className={className}
        placeholder={placeholder}
        isMulti={isMulti}
        cacheOptions
        defaultOptions={defaultOptions}
        styles={selectStyles}
        value={value}
        isClearable={isClearable}
        isDisabled={disabled}
        hideSelectedOptions={hideSelectedOptions}
        loadOptions={loadOptions!}
        onInputChange={onInputChange}
        onChange={onChange}
      />
    );
  }

  return (
    <SortableSelect
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      components={{
        // @ts-ignore
        MultiValue: SortableMultiValue,
      }}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({node}) => node.getBoundingClientRect()}
      className={className}
      placeholder={placeholder}
      isMulti={isMulti}
      defaultOptions={defaultOptions}
      styles={selectStyles}
      value={value}
      isClearable={isClearable}
      isDisabled={disabled}
      options={options}
      hideSelectedOptions={hideSelectedOptions}
      onChange={onChange}
    />
  );
};

export default SelectInput;
