import {useMemo} from "react";
import useFilter, {FilterOption} from "./useFilter";

const useQueryBuilder = (
  search: {value: string; query: any},
  options: FilterOption[],
) => {
  const filter = useFilter(options);

  const filterQueries = useMemo(() => {
    return options.reduce((acc, option) => {
      acc[option.value] = option.query;
      return acc;
    }, {} as any);
  }, [options]);

  const query: any = useMemo(() => {
    const queries = Object.entries(filter.filterData.data).reduce(
      (acc, [key, value]) => {
        return {...acc, ...filterQueries[key](value.value)};
      },
      search.value ? {...search.query(search.value)} : {},
    );

    if (queries.length === 0) {
      return {};
    }

    return {
      filter: {
        ...queries,
      },
    };
  }, [search, filter.filterData]);

  return {filter, query};
};

export default useQueryBuilder;
