import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  /** The Any scalar type is used in operations and types that involve any type of value. */
  Any: any;
  /** The Object scalar type is used in operations and types that involve objects. */
  Object: any;
  /** The Date scalar type is used in operations and types that involve dates. */
  Date: any;
  /** The Bytes scalar type is used in operations and types that involve base 64 binary data. */
  Bytes: any;
  /** The File scalar type is used in operations and types that involve files. */
  File: any;
};

/** The FileInfo object type is used to return the information about files. */
export type FileInfo = {
  __typename?: "FileInfo";
  /** This is the file name. */
  name: Scalars["String"];
  /** This is the url in which the file can be downloaded. */
  url: Scalars["String"];
};

export type FileInput = {
  /** A File Scalar can be an url or a FileInfo object. If this field is set to null the file will be unlinked. */
  file?: Maybe<Scalars["File"]>;
  /** Use this field if you want to create a new file. */
  upload?: Maybe<Scalars["Upload"]>;
  /** Use this field if you want to unlink the file (the file will not be deleted on cloud storage) */
  unlink?: Maybe<Scalars["Boolean"]>;
};

/** The GeoPointInput type is used in operations that involve inputting fields of type geo point. */
export type GeoPointInput = {
  /** This is the latitude. */
  latitude: Scalars["Float"];
  /** This is the longitude. */
  longitude: Scalars["Float"];
};

/** The GeoPoint object type is used to return the information about geo point fields. */
export type GeoPoint = {
  __typename?: "GeoPoint";
  /** This is the latitude. */
  latitude: Scalars["Float"];
  /** This is the longitude. */
  longitude: Scalars["Float"];
};

/** The ParseObject interface type is used as a base type for the auto generated object types. */
export type ParseObject = {
  /** This is the object id. */
  objectId: Scalars["ID"];
  /** This is the date in which the object was created. */
  createdAt: Scalars["Date"];
  /** This is the date in which the object was las updated. */
  updatedAt: Scalars["Date"];
  ACL: Acl;
};

/** The ReadPreference enum type is used in queries in order to select in which database replica the operation must run. */
export enum ReadPreference {
  Primary = "PRIMARY",
  PrimaryPreferred = "PRIMARY_PREFERRED",
  Secondary = "SECONDARY",
  SecondaryPreferred = "SECONDARY_PREFERRED",
  Nearest = "NEAREST",
}

/** The ReadOptionsInputt type is used in queries in order to set the read preferences. */
export type ReadOptionsInput = {
  /** The read preference for the main query to be executed. */
  readPreference?: Maybe<ReadPreference>;
  /** The read preference for the queries to be executed to include fields. */
  includeReadPreference?: Maybe<ReadPreference>;
  /** The read preference for the subqueries that may be required. */
  subqueryReadPreference?: Maybe<ReadPreference>;
};

/** The SearchInput type is used to specifiy a search operation on a full text search. */
export type SearchInput = {
  /** This is the term to be searched. */
  term: Scalars["String"];
  /** This is the language to tetermine the list of stop words and the rules for tokenizer. */
  language?: Maybe<Scalars["String"]>;
  /** This is the flag to enable or disable case sensitive search. */
  caseSensitive?: Maybe<Scalars["Boolean"]>;
  /** This is the flag to enable or disable diacritic sensitive search. */
  diacriticSensitive?: Maybe<Scalars["Boolean"]>;
};

/** The TextInput type is used to specify a text operation on a constraint. */
export type TextInput = {
  /** This is the search to be executed. */
  search: SearchInput;
};

/** The BoxInput type is used to specifiy a box operation on a within geo query. */
export type BoxInput = {
  /** This is the bottom left coordinates of the box. */
  bottomLeft: GeoPointInput;
  /** This is the upper right coordinates of the box. */
  upperRight: GeoPointInput;
};

/** The WithinInput type is used to specify a within operation on a constraint. */
export type WithinInput = {
  /** This is the box to be specified. */
  box: BoxInput;
};

/** The CenterSphereInput type is used to specifiy a centerSphere operation on a geoWithin query. */
export type CenterSphereInput = {
  /** This is the center of the sphere. */
  center: GeoPointInput;
  /** This is the radius of the sphere. */
  distance: Scalars["Float"];
};

/** The GeoWithinInput type is used to specify a geoWithin operation on a constraint. */
export type GeoWithinInput = {
  /** This is the polygon to be specified. */
  polygon?: Maybe<Array<GeoPointInput>>;
  /** This is the sphere to be specified. */
  centerSphere?: Maybe<CenterSphereInput>;
};

/** The GeoIntersectsInput type is used to specify a geoIntersects operation on a constraint. */
export type GeoIntersectsInput = {
  /** This is the point to be specified. */
  point?: Maybe<GeoPointInput>;
};

/** The IdWhereInput input type is used in operations that involve filtering objects by an id. */
export type IdWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<Scalars["ID"]>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<Scalars["ID"]>;
  /** This is the lessThan operator to specify a constraint to select the objects where the value of a field is less than a specified value. */
  lessThan?: Maybe<Scalars["ID"]>;
  /** This is the lessThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is less than or equal to a specified value. */
  lessThanOrEqualTo?: Maybe<Scalars["ID"]>;
  /** This is the greaterThan operator to specify a constraint to select the objects where the value of a field is greater than a specified value. */
  greaterThan?: Maybe<Scalars["ID"]>;
  /** This is the greaterThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is greater than or equal to a specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars["ID"]>;
  /** This is the in operator to specify a constraint to select the objects where the value of a field equals any value in the specified array. */
  in?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /** This is the notIn operator to specify a constraint to select the objects where the value of a field do not equal any value in the specified array. */
  notIn?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** The StringWhereInput input type is used in operations that involve filtering objects by a field of type String. */
export type StringWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<Scalars["String"]>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<Scalars["String"]>;
  /** This is the lessThan operator to specify a constraint to select the objects where the value of a field is less than a specified value. */
  lessThan?: Maybe<Scalars["String"]>;
  /** This is the lessThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is less than or equal to a specified value. */
  lessThanOrEqualTo?: Maybe<Scalars["String"]>;
  /** This is the greaterThan operator to specify a constraint to select the objects where the value of a field is greater than a specified value. */
  greaterThan?: Maybe<Scalars["String"]>;
  /** This is the greaterThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is greater than or equal to a specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars["String"]>;
  /** This is the in operator to specify a constraint to select the objects where the value of a field equals any value in the specified array. */
  in?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** This is the notIn operator to specify a constraint to select the objects where the value of a field do not equal any value in the specified array. */
  notIn?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the matchesRegex operator to specify a constraint to select the objects where the value of a field matches a specified regular expression. */
  matchesRegex?: Maybe<Scalars["String"]>;
  /** This is the options operator to specify optional flags (such as "i" and "m") to be added to a matchesRegex operation in the same set of constraints. */
  options?: Maybe<Scalars["String"]>;
  /** This is the $text operator to specify a full text search constraint. */
  text?: Maybe<TextInput>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** The NumberWhereInput input type is used in operations that involve filtering objects by a field of type Number. */
export type NumberWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<Scalars["Float"]>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<Scalars["Float"]>;
  /** This is the lessThan operator to specify a constraint to select the objects where the value of a field is less than a specified value. */
  lessThan?: Maybe<Scalars["Float"]>;
  /** This is the lessThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is less than or equal to a specified value. */
  lessThanOrEqualTo?: Maybe<Scalars["Float"]>;
  /** This is the greaterThan operator to specify a constraint to select the objects where the value of a field is greater than a specified value. */
  greaterThan?: Maybe<Scalars["Float"]>;
  /** This is the greaterThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is greater than or equal to a specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars["Float"]>;
  /** This is the in operator to specify a constraint to select the objects where the value of a field equals any value in the specified array. */
  in?: Maybe<Array<Maybe<Scalars["Float"]>>>;
  /** This is the notIn operator to specify a constraint to select the objects where the value of a field do not equal any value in the specified array. */
  notIn?: Maybe<Array<Maybe<Scalars["Float"]>>>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** The BooleanWhereInput input type is used in operations that involve filtering objects by a field of type Boolean. */
export type BooleanWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<Scalars["Boolean"]>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<Scalars["Boolean"]>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** The ArrayWhereInput input type is used in operations that involve filtering objects by a field of type Array. */
export type ArrayWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<Scalars["Any"]>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<Scalars["Any"]>;
  /** This is the lessThan operator to specify a constraint to select the objects where the value of a field is less than a specified value. */
  lessThan?: Maybe<Scalars["Any"]>;
  /** This is the lessThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is less than or equal to a specified value. */
  lessThanOrEqualTo?: Maybe<Scalars["Any"]>;
  /** This is the greaterThan operator to specify a constraint to select the objects where the value of a field is greater than a specified value. */
  greaterThan?: Maybe<Scalars["Any"]>;
  /** This is the greaterThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is greater than or equal to a specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars["Any"]>;
  /** This is the in operator to specify a constraint to select the objects where the value of a field equals any value in the specified array. */
  in?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the notIn operator to specify a constraint to select the objects where the value of a field do not equal any value in the specified array. */
  notIn?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the containedBy operator to specify a constraint to select the objects where the values of an array field is contained by another specified array. */
  containedBy?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the contains operator to specify a constraint to select the objects where the values of an array field contain all elements of another specified array. */
  contains?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** An entry from an object, i.e., a pair of key and value. */
export type KeyValueInput = {
  /** The key used to retrieve the value of this entry. */
  key: Scalars["String"];
  /** The value of the entry. Could be any type of scalar data. */
  value: Scalars["Any"];
};

/** The ObjectWhereInput input type is used in operations that involve filtering result by a field of type Object. */
export type ObjectWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<KeyValueInput>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<KeyValueInput>;
  /** This is the in operator to specify a constraint to select the objects where the value of a field equals any value in the specified array. */
  in?: Maybe<Array<Maybe<KeyValueInput>>>;
  /** This is the notIn operator to specify a constraint to select the objects where the value of a field do not equal any value in the specified array. */
  notIn?: Maybe<Array<Maybe<KeyValueInput>>>;
  /** This is the lessThan operator to specify a constraint to select the objects where the value of a field is less than a specified value. */
  lessThan?: Maybe<KeyValueInput>;
  /** This is the lessThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is less than or equal to a specified value. */
  lessThanOrEqualTo?: Maybe<KeyValueInput>;
  /** This is the greaterThan operator to specify a constraint to select the objects where the value of a field is greater than a specified value. */
  greaterThan?: Maybe<KeyValueInput>;
  /** This is the greaterThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is greater than or equal to a specified value. */
  greaterThanOrEqualTo?: Maybe<KeyValueInput>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** The DateWhereInput input type is used in operations that involve filtering objects by a field of type Date. */
export type DateWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<Scalars["Date"]>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<Scalars["Date"]>;
  /** This is the lessThan operator to specify a constraint to select the objects where the value of a field is less than a specified value. */
  lessThan?: Maybe<Scalars["Date"]>;
  /** This is the lessThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is less than or equal to a specified value. */
  lessThanOrEqualTo?: Maybe<Scalars["Date"]>;
  /** This is the greaterThan operator to specify a constraint to select the objects where the value of a field is greater than a specified value. */
  greaterThan?: Maybe<Scalars["Date"]>;
  /** This is the greaterThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is greater than or equal to a specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars["Date"]>;
  /** This is the in operator to specify a constraint to select the objects where the value of a field equals any value in the specified array. */
  in?: Maybe<Array<Maybe<Scalars["Date"]>>>;
  /** This is the notIn operator to specify a constraint to select the objects where the value of a field do not equal any value in the specified array. */
  notIn?: Maybe<Array<Maybe<Scalars["Date"]>>>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** The BytesWhereInput input type is used in operations that involve filtering objects by a field of type Bytes. */
export type BytesWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<Scalars["Bytes"]>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<Scalars["Bytes"]>;
  /** This is the lessThan operator to specify a constraint to select the objects where the value of a field is less than a specified value. */
  lessThan?: Maybe<Scalars["Bytes"]>;
  /** This is the lessThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is less than or equal to a specified value. */
  lessThanOrEqualTo?: Maybe<Scalars["Bytes"]>;
  /** This is the greaterThan operator to specify a constraint to select the objects where the value of a field is greater than a specified value. */
  greaterThan?: Maybe<Scalars["Bytes"]>;
  /** This is the greaterThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is greater than or equal to a specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars["Bytes"]>;
  /** This is the in operator to specify a constraint to select the objects where the value of a field equals any value in the specified array. */
  in?: Maybe<Array<Maybe<Scalars["Bytes"]>>>;
  /** This is the notIn operator to specify a constraint to select the objects where the value of a field do not equal any value in the specified array. */
  notIn?: Maybe<Array<Maybe<Scalars["Bytes"]>>>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** The FileWhereInput input type is used in operations that involve filtering objects by a field of type File. */
export type FileWhereInput = {
  /** This is the equalTo operator to specify a constraint to select the objects where the value of a field equals to a specified value. */
  equalTo?: Maybe<Scalars["File"]>;
  /** This is the notEqualTo operator to specify a constraint to select the objects where the value of a field do not equal to a specified value. */
  notEqualTo?: Maybe<Scalars["File"]>;
  /** This is the lessThan operator to specify a constraint to select the objects where the value of a field is less than a specified value. */
  lessThan?: Maybe<Scalars["File"]>;
  /** This is the lessThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is less than or equal to a specified value. */
  lessThanOrEqualTo?: Maybe<Scalars["File"]>;
  /** This is the greaterThan operator to specify a constraint to select the objects where the value of a field is greater than a specified value. */
  greaterThan?: Maybe<Scalars["File"]>;
  /** This is the greaterThanOrEqualTo operator to specify a constraint to select the objects where the value of a field is greater than or equal to a specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars["File"]>;
  /** This is the in operator to specify a constraint to select the objects where the value of a field equals any value in the specified array. */
  in?: Maybe<Array<Maybe<Scalars["File"]>>>;
  /** This is the notIn operator to specify a constraint to select the objects where the value of a field do not equal any value in the specified array. */
  notIn?: Maybe<Array<Maybe<Scalars["File"]>>>;
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the matchesRegex operator to specify a constraint to select the objects where the value of a field matches a specified regular expression. */
  matchesRegex?: Maybe<Scalars["String"]>;
  /** This is the options operator to specify optional flags (such as "i" and "m") to be added to a matchesRegex operation in the same set of constraints. */
  options?: Maybe<Scalars["String"]>;
  /** This is the inQueryKey operator to specify a constraint to select the objects where a field equals to a key in the result of a different query. */
  inQueryKey?: Maybe<SelectInput>;
  /** This is the notInQueryKey operator to specify a constraint to select the objects where a field do not equal to a key in the result of a different query. */
  notInQueryKey?: Maybe<SelectInput>;
};

/** The GeoPointWhereInput input type is used in operations that involve filtering objects by a field of type GeoPoint. */
export type GeoPointWhereInput = {
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the nearSphere operator to specify a constraint to select the objects where the values of a geo point field is near to another geo point. */
  nearSphere?: Maybe<GeoPointInput>;
  /** This is the maxDistance operator to specify a constraint to select the objects where the values of a geo point field is at a max distance (in radians) from the geo point specified in the $nearSphere operator. */
  maxDistance?: Maybe<Scalars["Float"]>;
  /** This is the maxDistanceInRadians operator to specify a constraint to select the objects where the values of a geo point field is at a max distance (in radians) from the geo point specified in the $nearSphere operator. */
  maxDistanceInRadians?: Maybe<Scalars["Float"]>;
  /** This is the maxDistanceInMiles operator to specify a constraint to select the objects where the values of a geo point field is at a max distance (in miles) from the geo point specified in the $nearSphere operator. */
  maxDistanceInMiles?: Maybe<Scalars["Float"]>;
  /** This is the maxDistanceInKilometers operator to specify a constraint to select the objects where the values of a geo point field is at a max distance (in kilometers) from the geo point specified in the $nearSphere operator. */
  maxDistanceInKilometers?: Maybe<Scalars["Float"]>;
  /** This is the within operator to specify a constraint to select the objects where the values of a geo point field is within a specified box. */
  within?: Maybe<WithinInput>;
  /** This is the geoWithin operator to specify a constraint to select the objects where the values of a geo point field is within a specified polygon or sphere. */
  geoWithin?: Maybe<GeoWithinInput>;
};

/** The PolygonWhereInput input type is used in operations that involve filtering objects by a field of type Polygon. */
export type PolygonWhereInput = {
  /** This is the exists operator to specify a constraint to select the objects where a field exists (or do not exist). */
  exists?: Maybe<Scalars["Boolean"]>;
  /** This is the geoIntersects operator to specify a constraint to select the objects where the values of a polygon field intersect a specified point. */
  geoIntersects?: Maybe<GeoIntersectsInput>;
};

/** The Element object type is used to return array items' value. */
export type Element = {
  __typename?: "Element";
  /** Return the value of the element in the array */
  value: Scalars["Any"];
};

/** Allow to manage access rights. If not provided object will be publicly readable and writable */
export type AclInput = {
  /** Access control list for users. */
  users?: Maybe<Array<UserAclInput>>;
  /** Access control list for roles. */
  roles?: Maybe<Array<RoleAclInput>>;
  /** Public access control list. */
  public?: Maybe<PublicAclInput>;
};

/** Allow to manage users in ACL. */
export type UserAclInput = {
  /** ID of the targetted User. */
  userId: Scalars["ID"];
  /** Allow the user to read the current object. */
  read: Scalars["Boolean"];
  /** Allow the user to write on the current object. */
  write: Scalars["Boolean"];
};

/** Allow to manage roles in ACL. */
export type RoleAclInput = {
  /** Name of the targetted Role. */
  roleName: Scalars["String"];
  /** Allow users who are members of the role to read the current object. */
  read: Scalars["Boolean"];
  /** Allow users who are members of the role to write on the current object. */
  write: Scalars["Boolean"];
};

/** Allow to manage public rights. */
export type PublicAclInput = {
  /** Allow anyone to read the current object. */
  read: Scalars["Boolean"];
  /** Allow anyone to write on the current object. */
  write: Scalars["Boolean"];
};

/** Current access control list of the current object. */
export type Acl = {
  __typename?: "ACL";
  /** Access control list for users. */
  users?: Maybe<Array<UserAcl>>;
  /** Access control list for roles. */
  roles?: Maybe<Array<RoleAcl>>;
  /** Public access control list. */
  public?: Maybe<PublicAcl>;
};

/** Allow to manage users in ACL. If read and write are null the users have read and write rights. */
export type UserAcl = {
  __typename?: "UserACL";
  /** ID of the targetted User. */
  userId: Scalars["ID"];
  /** Allow the user to read the current object. */
  read: Scalars["Boolean"];
  /** Allow the user to write on the current object. */
  write: Scalars["Boolean"];
};

/** Allow to manage roles in ACL. If read and write are null the role have read and write rights. */
export type RoleAcl = {
  __typename?: "RoleACL";
  /** Name of the targetted Role. */
  roleName: Scalars["ID"];
  /** Allow users who are members of the role to read the current object. */
  read: Scalars["Boolean"];
  /** Allow users who are members of the role to write on the current object. */
  write: Scalars["Boolean"];
};

/** Allow to manage public rights. */
export type PublicAcl = {
  __typename?: "PublicACL";
  /** Allow anyone to read the current object. */
  read?: Maybe<Scalars["Boolean"]>;
  /** Allow anyone to write on the current object. */
  write?: Maybe<Scalars["Boolean"]>;
};

/** The SubqueryInput type is used to specify a sub query to another class. */
export type SubqueryInput = {
  /** This is the class name of the object. */
  className: Scalars["String"];
  /** These are the conditions that the objects need to match in order to be found */
  where: Scalars["Object"];
};

/** The SelectInput type is used to specify an inQueryKey or a notInQueryKey operation on a constraint. */
export type SelectInput = {
  /** This is the subquery to be executed. */
  query: SubqueryInput;
  /** This is the key in the result of the subquery that must match (not match) the field. */
  key: Scalars["String"];
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars["ID"];
};

/** The SchemaFieldInput is used to specify a field of an object class schema. */
export type SchemaFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaStringFieldInput is used to specify a field of type string for an object class schema. */
export type SchemaStringFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaStringField is used to return information of a String field. */
export type SchemaStringField = SchemaField & {
  __typename?: "SchemaStringField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaField interface type is used as a base type for the different supported fields of an object class schema. */
export type SchemaField = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaNumberFieldInput is used to specify a field of type number for an object class schema. */
export type SchemaNumberFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaNumberField is used to return information of a Number field. */
export type SchemaNumberField = SchemaField & {
  __typename?: "SchemaNumberField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaBooleanFieldInput is used to specify a field of type boolean for an object class schema. */
export type SchemaBooleanFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaBooleanField is used to return information of a Boolean field. */
export type SchemaBooleanField = SchemaField & {
  __typename?: "SchemaBooleanField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaArrayFieldInput is used to specify a field of type array for an object class schema. */
export type SchemaArrayFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaArrayField is used to return information of an Array field. */
export type SchemaArrayField = SchemaField & {
  __typename?: "SchemaArrayField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaObjectFieldInput is used to specify a field of type object for an object class schema. */
export type SchemaObjectFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaObjectField is used to return information of an Object field. */
export type SchemaObjectField = SchemaField & {
  __typename?: "SchemaObjectField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaDateFieldInput is used to specify a field of type date for an object class schema. */
export type SchemaDateFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaDateField is used to return information of a Date field. */
export type SchemaDateField = SchemaField & {
  __typename?: "SchemaDateField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaFileFieldInput is used to specify a field of type file for an object class schema. */
export type SchemaFileFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaFileField is used to return information of a File field. */
export type SchemaFileField = SchemaField & {
  __typename?: "SchemaFileField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaGeoPointFieldInput is used to specify a field of type geo point for an object class schema. */
export type SchemaGeoPointFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaGeoPointField is used to return information of a Geo Point field. */
export type SchemaGeoPointField = SchemaField & {
  __typename?: "SchemaGeoPointField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaPolygonFieldInput is used to specify a field of type polygon for an object class schema. */
export type SchemaPolygonFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaPolygonField is used to return information of a Polygon field. */
export type SchemaPolygonField = SchemaField & {
  __typename?: "SchemaPolygonField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaBytesFieldInput is used to specify a field of type bytes for an object class schema. */
export type SchemaBytesFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
};

/** The SchemaBytesField is used to return information of a Bytes field. */
export type SchemaBytesField = SchemaField & {
  __typename?: "SchemaBytesField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The PointerFieldInput is used to specify a field of type pointer for an object class schema. */
export type PointerFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
  /** This is the name of the target class for the field. */
  targetClassName: Scalars["String"];
};

/** The SchemaPointerField is used to return information of a Pointer field. */
export type SchemaPointerField = SchemaField & {
  __typename?: "SchemaPointerField";
  /** This is the field name. */
  name: Scalars["String"];
  /** This is the name of the target class for the field. */
  targetClassName: Scalars["String"];
};

/** The RelationFieldInput is used to specify a field of type relation for an object class schema. */
export type RelationFieldInput = {
  /** This is the field name. */
  name: Scalars["String"];
  /** This is the name of the target class for the field. */
  targetClassName: Scalars["String"];
};

/** The SchemaRelationField is used to return information of a Relation field. */
export type SchemaRelationField = SchemaField & {
  __typename?: "SchemaRelationField";
  /** This is the field name. */
  name: Scalars["String"];
  /** This is the name of the target class for the field. */
  targetClassName: Scalars["String"];
};

/** The SchemaACLField is used to return information of an ACL field. */
export type SchemaAclField = SchemaField & {
  __typename?: "SchemaACLField";
  /** This is the field name. */
  name: Scalars["String"];
};

/** The CreateClassSchemaInput type is used to specify the schema for a new object class to be created. */
export type SchemaFieldsInput = {
  /** These are the String fields to be added to the class schema. */
  addStrings?: Maybe<Array<SchemaStringFieldInput>>;
  /** These are the Number fields to be added to the class schema. */
  addNumbers?: Maybe<Array<SchemaNumberFieldInput>>;
  /** These are the Boolean fields to be added to the class schema. */
  addBooleans?: Maybe<Array<SchemaBooleanFieldInput>>;
  /** These are the Array fields to be added to the class schema. */
  addArrays?: Maybe<Array<SchemaArrayFieldInput>>;
  /** These are the Object fields to be added to the class schema. */
  addObjects?: Maybe<Array<SchemaObjectFieldInput>>;
  /** These are the Date fields to be added to the class schema. */
  addDates?: Maybe<Array<SchemaDateFieldInput>>;
  /** These are the File fields to be added to the class schema. */
  addFiles?: Maybe<Array<SchemaFileFieldInput>>;
  /** This is the Geo Point field to be added to the class schema. Currently it is supported only one GeoPoint field per Class. */
  addGeoPoint?: Maybe<SchemaGeoPointFieldInput>;
  /** These are the Polygon fields to be added to the class schema. */
  addPolygons?: Maybe<Array<SchemaPolygonFieldInput>>;
  /** These are the Bytes fields to be added to the class schema. */
  addBytes?: Maybe<Array<SchemaBytesFieldInput>>;
  /** These are the Pointer fields to be added to the class schema. */
  addPointers?: Maybe<Array<PointerFieldInput>>;
  /** These are the Relation fields to be added to the class schema. */
  addRelations?: Maybe<Array<RelationFieldInput>>;
  /** These are the fields to be removed from the class schema. */
  remove?: Maybe<Array<SchemaFieldInput>>;
};

/** The Class type is used to return the information about an object class. */
export type Class = {
  __typename?: "Class";
  /** This is the name of the object class. */
  name: Scalars["String"];
  /** These are the schema's fields of the object class. */
  schemaFields: Array<SchemaField>;
};

/** The CreateRoleFieldsInput input type is used in operations that involve creation of objects in the Role class. */
export type CreateRoleFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object name. */
  name?: Maybe<Scalars["String"]>;
  /** This is the object users. */
  users?: Maybe<UserRelationInput>;
  /** This is the object roles. */
  roles?: Maybe<RoleRelationInput>;
};

/** The UpdateRoleFieldsInput input type is used in operations that involve creation of objects in the Role class. */
export type UpdateRoleFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object name. */
  name?: Maybe<Scalars["String"]>;
  /** This is the object users. */
  users?: Maybe<UserRelationInput>;
  /** This is the object roles. */
  roles?: Maybe<RoleRelationInput>;
};

/** Allow to link OR add and link an object of the Role class. */
export type RolePointerInput = {
  /** Link an existing object from Role class. You can use either the global or the object id. */
  link?: Maybe<Scalars["ID"]>;
  /** Create and link an object from Role class. */
  createAndLink?: Maybe<CreateRoleFieldsInput>;
};

/** Allow to add, remove, createAndAdd objects of the Role class into a relation field. */
export type RoleRelationInput = {
  /** Add existing objects from the Role class into the relation. You can use either the global or the object ids. */
  add?: Maybe<Array<Scalars["ID"]>>;
  /** Remove existing objects from the Role class out of the relation. You can use either the global or the object ids. */
  remove?: Maybe<Array<Scalars["ID"]>>;
  /** Create and add objects of the Role class into the relation. */
  createAndAdd?: Maybe<Array<CreateRoleFieldsInput>>;
};

/** The RoleWhereInput input type is used in operations that involve filtering objects of Role class. */
export type RoleWhereInput = {
  /** This is the object objectId. */
  objectId?: Maybe<IdWhereInput>;
  /** This is the object createdAt. */
  createdAt?: Maybe<DateWhereInput>;
  /** This is the object updatedAt. */
  updatedAt?: Maybe<DateWhereInput>;
  /** This is the object ACL. */
  ACL?: Maybe<ObjectWhereInput>;
  /** This is the object name. */
  name?: Maybe<StringWhereInput>;
  /** This is the object users. */
  users?: Maybe<UserRelationWhereInput>;
  /** This is the object roles. */
  roles?: Maybe<RoleRelationWhereInput>;
  /** This is the object id. */
  id?: Maybe<IdWhereInput>;
  /** This is the OR operator to compound constraints. */
  OR?: Maybe<Array<RoleWhereInput>>;
  /** This is the AND operator to compound constraints. */
  AND?: Maybe<Array<RoleWhereInput>>;
  /** This is the NOR operator to compound constraints. */
  NOR?: Maybe<Array<RoleWhereInput>>;
};

/** The RoleRelationWhereInput input type is used in operations that involve filtering objects of Role class. */
export type RoleRelationWhereInput = {
  /** Run a relational/pointer query where at least one child object can match. */
  have?: Maybe<RoleWhereInput>;
  /** Run an inverted relational/pointer query where at least one child object can match. */
  haveNot?: Maybe<RoleWhereInput>;
  /** Check if the relation/pointer contains objects. */
  exists?: Maybe<Scalars["Boolean"]>;
};

/** The RoleOrder input type is used when sorting objects of the Role class. */
export enum RoleOrder {
  ObjectIdAsc = "objectId_ASC",
  ObjectIdDesc = "objectId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  AclAsc = "ACL_ASC",
  AclDesc = "ACL_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  UsersAsc = "users_ASC",
  UsersDesc = "users_DESC",
  RolesAsc = "roles_ASC",
  RolesDesc = "roles_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
}

/** The Role object type is used in operations that involve outputting objects of Role class. */
export type Role = ParseObject &
  Node & {
    __typename?: "Role";
    /** The ID of an object */
    id: Scalars["ID"];
    /** This is the object id. */
    objectId: Scalars["ID"];
    /** This is the date in which the object was created. */
    createdAt: Scalars["Date"];
    /** This is the date in which the object was las updated. */
    updatedAt: Scalars["Date"];
    ACL: Acl;
    /** This is the object name. */
    name?: Maybe<Scalars["String"]>;
    /** This is the object users. */
    users: UserConnection;
    /** This is the object roles. */
    roles: RoleConnection;
  };

/** The Role object type is used in operations that involve outputting objects of Role class. */
export type RoleUsersArgs = {
  where?: Maybe<UserWhereInput>;
  order?: Maybe<Array<UserOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** The Role object type is used in operations that involve outputting objects of Role class. */
export type RoleRolesArgs = {
  where?: Maybe<RoleWhereInput>;
  order?: Maybe<Array<RoleOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: "RoleEdge";
  /** The item at the end of the edge */
  node?: Maybe<Role>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A connection to a list of items. */
export type RoleConnection = {
  __typename?: "RoleConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoleEdge>>>;
  /** This is the total matched objecs count that is returned when the count flag is set. */
  count: Scalars["Int"];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
};

export type CreateRoleInput = {
  /** These are the fields that will be used to create the new object. */
  fields?: Maybe<CreateRoleFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateRolePayload = {
  __typename?: "CreateRolePayload";
  /** This is the created object. */
  role: Role;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateRoleInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  /** These are the fields that will be used to update the object. */
  fields?: Maybe<UpdateRoleFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateRolePayload = {
  __typename?: "UpdateRolePayload";
  /** This is the updated object. */
  role: Role;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteRoleInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteRolePayload = {
  __typename?: "DeleteRolePayload";
  /** This is the deleted object. */
  role: Role;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The CreateSessionFieldsInput input type is used in operations that involve creation of objects in the Session class. */
export type CreateSessionFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object restricted. */
  restricted?: Maybe<Scalars["Boolean"]>;
  /** This is the object user. */
  user?: Maybe<UserPointerInput>;
  /** This is the object installationId. */
  installationId?: Maybe<Scalars["String"]>;
  /** This is the object sessionToken. */
  sessionToken?: Maybe<Scalars["String"]>;
  /** This is the object expiresAt. */
  expiresAt?: Maybe<Scalars["Date"]>;
  /** This is the object createdWith. */
  createdWith?: Maybe<Scalars["Object"]>;
};

/** The UpdateSessionFieldsInput input type is used in operations that involve creation of objects in the Session class. */
export type UpdateSessionFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object restricted. */
  restricted?: Maybe<Scalars["Boolean"]>;
  /** This is the object user. */
  user?: Maybe<UserPointerInput>;
  /** This is the object installationId. */
  installationId?: Maybe<Scalars["String"]>;
  /** This is the object sessionToken. */
  sessionToken?: Maybe<Scalars["String"]>;
  /** This is the object expiresAt. */
  expiresAt?: Maybe<Scalars["Date"]>;
  /** This is the object createdWith. */
  createdWith?: Maybe<Scalars["Object"]>;
};

/** Allow to link OR add and link an object of the Session class. */
export type SessionPointerInput = {
  /** Link an existing object from Session class. You can use either the global or the object id. */
  link?: Maybe<Scalars["ID"]>;
  /** Create and link an object from Session class. */
  createAndLink?: Maybe<CreateSessionFieldsInput>;
};

/** Allow to add, remove, createAndAdd objects of the Session class into a relation field. */
export type SessionRelationInput = {
  /** Add existing objects from the Session class into the relation. You can use either the global or the object ids. */
  add?: Maybe<Array<Scalars["ID"]>>;
  /** Remove existing objects from the Session class out of the relation. You can use either the global or the object ids. */
  remove?: Maybe<Array<Scalars["ID"]>>;
  /** Create and add objects of the Session class into the relation. */
  createAndAdd?: Maybe<Array<CreateSessionFieldsInput>>;
};

/** The SessionWhereInput input type is used in operations that involve filtering objects of Session class. */
export type SessionWhereInput = {
  /** This is the object objectId. */
  objectId?: Maybe<IdWhereInput>;
  /** This is the object createdAt. */
  createdAt?: Maybe<DateWhereInput>;
  /** This is the object updatedAt. */
  updatedAt?: Maybe<DateWhereInput>;
  /** This is the object ACL. */
  ACL?: Maybe<ObjectWhereInput>;
  /** This is the object restricted. */
  restricted?: Maybe<BooleanWhereInput>;
  /** This is the object user. */
  user?: Maybe<UserRelationWhereInput>;
  /** This is the object installationId. */
  installationId?: Maybe<StringWhereInput>;
  /** This is the object sessionToken. */
  sessionToken?: Maybe<StringWhereInput>;
  /** This is the object expiresAt. */
  expiresAt?: Maybe<DateWhereInput>;
  /** This is the object createdWith. */
  createdWith?: Maybe<ObjectWhereInput>;
  /** This is the object id. */
  id?: Maybe<IdWhereInput>;
  /** This is the OR operator to compound constraints. */
  OR?: Maybe<Array<SessionWhereInput>>;
  /** This is the AND operator to compound constraints. */
  AND?: Maybe<Array<SessionWhereInput>>;
  /** This is the NOR operator to compound constraints. */
  NOR?: Maybe<Array<SessionWhereInput>>;
};

/** The SessionRelationWhereInput input type is used in operations that involve filtering objects of Session class. */
export type SessionRelationWhereInput = {
  /** Run a relational/pointer query where at least one child object can match. */
  have?: Maybe<SessionWhereInput>;
  /** Run an inverted relational/pointer query where at least one child object can match. */
  haveNot?: Maybe<SessionWhereInput>;
  /** Check if the relation/pointer contains objects. */
  exists?: Maybe<Scalars["Boolean"]>;
};

/** The SessionOrder input type is used when sorting objects of the Session class. */
export enum SessionOrder {
  ObjectIdAsc = "objectId_ASC",
  ObjectIdDesc = "objectId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  AclAsc = "ACL_ASC",
  AclDesc = "ACL_DESC",
  RestrictedAsc = "restricted_ASC",
  RestrictedDesc = "restricted_DESC",
  UserAsc = "user_ASC",
  UserDesc = "user_DESC",
  InstallationIdAsc = "installationId_ASC",
  InstallationIdDesc = "installationId_DESC",
  SessionTokenAsc = "sessionToken_ASC",
  SessionTokenDesc = "sessionToken_DESC",
  ExpiresAtAsc = "expiresAt_ASC",
  ExpiresAtDesc = "expiresAt_DESC",
  CreatedWithAsc = "createdWith_ASC",
  CreatedWithDesc = "createdWith_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
}

/** The Session object type is used in operations that involve outputting objects of Session class. */
export type Session = ParseObject &
  Node & {
    __typename?: "Session";
    /** The ID of an object */
    id: Scalars["ID"];
    /** This is the object id. */
    objectId: Scalars["ID"];
    /** This is the date in which the object was created. */
    createdAt: Scalars["Date"];
    /** This is the date in which the object was las updated. */
    updatedAt: Scalars["Date"];
    ACL: Acl;
    /** This is the object restricted. */
    restricted?: Maybe<Scalars["Boolean"]>;
    /** This is the object user. */
    user?: Maybe<User>;
    /** This is the object installationId. */
    installationId?: Maybe<Scalars["String"]>;
    /** This is the object sessionToken. */
    sessionToken?: Maybe<Scalars["String"]>;
    /** This is the object expiresAt. */
    expiresAt?: Maybe<Scalars["Date"]>;
    /** This is the object createdWith. */
    createdWith?: Maybe<Scalars["Object"]>;
  };

/** An edge in a connection. */
export type SessionEdge = {
  __typename?: "SessionEdge";
  /** The item at the end of the edge */
  node?: Maybe<Session>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A connection to a list of items. */
export type SessionConnection = {
  __typename?: "SessionConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SessionEdge>>>;
  /** This is the total matched objecs count that is returned when the count flag is set. */
  count: Scalars["Int"];
};

export type CreateSessionInput = {
  /** These are the fields that will be used to create the new object. */
  fields?: Maybe<CreateSessionFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateSessionPayload = {
  __typename?: "CreateSessionPayload";
  /** This is the created object. */
  session: Session;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateSessionInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  /** These are the fields that will be used to update the object. */
  fields?: Maybe<UpdateSessionFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateSessionPayload = {
  __typename?: "UpdateSessionPayload";
  /** This is the updated object. */
  session: Session;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteSessionInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteSessionPayload = {
  __typename?: "DeleteSessionPayload";
  /** This is the deleted object. */
  session: Session;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The CreateUserFieldsInput input type is used in operations that involve creation of objects in the User class. */
export type CreateUserFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object username. */
  username: Scalars["String"];
  /** This is the object password. */
  password: Scalars["String"];
  /** This is the object email. */
  email?: Maybe<Scalars["String"]>;
  /** This is the object emailVerified. */
  emailVerified?: Maybe<Scalars["Boolean"]>;
  /** This is the object authData. */
  authData?: Maybe<Scalars["Object"]>;
  /** This is the object name. */
  name: Scalars["String"];
  /** This is the object avatar. */
  avatar?: Maybe<FileInput>;
  /** This is the object role. */
  role?: Maybe<RolePointerInput>;
};

/** The UpdateUserFieldsInput input type is used in operations that involve creation of objects in the User class. */
export type UpdateUserFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object username. */
  username?: Maybe<Scalars["String"]>;
  /** This is the object password. */
  password?: Maybe<Scalars["String"]>;
  /** This is the object email. */
  email?: Maybe<Scalars["String"]>;
  /** This is the object emailVerified. */
  emailVerified?: Maybe<Scalars["Boolean"]>;
  /** This is the object authData. */
  authData?: Maybe<Scalars["Object"]>;
  /** This is the object name. */
  name?: Maybe<Scalars["String"]>;
  /** This is the object avatar. */
  avatar?: Maybe<FileInput>;
  /** This is the object role. */
  role?: Maybe<RolePointerInput>;
};

/** Allow to link OR add and link an object of the User class. */
export type UserPointerInput = {
  /** Link an existing object from User class. You can use either the global or the object id. */
  link?: Maybe<Scalars["ID"]>;
  /** Create and link an object from User class. */
  createAndLink?: Maybe<CreateUserFieldsInput>;
};

/** Allow to add, remove, createAndAdd objects of the User class into a relation field. */
export type UserRelationInput = {
  /** Add existing objects from the User class into the relation. You can use either the global or the object ids. */
  add?: Maybe<Array<Scalars["ID"]>>;
  /** Remove existing objects from the User class out of the relation. You can use either the global or the object ids. */
  remove?: Maybe<Array<Scalars["ID"]>>;
  /** Create and add objects of the User class into the relation. */
  createAndAdd?: Maybe<Array<CreateUserFieldsInput>>;
};

/** The UserWhereInput input type is used in operations that involve filtering objects of User class. */
export type UserWhereInput = {
  /** This is the object objectId. */
  objectId?: Maybe<IdWhereInput>;
  /** This is the object createdAt. */
  createdAt?: Maybe<DateWhereInput>;
  /** This is the object updatedAt. */
  updatedAt?: Maybe<DateWhereInput>;
  /** This is the object ACL. */
  ACL?: Maybe<ObjectWhereInput>;
  /** This is the object username. */
  username?: Maybe<StringWhereInput>;
  /** This is the object password. */
  password?: Maybe<StringWhereInput>;
  /** This is the object email. */
  email?: Maybe<StringWhereInput>;
  /** This is the object emailVerified. */
  emailVerified?: Maybe<BooleanWhereInput>;
  /** This is the object authData. */
  authData?: Maybe<ObjectWhereInput>;
  /** This is the object name. */
  name?: Maybe<StringWhereInput>;
  /** This is the object avatar. */
  avatar?: Maybe<FileWhereInput>;
  /** This is the object role. */
  role?: Maybe<RoleRelationWhereInput>;
  /** This is the object id. */
  id?: Maybe<IdWhereInput>;
  /** This is the OR operator to compound constraints. */
  OR?: Maybe<Array<UserWhereInput>>;
  /** This is the AND operator to compound constraints. */
  AND?: Maybe<Array<UserWhereInput>>;
  /** This is the NOR operator to compound constraints. */
  NOR?: Maybe<Array<UserWhereInput>>;
};

/** The UserRelationWhereInput input type is used in operations that involve filtering objects of User class. */
export type UserRelationWhereInput = {
  /** Run a relational/pointer query where at least one child object can match. */
  have?: Maybe<UserWhereInput>;
  /** Run an inverted relational/pointer query where at least one child object can match. */
  haveNot?: Maybe<UserWhereInput>;
  /** Check if the relation/pointer contains objects. */
  exists?: Maybe<Scalars["Boolean"]>;
};

/** The UserOrder input type is used when sorting objects of the User class. */
export enum UserOrder {
  ObjectIdAsc = "objectId_ASC",
  ObjectIdDesc = "objectId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  AclAsc = "ACL_ASC",
  AclDesc = "ACL_DESC",
  UsernameAsc = "username_ASC",
  UsernameDesc = "username_DESC",
  PasswordAsc = "password_ASC",
  PasswordDesc = "password_DESC",
  EmailAsc = "email_ASC",
  EmailDesc = "email_DESC",
  EmailVerifiedAsc = "emailVerified_ASC",
  EmailVerifiedDesc = "emailVerified_DESC",
  AuthDataAsc = "authData_ASC",
  AuthDataDesc = "authData_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  AvatarAsc = "avatar_ASC",
  AvatarDesc = "avatar_DESC",
  RoleAsc = "role_ASC",
  RoleDesc = "role_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
}

/** The User object type is used in operations that involve outputting objects of User class. */
export type User = ParseObject &
  Node & {
    __typename?: "User";
    /** The ID of an object */
    id: Scalars["ID"];
    /** This is the object id. */
    objectId: Scalars["ID"];
    /** This is the date in which the object was created. */
    createdAt: Scalars["Date"];
    /** This is the date in which the object was las updated. */
    updatedAt: Scalars["Date"];
    ACL: Acl;
    /** This is the object username. */
    username?: Maybe<Scalars["String"]>;
    /** This is the object email. */
    email?: Maybe<Scalars["String"]>;
    /** This is the object emailVerified. */
    emailVerified?: Maybe<Scalars["Boolean"]>;
    /** This is the object authData. */
    authData?: Maybe<Scalars["Object"]>;
    /** This is the object name. */
    name: Scalars["String"];
    /** This is the object avatar. */
    avatar?: Maybe<FileInfo>;
    /** This is the object role. */
    role?: Maybe<Role>;
  };

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** The item at the end of the edge */
  node?: Maybe<User>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: "UserConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** This is the total matched objecs count that is returned when the count flag is set. */
  count: Scalars["Int"];
};

/** The Viewer object type is used in operations that involve outputting the current user data. */
export type Viewer = {
  __typename?: "Viewer";
  /** The current user session token. */
  sessionToken: Scalars["String"];
  /** This is the current user. */
  user: User;
};

export type CreateUserInput = {
  /** These are the fields that will be used to create the new object. */
  fields?: Maybe<CreateUserFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateUserPayload = {
  __typename?: "CreateUserPayload";
  /** This is the created object. */
  user: User;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateUserInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  /** These are the fields that will be used to update the object. */
  fields?: Maybe<UpdateUserFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  /** This is the updated object. */
  user: User;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteUserInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteUserPayload = {
  __typename?: "DeleteUserPayload";
  /** This is the deleted object. */
  user: User;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The CreateHanziFieldsInput input type is used in operations that involve creation of objects in the Hanzi class. */
export type CreateHanziFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object character. */
  character?: Maybe<Scalars["String"]>;
  /** This is the object strokes. */
  strokes?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object medians. */
  medians?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserPointerInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserPointerInput>;
  /** This is the object status. */
  status?: Maybe<Scalars["String"]>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<Scalars["Date"]>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<Scalars["Date"]>;
};

/** The UpdateHanziFieldsInput input type is used in operations that involve creation of objects in the Hanzi class. */
export type UpdateHanziFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object character. */
  character?: Maybe<Scalars["String"]>;
  /** This is the object strokes. */
  strokes?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object medians. */
  medians?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserPointerInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserPointerInput>;
  /** This is the object status. */
  status?: Maybe<Scalars["String"]>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<Scalars["Date"]>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<Scalars["Date"]>;
};

/** Allow to link OR add and link an object of the Hanzi class. */
export type HanziPointerInput = {
  /** Link an existing object from Hanzi class. You can use either the global or the object id. */
  link?: Maybe<Scalars["ID"]>;
  /** Create and link an object from Hanzi class. */
  createAndLink?: Maybe<CreateHanziFieldsInput>;
};

/** Allow to add, remove, createAndAdd objects of the Hanzi class into a relation field. */
export type HanziRelationInput = {
  /** Add existing objects from the Hanzi class into the relation. You can use either the global or the object ids. */
  add?: Maybe<Array<Scalars["ID"]>>;
  /** Remove existing objects from the Hanzi class out of the relation. You can use either the global or the object ids. */
  remove?: Maybe<Array<Scalars["ID"]>>;
  /** Create and add objects of the Hanzi class into the relation. */
  createAndAdd?: Maybe<Array<CreateHanziFieldsInput>>;
};

/** The HanziWhereInput input type is used in operations that involve filtering objects of Hanzi class. */
export type HanziWhereInput = {
  /** This is the object objectId. */
  objectId?: Maybe<IdWhereInput>;
  /** This is the object createdAt. */
  createdAt?: Maybe<DateWhereInput>;
  /** This is the object updatedAt. */
  updatedAt?: Maybe<DateWhereInput>;
  /** This is the object ACL. */
  ACL?: Maybe<ObjectWhereInput>;
  /** This is the object character. */
  character?: Maybe<StringWhereInput>;
  /** This is the object strokes. */
  strokes?: Maybe<ArrayWhereInput>;
  /** This is the object medians. */
  medians?: Maybe<ArrayWhereInput>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserRelationWhereInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserRelationWhereInput>;
  /** This is the object status. */
  status?: Maybe<StringWhereInput>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<DateWhereInput>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<DateWhereInput>;
  /** This is the object id. */
  id?: Maybe<IdWhereInput>;
  /** This is the OR operator to compound constraints. */
  OR?: Maybe<Array<HanziWhereInput>>;
  /** This is the AND operator to compound constraints. */
  AND?: Maybe<Array<HanziWhereInput>>;
  /** This is the NOR operator to compound constraints. */
  NOR?: Maybe<Array<HanziWhereInput>>;
};

/** The HanziRelationWhereInput input type is used in operations that involve filtering objects of Hanzi class. */
export type HanziRelationWhereInput = {
  /** Run a relational/pointer query where at least one child object can match. */
  have?: Maybe<HanziWhereInput>;
  /** Run an inverted relational/pointer query where at least one child object can match. */
  haveNot?: Maybe<HanziWhereInput>;
  /** Check if the relation/pointer contains objects. */
  exists?: Maybe<Scalars["Boolean"]>;
};

/** The HanziOrder input type is used when sorting objects of the Hanzi class. */
export enum HanziOrder {
  ObjectIdAsc = "objectId_ASC",
  ObjectIdDesc = "objectId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  AclAsc = "ACL_ASC",
  AclDesc = "ACL_DESC",
  CharacterAsc = "character_ASC",
  CharacterDesc = "character_DESC",
  StrokesAsc = "strokes_ASC",
  StrokesDesc = "strokes_DESC",
  MediansAsc = "medians_ASC",
  MediansDesc = "medians_DESC",
  LastUpdatedByAsc = "lastUpdatedBy_ASC",
  LastUpdatedByDesc = "lastUpdatedBy_DESC",
  ApprovedByAsc = "approvedBy_ASC",
  ApprovedByDesc = "approvedBy_DESC",
  StatusAsc = "status_ASC",
  StatusDesc = "status_DESC",
  LastUpdatedAtAsc = "lastUpdatedAt_ASC",
  LastUpdatedAtDesc = "lastUpdatedAt_DESC",
  LastApprovedAtAsc = "lastApprovedAt_ASC",
  LastApprovedAtDesc = "lastApprovedAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
}

/** The Hanzi object type is used in operations that involve outputting objects of Hanzi class. */
export type Hanzi = ParseObject &
  Node & {
    __typename?: "Hanzi";
    /** The ID of an object */
    id: Scalars["ID"];
    /** This is the object id. */
    objectId: Scalars["ID"];
    /** This is the date in which the object was created. */
    createdAt: Scalars["Date"];
    /** This is the date in which the object was las updated. */
    updatedAt: Scalars["Date"];
    ACL: Acl;
    /** This is the object character. */
    character?: Maybe<Scalars["String"]>;
    /** Use Inline Fragment on Array to get results: https://graphql.org/learn/queries/#inline-fragments */
    strokes?: Maybe<Array<Maybe<ArrayResult>>>;
    /** Use Inline Fragment on Array to get results: https://graphql.org/learn/queries/#inline-fragments */
    medians?: Maybe<Array<Maybe<ArrayResult>>>;
    /** This is the object lastUpdatedBy. */
    lastUpdatedBy?: Maybe<User>;
    /** This is the object approvedBy. */
    approvedBy?: Maybe<User>;
    /** This is the object status. */
    status?: Maybe<Scalars["String"]>;
    /** This is the object lastUpdatedAt. */
    lastUpdatedAt?: Maybe<Scalars["Date"]>;
    /** This is the object lastApprovedAt. */
    lastApprovedAt?: Maybe<Scalars["Date"]>;
  };

/** An edge in a connection. */
export type HanziEdge = {
  __typename?: "HanziEdge";
  /** The item at the end of the edge */
  node?: Maybe<Hanzi>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A connection to a list of items. */
export type HanziConnection = {
  __typename?: "HanziConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HanziEdge>>>;
  /** This is the total matched objecs count that is returned when the count flag is set. */
  count: Scalars["Int"];
};

export type CreateHanziInput = {
  /** These are the fields that will be used to create the new object. */
  fields?: Maybe<CreateHanziFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateHanziPayload = {
  __typename?: "CreateHanziPayload";
  /** This is the created object. */
  hanzi: Hanzi;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateHanziInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  /** These are the fields that will be used to update the object. */
  fields?: Maybe<UpdateHanziFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateHanziPayload = {
  __typename?: "UpdateHanziPayload";
  /** This is the updated object. */
  hanzi: Hanzi;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteHanziInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteHanziPayload = {
  __typename?: "DeleteHanziPayload";
  /** This is the deleted object. */
  hanzi: Hanzi;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The CreateListFieldsInput input type is used in operations that involve creation of objects in the List class. */
export type CreateListFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object sublist. */
  sublist: Scalars["String"];
  /** This is the object list. */
  list?: Maybe<Scalars["String"]>;
  /** This is the object name. */
  name?: Maybe<Scalars["String"]>;
};

/** The UpdateListFieldsInput input type is used in operations that involve creation of objects in the List class. */
export type UpdateListFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object sublist. */
  sublist?: Maybe<Scalars["String"]>;
  /** This is the object list. */
  list?: Maybe<Scalars["String"]>;
  /** This is the object name. */
  name?: Maybe<Scalars["String"]>;
};

/** Allow to link OR add and link an object of the List class. */
export type ListPointerInput = {
  /** Link an existing object from List class. You can use either the global or the object id. */
  link?: Maybe<Scalars["ID"]>;
  /** Create and link an object from List class. */
  createAndLink?: Maybe<CreateListFieldsInput>;
};

/** Allow to add, remove, createAndAdd objects of the List class into a relation field. */
export type ListRelationInput = {
  /** Add existing objects from the List class into the relation. You can use either the global or the object ids. */
  add?: Maybe<Array<Scalars["ID"]>>;
  /** Remove existing objects from the List class out of the relation. You can use either the global or the object ids. */
  remove?: Maybe<Array<Scalars["ID"]>>;
  /** Create and add objects of the List class into the relation. */
  createAndAdd?: Maybe<Array<CreateListFieldsInput>>;
};

/** The ListWhereInput input type is used in operations that involve filtering objects of List class. */
export type ListWhereInput = {
  /** This is the object objectId. */
  objectId?: Maybe<IdWhereInput>;
  /** This is the object createdAt. */
  createdAt?: Maybe<DateWhereInput>;
  /** This is the object updatedAt. */
  updatedAt?: Maybe<DateWhereInput>;
  /** This is the object ACL. */
  ACL?: Maybe<ObjectWhereInput>;
  /** This is the object sublist. */
  sublist?: Maybe<StringWhereInput>;
  /** This is the object list. */
  list?: Maybe<StringWhereInput>;
  /** This is the object name. */
  name?: Maybe<StringWhereInput>;
  /** This is the object id. */
  id?: Maybe<IdWhereInput>;
  /** This is the OR operator to compound constraints. */
  OR?: Maybe<Array<ListWhereInput>>;
  /** This is the AND operator to compound constraints. */
  AND?: Maybe<Array<ListWhereInput>>;
  /** This is the NOR operator to compound constraints. */
  NOR?: Maybe<Array<ListWhereInput>>;
};

/** The ListRelationWhereInput input type is used in operations that involve filtering objects of List class. */
export type ListRelationWhereInput = {
  /** Run a relational/pointer query where at least one child object can match. */
  have?: Maybe<ListWhereInput>;
  /** Run an inverted relational/pointer query where at least one child object can match. */
  haveNot?: Maybe<ListWhereInput>;
  /** Check if the relation/pointer contains objects. */
  exists?: Maybe<Scalars["Boolean"]>;
};

/** The ListOrder input type is used when sorting objects of the List class. */
export enum ListOrder {
  ObjectIdAsc = "objectId_ASC",
  ObjectIdDesc = "objectId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  AclAsc = "ACL_ASC",
  AclDesc = "ACL_DESC",
  SublistAsc = "sublist_ASC",
  SublistDesc = "sublist_DESC",
  ListAsc = "list_ASC",
  ListDesc = "list_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
}

/** The List object type is used in operations that involve outputting objects of List class. */
export type List = ParseObject &
  Node & {
    __typename?: "List";
    /** The ID of an object */
    id: Scalars["ID"];
    /** This is the object id. */
    objectId: Scalars["ID"];
    /** This is the date in which the object was created. */
    createdAt: Scalars["Date"];
    /** This is the date in which the object was las updated. */
    updatedAt: Scalars["Date"];
    ACL: Acl;
    /** This is the object sublist. */
    sublist: Scalars["String"];
    /** This is the object list. */
    list?: Maybe<Scalars["String"]>;
    /** This is the object name. */
    name?: Maybe<Scalars["String"]>;
  };

/** An edge in a connection. */
export type ListEdge = {
  __typename?: "ListEdge";
  /** The item at the end of the edge */
  node?: Maybe<List>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A connection to a list of items. */
export type ListConnection = {
  __typename?: "ListConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ListEdge>>>;
  /** This is the total matched objecs count that is returned when the count flag is set. */
  count: Scalars["Int"];
};

export type CreateListInput = {
  /** These are the fields that will be used to create the new object. */
  fields?: Maybe<CreateListFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateListPayload = {
  __typename?: "CreateListPayload";
  /** This is the created object. */
  list: List;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateListInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  /** These are the fields that will be used to update the object. */
  fields?: Maybe<UpdateListFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateListPayload = {
  __typename?: "UpdateListPayload";
  /** This is the updated object. */
  list: List;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteListInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteListPayload = {
  __typename?: "DeleteListPayload";
  /** This is the deleted object. */
  list: List;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The CreatePresenceFieldsInput input type is used in operations that involve creation of objects in the Presence class. */
export type CreatePresenceFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object user. */
  user: UserPointerInput;
  /** This is the object online. */
  online: Scalars["Boolean"];
  /** This is the object page. */
  page?: Maybe<Scalars["String"]>;
  /** This is the object item. */
  item?: Maybe<Scalars["String"]>;
};

/** The UpdatePresenceFieldsInput input type is used in operations that involve creation of objects in the Presence class. */
export type UpdatePresenceFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object user. */
  user?: Maybe<UserPointerInput>;
  /** This is the object online. */
  online?: Maybe<Scalars["Boolean"]>;
  /** This is the object page. */
  page?: Maybe<Scalars["String"]>;
  /** This is the object item. */
  item?: Maybe<Scalars["String"]>;
};

/** Allow to link OR add and link an object of the Presence class. */
export type PresencePointerInput = {
  /** Link an existing object from Presence class. You can use either the global or the object id. */
  link?: Maybe<Scalars["ID"]>;
  /** Create and link an object from Presence class. */
  createAndLink?: Maybe<CreatePresenceFieldsInput>;
};

/** Allow to add, remove, createAndAdd objects of the Presence class into a relation field. */
export type PresenceRelationInput = {
  /** Add existing objects from the Presence class into the relation. You can use either the global or the object ids. */
  add?: Maybe<Array<Scalars["ID"]>>;
  /** Remove existing objects from the Presence class out of the relation. You can use either the global or the object ids. */
  remove?: Maybe<Array<Scalars["ID"]>>;
  /** Create and add objects of the Presence class into the relation. */
  createAndAdd?: Maybe<Array<CreatePresenceFieldsInput>>;
};

/** The PresenceWhereInput input type is used in operations that involve filtering objects of Presence class. */
export type PresenceWhereInput = {
  /** This is the object objectId. */
  objectId?: Maybe<IdWhereInput>;
  /** This is the object createdAt. */
  createdAt?: Maybe<DateWhereInput>;
  /** This is the object updatedAt. */
  updatedAt?: Maybe<DateWhereInput>;
  /** This is the object ACL. */
  ACL?: Maybe<ObjectWhereInput>;
  /** This is the object user. */
  user?: Maybe<UserRelationWhereInput>;
  /** This is the object online. */
  online?: Maybe<BooleanWhereInput>;
  /** This is the object page. */
  page?: Maybe<StringWhereInput>;
  /** This is the object item. */
  item?: Maybe<StringWhereInput>;
  /** This is the object id. */
  id?: Maybe<IdWhereInput>;
  /** This is the OR operator to compound constraints. */
  OR?: Maybe<Array<PresenceWhereInput>>;
  /** This is the AND operator to compound constraints. */
  AND?: Maybe<Array<PresenceWhereInput>>;
  /** This is the NOR operator to compound constraints. */
  NOR?: Maybe<Array<PresenceWhereInput>>;
};

/** The PresenceRelationWhereInput input type is used in operations that involve filtering objects of Presence class. */
export type PresenceRelationWhereInput = {
  /** Run a relational/pointer query where at least one child object can match. */
  have?: Maybe<PresenceWhereInput>;
  /** Run an inverted relational/pointer query where at least one child object can match. */
  haveNot?: Maybe<PresenceWhereInput>;
  /** Check if the relation/pointer contains objects. */
  exists?: Maybe<Scalars["Boolean"]>;
};

/** The PresenceOrder input type is used when sorting objects of the Presence class. */
export enum PresenceOrder {
  ObjectIdAsc = "objectId_ASC",
  ObjectIdDesc = "objectId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  AclAsc = "ACL_ASC",
  AclDesc = "ACL_DESC",
  UserAsc = "user_ASC",
  UserDesc = "user_DESC",
  OnlineAsc = "online_ASC",
  OnlineDesc = "online_DESC",
  PageAsc = "page_ASC",
  PageDesc = "page_DESC",
  ItemAsc = "item_ASC",
  ItemDesc = "item_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
}

/** The Presence object type is used in operations that involve outputting objects of Presence class. */
export type Presence = ParseObject &
  Node & {
    __typename?: "Presence";
    /** The ID of an object */
    id: Scalars["ID"];
    /** This is the object id. */
    objectId: Scalars["ID"];
    /** This is the date in which the object was created. */
    createdAt: Scalars["Date"];
    /** This is the date in which the object was las updated. */
    updatedAt: Scalars["Date"];
    ACL: Acl;
    /** This is the object user. */
    user: User;
    /** This is the object online. */
    online: Scalars["Boolean"];
    /** This is the object page. */
    page?: Maybe<Scalars["String"]>;
    /** This is the object item. */
    item?: Maybe<Scalars["String"]>;
  };

/** An edge in a connection. */
export type PresenceEdge = {
  __typename?: "PresenceEdge";
  /** The item at the end of the edge */
  node?: Maybe<Presence>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A connection to a list of items. */
export type PresenceConnection = {
  __typename?: "PresenceConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PresenceEdge>>>;
  /** This is the total matched objecs count that is returned when the count flag is set. */
  count: Scalars["Int"];
};

export type CreatePresenceInput = {
  /** These are the fields that will be used to create the new object. */
  fields?: Maybe<CreatePresenceFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreatePresencePayload = {
  __typename?: "CreatePresencePayload";
  /** This is the created object. */
  presence: Presence;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdatePresenceInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  /** These are the fields that will be used to update the object. */
  fields?: Maybe<UpdatePresenceFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdatePresencePayload = {
  __typename?: "UpdatePresencePayload";
  /** This is the updated object. */
  presence: Presence;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeletePresenceInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeletePresencePayload = {
  __typename?: "DeletePresencePayload";
  /** This is the deleted object. */
  presence: Presence;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The CreateSentenceFieldsInput input type is used in operations that involve creation of objects in the Sentence class. */
export type CreateSentenceFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object english. */
  english?: Maybe<Scalars["String"]>;
  /** This is the object audio. */
  audio?: Maybe<Scalars["String"]>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserPointerInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserPointerInput>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<Scalars["Date"]>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<Scalars["Date"]>;
  /** This is the object status. */
  status?: Maybe<Scalars["String"]>;
  /** This is the object words. */
  words?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object vocabularies. */
  vocabularies?: Maybe<VocabularyRelationInput>;
};

/** The UpdateSentenceFieldsInput input type is used in operations that involve creation of objects in the Sentence class. */
export type UpdateSentenceFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object english. */
  english?: Maybe<Scalars["String"]>;
  /** This is the object audio. */
  audio?: Maybe<Scalars["String"]>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserPointerInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserPointerInput>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<Scalars["Date"]>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<Scalars["Date"]>;
  /** This is the object status. */
  status?: Maybe<Scalars["String"]>;
  /** This is the object words. */
  words?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object vocabularies. */
  vocabularies?: Maybe<VocabularyRelationInput>;
};

/** Allow to link OR add and link an object of the Sentence class. */
export type SentencePointerInput = {
  /** Link an existing object from Sentence class. You can use either the global or the object id. */
  link?: Maybe<Scalars["ID"]>;
  /** Create and link an object from Sentence class. */
  createAndLink?: Maybe<CreateSentenceFieldsInput>;
};

/** Allow to add, remove, createAndAdd objects of the Sentence class into a relation field. */
export type SentenceRelationInput = {
  /** Add existing objects from the Sentence class into the relation. You can use either the global or the object ids. */
  add?: Maybe<Array<Scalars["ID"]>>;
  /** Remove existing objects from the Sentence class out of the relation. You can use either the global or the object ids. */
  remove?: Maybe<Array<Scalars["ID"]>>;
  /** Create and add objects of the Sentence class into the relation. */
  createAndAdd?: Maybe<Array<CreateSentenceFieldsInput>>;
};

/** The SentenceWhereInput input type is used in operations that involve filtering objects of Sentence class. */
export type SentenceWhereInput = {
  /** This is the object objectId. */
  objectId?: Maybe<IdWhereInput>;
  /** This is the object createdAt. */
  createdAt?: Maybe<DateWhereInput>;
  /** This is the object updatedAt. */
  updatedAt?: Maybe<DateWhereInput>;
  /** This is the object ACL. */
  ACL?: Maybe<ObjectWhereInput>;
  /** This is the object english. */
  english?: Maybe<StringWhereInput>;
  /** This is the object audio. */
  audio?: Maybe<StringWhereInput>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserRelationWhereInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserRelationWhereInput>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<DateWhereInput>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<DateWhereInput>;
  /** This is the object status. */
  status?: Maybe<StringWhereInput>;
  /** This is the object words. */
  words?: Maybe<ArrayWhereInput>;
  /** This is the object vocabularies. */
  vocabularies?: Maybe<VocabularyRelationWhereInput>;
  /** This is the object id. */
  id?: Maybe<IdWhereInput>;
  /** This is the OR operator to compound constraints. */
  OR?: Maybe<Array<SentenceWhereInput>>;
  /** This is the AND operator to compound constraints. */
  AND?: Maybe<Array<SentenceWhereInput>>;
  /** This is the NOR operator to compound constraints. */
  NOR?: Maybe<Array<SentenceWhereInput>>;
};

/** The SentenceRelationWhereInput input type is used in operations that involve filtering objects of Sentence class. */
export type SentenceRelationWhereInput = {
  /** Run a relational/pointer query where at least one child object can match. */
  have?: Maybe<SentenceWhereInput>;
  /** Run an inverted relational/pointer query where at least one child object can match. */
  haveNot?: Maybe<SentenceWhereInput>;
  /** Check if the relation/pointer contains objects. */
  exists?: Maybe<Scalars["Boolean"]>;
};

/** The SentenceOrder input type is used when sorting objects of the Sentence class. */
export enum SentenceOrder {
  ObjectIdAsc = "objectId_ASC",
  ObjectIdDesc = "objectId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  AclAsc = "ACL_ASC",
  AclDesc = "ACL_DESC",
  EnglishAsc = "english_ASC",
  EnglishDesc = "english_DESC",
  AudioAsc = "audio_ASC",
  AudioDesc = "audio_DESC",
  LastUpdatedByAsc = "lastUpdatedBy_ASC",
  LastUpdatedByDesc = "lastUpdatedBy_DESC",
  ApprovedByAsc = "approvedBy_ASC",
  ApprovedByDesc = "approvedBy_DESC",
  LastApprovedAtAsc = "lastApprovedAt_ASC",
  LastApprovedAtDesc = "lastApprovedAt_DESC",
  LastUpdatedAtAsc = "lastUpdatedAt_ASC",
  LastUpdatedAtDesc = "lastUpdatedAt_DESC",
  StatusAsc = "status_ASC",
  StatusDesc = "status_DESC",
  WordsAsc = "words_ASC",
  WordsDesc = "words_DESC",
  VocabulariesAsc = "vocabularies_ASC",
  VocabulariesDesc = "vocabularies_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
}

/** The Sentence object type is used in operations that involve outputting objects of Sentence class. */
export type Sentence = ParseObject &
  Node & {
    __typename?: "Sentence";
    /** The ID of an object */
    id: Scalars["ID"];
    /** This is the object id. */
    objectId: Scalars["ID"];
    /** This is the date in which the object was created. */
    createdAt: Scalars["Date"];
    /** This is the date in which the object was las updated. */
    updatedAt: Scalars["Date"];
    ACL: Acl;
    /** This is the object english. */
    english?: Maybe<Scalars["String"]>;
    /** This is the object audio. */
    audio?: Maybe<Scalars["String"]>;
    /** This is the object lastUpdatedBy. */
    lastUpdatedBy?: Maybe<User>;
    /** This is the object approvedBy. */
    approvedBy?: Maybe<User>;
    /** This is the object lastApprovedAt. */
    lastApprovedAt?: Maybe<Scalars["Date"]>;
    /** This is the object lastUpdatedAt. */
    lastUpdatedAt?: Maybe<Scalars["Date"]>;
    /** This is the object status. */
    status?: Maybe<Scalars["String"]>;
    /** Use Inline Fragment on Array to get results: https://graphql.org/learn/queries/#inline-fragments */
    words?: Maybe<Array<Maybe<ArrayResult>>>;
    /** This is the object vocabularies. */
    vocabularies: VocabularyConnection;
  };

/** The Sentence object type is used in operations that involve outputting objects of Sentence class. */
export type SentenceVocabulariesArgs = {
  where?: Maybe<VocabularyWhereInput>;
  order?: Maybe<Array<VocabularyOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** An edge in a connection. */
export type SentenceEdge = {
  __typename?: "SentenceEdge";
  /** The item at the end of the edge */
  node?: Maybe<Sentence>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A connection to a list of items. */
export type SentenceConnection = {
  __typename?: "SentenceConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SentenceEdge>>>;
  /** This is the total matched objecs count that is returned when the count flag is set. */
  count: Scalars["Int"];
};

export type CreateSentenceInput = {
  /** These are the fields that will be used to create the new object. */
  fields?: Maybe<CreateSentenceFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateSentencePayload = {
  __typename?: "CreateSentencePayload";
  /** This is the created object. */
  sentence: Sentence;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateSentenceInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  /** These are the fields that will be used to update the object. */
  fields?: Maybe<UpdateSentenceFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateSentencePayload = {
  __typename?: "UpdateSentencePayload";
  /** This is the updated object. */
  sentence: Sentence;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteSentenceInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteSentencePayload = {
  __typename?: "DeleteSentencePayload";
  /** This is the deleted object. */
  sentence: Sentence;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The CreateVocabularyFieldsInput input type is used in operations that involve creation of objects in the Vocabulary class. */
export type CreateVocabularyFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object hanzi. */
  hanzi: Scalars["String"];
  /** This is the object pinyin. */
  pinyin?: Maybe<Scalars["String"]>;
  /** This is the object speechPart. */
  speechPart?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object english. */
  english?: Maybe<Scalars["String"]>;
  /** This is the object hanzis. */
  hanzis?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<Scalars["Date"]>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<Scalars["Date"]>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserPointerInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserPointerInput>;
  /** This is the object status. */
  status?: Maybe<Scalars["String"]>;
  /** This is the object lists. */
  lists?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object legacyId. */
  legacyId?: Maybe<Scalars["String"]>;
  /** This is the object audio. */
  audio?: Maybe<Scalars["String"]>;
  /** This is the object sentences. */
  sentences?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object instagram. */
  instagram?: Maybe<Scalars["Boolean"]>;
  /** This is the object frequency. */
  frequency?: Maybe<Scalars["Float"]>;
};

/** The UpdateVocabularyFieldsInput input type is used in operations that involve creation of objects in the Vocabulary class. */
export type UpdateVocabularyFieldsInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object hanzi. */
  hanzi?: Maybe<Scalars["String"]>;
  /** This is the object pinyin. */
  pinyin?: Maybe<Scalars["String"]>;
  /** This is the object speechPart. */
  speechPart?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object english. */
  english?: Maybe<Scalars["String"]>;
  /** This is the object hanzis. */
  hanzis?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<Scalars["Date"]>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<Scalars["Date"]>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserPointerInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserPointerInput>;
  /** This is the object status. */
  status?: Maybe<Scalars["String"]>;
  /** This is the object lists. */
  lists?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object legacyId. */
  legacyId?: Maybe<Scalars["String"]>;
  /** This is the object audio. */
  audio?: Maybe<Scalars["String"]>;
  /** This is the object sentences. */
  sentences?: Maybe<Array<Maybe<Scalars["Any"]>>>;
  /** This is the object instagram. */
  instagram?: Maybe<Scalars["Boolean"]>;
  /** This is the object frequency. */
  frequency?: Maybe<Scalars["Float"]>;
};

/** Allow to link OR add and link an object of the Vocabulary class. */
export type VocabularyPointerInput = {
  /** Link an existing object from Vocabulary class. You can use either the global or the object id. */
  link?: Maybe<Scalars["ID"]>;
  /** Create and link an object from Vocabulary class. */
  createAndLink?: Maybe<CreateVocabularyFieldsInput>;
};

/** Allow to add, remove, createAndAdd objects of the Vocabulary class into a relation field. */
export type VocabularyRelationInput = {
  /** Add existing objects from the Vocabulary class into the relation. You can use either the global or the object ids. */
  add?: Maybe<Array<Scalars["ID"]>>;
  /** Remove existing objects from the Vocabulary class out of the relation. You can use either the global or the object ids. */
  remove?: Maybe<Array<Scalars["ID"]>>;
  /** Create and add objects of the Vocabulary class into the relation. */
  createAndAdd?: Maybe<Array<CreateVocabularyFieldsInput>>;
};

/** The VocabularyWhereInput input type is used in operations that involve filtering objects of Vocabulary class. */
export type VocabularyWhereInput = {
  /** This is the object objectId. */
  objectId?: Maybe<IdWhereInput>;
  /** This is the object createdAt. */
  createdAt?: Maybe<DateWhereInput>;
  /** This is the object updatedAt. */
  updatedAt?: Maybe<DateWhereInput>;
  /** This is the object ACL. */
  ACL?: Maybe<ObjectWhereInput>;
  /** This is the object hanzi. */
  hanzi?: Maybe<StringWhereInput>;
  /** This is the object pinyin. */
  pinyin?: Maybe<StringWhereInput>;
  /** This is the object speechPart. */
  speechPart?: Maybe<ArrayWhereInput>;
  /** This is the object english. */
  english?: Maybe<StringWhereInput>;
  /** This is the object hanzis. */
  hanzis?: Maybe<ArrayWhereInput>;
  /** This is the object lastApprovedAt. */
  lastApprovedAt?: Maybe<DateWhereInput>;
  /** This is the object lastUpdatedAt. */
  lastUpdatedAt?: Maybe<DateWhereInput>;
  /** This is the object lastUpdatedBy. */
  lastUpdatedBy?: Maybe<UserRelationWhereInput>;
  /** This is the object approvedBy. */
  approvedBy?: Maybe<UserRelationWhereInput>;
  /** This is the object status. */
  status?: Maybe<StringWhereInput>;
  /** This is the object lists. */
  lists?: Maybe<ArrayWhereInput>;
  /** This is the object legacyId. */
  legacyId?: Maybe<StringWhereInput>;
  /** This is the object audio. */
  audio?: Maybe<StringWhereInput>;
  /** This is the object sentences. */
  sentences?: Maybe<ArrayWhereInput>;
  /** This is the object instagram. */
  instagram?: Maybe<BooleanWhereInput>;
  /** This is the object frequency. */
  frequency?: Maybe<NumberWhereInput>;
  /** This is the object id. */
  id?: Maybe<IdWhereInput>;
  /** This is the OR operator to compound constraints. */
  OR?: Maybe<Array<VocabularyWhereInput>>;
  /** This is the AND operator to compound constraints. */
  AND?: Maybe<Array<VocabularyWhereInput>>;
  /** This is the NOR operator to compound constraints. */
  NOR?: Maybe<Array<VocabularyWhereInput>>;
};

/** The VocabularyRelationWhereInput input type is used in operations that involve filtering objects of Vocabulary class. */
export type VocabularyRelationWhereInput = {
  /** Run a relational/pointer query where at least one child object can match. */
  have?: Maybe<VocabularyWhereInput>;
  /** Run an inverted relational/pointer query where at least one child object can match. */
  haveNot?: Maybe<VocabularyWhereInput>;
  /** Check if the relation/pointer contains objects. */
  exists?: Maybe<Scalars["Boolean"]>;
};

/** The VocabularyOrder input type is used when sorting objects of the Vocabulary class. */
export enum VocabularyOrder {
  ObjectIdAsc = "objectId_ASC",
  ObjectIdDesc = "objectId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  AclAsc = "ACL_ASC",
  AclDesc = "ACL_DESC",
  HanziAsc = "hanzi_ASC",
  HanziDesc = "hanzi_DESC",
  PinyinAsc = "pinyin_ASC",
  PinyinDesc = "pinyin_DESC",
  SpeechPartAsc = "speechPart_ASC",
  SpeechPartDesc = "speechPart_DESC",
  EnglishAsc = "english_ASC",
  EnglishDesc = "english_DESC",
  HanzisAsc = "hanzis_ASC",
  HanzisDesc = "hanzis_DESC",
  LastApprovedAtAsc = "lastApprovedAt_ASC",
  LastApprovedAtDesc = "lastApprovedAt_DESC",
  LastUpdatedAtAsc = "lastUpdatedAt_ASC",
  LastUpdatedAtDesc = "lastUpdatedAt_DESC",
  LastUpdatedByAsc = "lastUpdatedBy_ASC",
  LastUpdatedByDesc = "lastUpdatedBy_DESC",
  ApprovedByAsc = "approvedBy_ASC",
  ApprovedByDesc = "approvedBy_DESC",
  StatusAsc = "status_ASC",
  StatusDesc = "status_DESC",
  ListsAsc = "lists_ASC",
  ListsDesc = "lists_DESC",
  LegacyIdAsc = "legacyId_ASC",
  LegacyIdDesc = "legacyId_DESC",
  AudioAsc = "audio_ASC",
  AudioDesc = "audio_DESC",
  SentencesAsc = "sentences_ASC",
  SentencesDesc = "sentences_DESC",
  InstagramAsc = "instagram_ASC",
  InstagramDesc = "instagram_DESC",
  FrequencyAsc = "frequency_ASC",
  FrequencyDesc = "frequency_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
}

/** The Vocabulary object type is used in operations that involve outputting objects of Vocabulary class. */
export type Vocabulary = ParseObject &
  Node & {
    __typename?: "Vocabulary";
    /** The ID of an object */
    id: Scalars["ID"];
    /** This is the object id. */
    objectId: Scalars["ID"];
    /** This is the date in which the object was created. */
    createdAt: Scalars["Date"];
    /** This is the date in which the object was las updated. */
    updatedAt: Scalars["Date"];
    ACL: Acl;
    /** This is the object hanzi. */
    hanzi: Scalars["String"];
    /** This is the object pinyin. */
    pinyin?: Maybe<Scalars["String"]>;
    /** Use Inline Fragment on Array to get results: https://graphql.org/learn/queries/#inline-fragments */
    speechPart?: Maybe<Array<Maybe<ArrayResult>>>;
    /** This is the object english. */
    english?: Maybe<Scalars["String"]>;
    /** Use Inline Fragment on Array to get results: https://graphql.org/learn/queries/#inline-fragments */
    hanzis?: Maybe<Array<Maybe<ArrayResult>>>;
    /** This is the object lastApprovedAt. */
    lastApprovedAt?: Maybe<Scalars["Date"]>;
    /** This is the object lastUpdatedAt. */
    lastUpdatedAt?: Maybe<Scalars["Date"]>;
    /** This is the object lastUpdatedBy. */
    lastUpdatedBy?: Maybe<User>;
    /** This is the object approvedBy. */
    approvedBy?: Maybe<User>;
    /** This is the object status. */
    status?: Maybe<Scalars["String"]>;
    /** Use Inline Fragment on Array to get results: https://graphql.org/learn/queries/#inline-fragments */
    lists?: Maybe<Array<Maybe<ArrayResult>>>;
    /** This is the object legacyId. */
    legacyId?: Maybe<Scalars["String"]>;
    /** This is the object audio. */
    audio?: Maybe<Scalars["String"]>;
    /** Use Inline Fragment on Array to get results: https://graphql.org/learn/queries/#inline-fragments */
    sentences?: Maybe<Array<Maybe<ArrayResult>>>;
    /** This is the object instagram. */
    instagram?: Maybe<Scalars["Boolean"]>;
    /** This is the object frequency. */
    frequency?: Maybe<Scalars["Float"]>;
  };

/** An edge in a connection. */
export type VocabularyEdge = {
  __typename?: "VocabularyEdge";
  /** The item at the end of the edge */
  node?: Maybe<Vocabulary>;
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
};

/** A connection to a list of items. */
export type VocabularyConnection = {
  __typename?: "VocabularyConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VocabularyEdge>>>;
  /** This is the total matched objecs count that is returned when the count flag is set. */
  count: Scalars["Int"];
};

export type CreateVocabularyInput = {
  /** These are the fields that will be used to create the new object. */
  fields?: Maybe<CreateVocabularyFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateVocabularyPayload = {
  __typename?: "CreateVocabularyPayload";
  /** This is the created object. */
  vocabulary: Vocabulary;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateVocabularyInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  /** These are the fields that will be used to update the object. */
  fields?: Maybe<UpdateVocabularyFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateVocabularyPayload = {
  __typename?: "UpdateVocabularyPayload";
  /** This is the updated object. */
  vocabulary: Vocabulary;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteVocabularyInput = {
  /** This is the object id. You can use either the global or the object id. */
  id: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteVocabularyPayload = {
  __typename?: "DeleteVocabularyPayload";
  /** This is the deleted object. */
  vocabulary: Vocabulary;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Use Inline Fragment on Array to get results: https://graphql.org/learn/queries/#inline-fragments */
export type ArrayResult =
  | Element
  | Role
  | Session
  | User
  | Hanzi
  | List
  | Presence
  | Sentence
  | Vocabulary;

export type CreateFileInput = {
  /** This is the new file to be created and uploaded. */
  upload: Scalars["Upload"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateFilePayload = {
  __typename?: "CreateFilePayload";
  /** This is the created file info. */
  fileInfo: FileInfo;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SignUpInput = {
  fields?: Maybe<CreateUserFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SignUpPayload = {
  __typename?: "SignUpPayload";
  /** This is the new user that was created, signed up and returned as a viewer. */
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type LogInWithInput = {
  authData: Scalars["Object"];
  fields?: Maybe<UserLoginWithInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UserLoginWithInput = {
  ACL?: Maybe<AclInput>;
  /** This is the object email. */
  email?: Maybe<Scalars["String"]>;
  /** This is the object emailVerified. */
  emailVerified?: Maybe<Scalars["Boolean"]>;
  /** This is the object name. */
  name: Scalars["String"];
  /** This is the object avatar. */
  avatar?: Maybe<FileInput>;
  /** This is the object role. */
  role?: Maybe<RolePointerInput>;
};

export type LogInWithPayload = {
  __typename?: "LogInWithPayload";
  /** This is the new user that was created, signed up and returned as a viewer. */
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type LogInInput = {
  /** This is the username used to log in the user. */
  username: Scalars["String"];
  /** This is the password used to log in the user. */
  password: Scalars["String"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type LogInPayload = {
  __typename?: "LogInPayload";
  /** This is the existing user that was logged in and returned as a viewer. */
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type LogOutInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type LogOutPayload = {
  __typename?: "LogOutPayload";
  /** This is the existing user that was logged out and returned as a viewer. */
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ResetPasswordInput = {
  email: Scalars["String"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ResetPasswordPayload = {
  __typename?: "ResetPasswordPayload";
  /** It's always true. */
  ok: Scalars["Boolean"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SendVerificationEmailInput = {
  email: Scalars["String"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SendVerificationEmailPayload = {
  __typename?: "SendVerificationEmailPayload";
  /** It's always true. */
  ok: Scalars["Boolean"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** The CloudCodeFunction enum type contains a list of all available cloud code functions. */
export enum CloudCodeFunction {
  UploadAudio = "uploadAudio",
}

export type CallCloudCodeInput = {
  /** This is the function to be called. */
  functionName: CloudCodeFunction;
  /** These are the params to be passed to the function. */
  params?: Maybe<Scalars["Object"]>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CallCloudCodePayload = {
  __typename?: "CallCloudCodePayload";
  /** This is the result value of the cloud code function execution. */
  result?: Maybe<Scalars["Any"]>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateClassInput = {
  /** This is the name of the object class. */
  name: Scalars["String"];
  /** These are the schema's fields of the object class. */
  schemaFields?: Maybe<SchemaFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateClassPayload = {
  __typename?: "CreateClassPayload";
  /** This is the created class. */
  class: Class;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateClassInput = {
  /** This is the name of the object class. */
  name: Scalars["String"];
  /** These are the schema's fields of the object class. */
  schemaFields?: Maybe<SchemaFieldsInput>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateClassPayload = {
  __typename?: "UpdateClassPayload";
  /** This is the updated class. */
  class: Class;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteClassInput = {
  /** This is the name of the object class. */
  name: Scalars["String"];
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteClassPayload = {
  __typename?: "DeleteClassPayload";
  /** This is the deleted class. */
  class: Class;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Query is the top level type for queries. */
export type Query = {
  __typename?: "Query";
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** The role query can be used to get an object of the Role class by its id. */
  role: Role;
  /** The roles query can be used to find objects of the Role class. */
  roles: RoleConnection;
  /** The session query can be used to get an object of the Session class by its id. */
  session: Session;
  /** The sessions query can be used to find objects of the Session class. */
  sessions: SessionConnection;
  /** The user query can be used to get an object of the User class by its id. */
  user: User;
  /** The users query can be used to find objects of the User class. */
  users: UserConnection;
  /** The hanzi query can be used to get an object of the Hanzi class by its id. */
  hanzi: Hanzi;
  /** The hanzis query can be used to find objects of the Hanzi class. */
  hanzis: HanziConnection;
  /** The list query can be used to get an object of the List class by its id. */
  list: List;
  /** The lists query can be used to find objects of the List class. */
  lists: ListConnection;
  /** The presence query can be used to get an object of the Presence class by its id. */
  presence: Presence;
  /** The presences query can be used to find objects of the Presence class. */
  presences: PresenceConnection;
  /** The sentence query can be used to get an object of the Sentence class by its id. */
  sentence: Sentence;
  /** The sentences query can be used to find objects of the Sentence class. */
  sentences: SentenceConnection;
  /** The vocabulary query can be used to get an object of the Vocabulary class by its id. */
  vocabulary: Vocabulary;
  /** The vocabularies query can be used to find objects of the Vocabulary class. */
  vocabularies: VocabularyConnection;
  /** The health query can be used to check if the server is up and running. */
  health: Scalars["Boolean"];
  /** The viewer query can be used to return the current user data. */
  viewer: Viewer;
  /** The class query can be used to retrieve an existing object class. */
  class: Class;
  /** The classes query can be used to retrieve the existing object classes. */
  classes: Array<Class>;
};

/** Query is the top level type for queries. */
export type QueryNodeArgs = {
  id: Scalars["ID"];
};

/** Query is the top level type for queries. */
export type QueryRoleArgs = {
  id: Scalars["ID"];
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryRolesArgs = {
  where?: Maybe<RoleWhereInput>;
  order?: Maybe<Array<RoleOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QuerySessionArgs = {
  id: Scalars["ID"];
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QuerySessionsArgs = {
  where?: Maybe<SessionWhereInput>;
  order?: Maybe<Array<SessionOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryUserArgs = {
  id: Scalars["ID"];
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  order?: Maybe<Array<UserOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryHanziArgs = {
  id: Scalars["ID"];
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryHanzisArgs = {
  where?: Maybe<HanziWhereInput>;
  order?: Maybe<Array<HanziOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryListArgs = {
  id: Scalars["ID"];
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryListsArgs = {
  where?: Maybe<ListWhereInput>;
  order?: Maybe<Array<ListOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryPresenceArgs = {
  id: Scalars["ID"];
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryPresencesArgs = {
  where?: Maybe<PresenceWhereInput>;
  order?: Maybe<Array<PresenceOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QuerySentenceArgs = {
  id: Scalars["ID"];
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QuerySentencesArgs = {
  where?: Maybe<SentenceWhereInput>;
  order?: Maybe<Array<SentenceOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryVocabularyArgs = {
  id: Scalars["ID"];
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryVocabulariesArgs = {
  where?: Maybe<VocabularyWhereInput>;
  order?: Maybe<Array<VocabularyOrder>>;
  skip?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  before?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["Int"]>;
  options?: Maybe<ReadOptionsInput>;
};

/** Query is the top level type for queries. */
export type QueryClassArgs = {
  name: Scalars["String"];
};

/** Mutation is the top level type for mutations. */
export type Mutation = {
  __typename?: "Mutation";
  /** The createRole mutation can be used to create a new object of the Role class. */
  createRole?: Maybe<CreateRolePayload>;
  /** The updateRole mutation can be used to update an object of the Role class. */
  updateRole?: Maybe<UpdateRolePayload>;
  /** The deleteRole mutation can be used to delete an object of the Role class. */
  deleteRole?: Maybe<DeleteRolePayload>;
  /** The createSession mutation can be used to create a new object of the Session class. */
  createSession?: Maybe<CreateSessionPayload>;
  /** The updateSession mutation can be used to update an object of the Session class. */
  updateSession?: Maybe<UpdateSessionPayload>;
  /** The deleteSession mutation can be used to delete an object of the Session class. */
  deleteSession?: Maybe<DeleteSessionPayload>;
  /** The createUser mutation can be used to create a new object of the User class. */
  createUser?: Maybe<CreateUserPayload>;
  /** The updateUser mutation can be used to update an object of the User class. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** The deleteUser mutation can be used to delete an object of the User class. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** The createHanzi mutation can be used to create a new object of the Hanzi class. */
  createHanzi?: Maybe<CreateHanziPayload>;
  /** The updateHanzi mutation can be used to update an object of the Hanzi class. */
  updateHanzi?: Maybe<UpdateHanziPayload>;
  /** The deleteHanzi mutation can be used to delete an object of the Hanzi class. */
  deleteHanzi?: Maybe<DeleteHanziPayload>;
  /** The createList mutation can be used to create a new object of the List class. */
  createList?: Maybe<CreateListPayload>;
  /** The updateList mutation can be used to update an object of the List class. */
  updateList?: Maybe<UpdateListPayload>;
  /** The deleteList mutation can be used to delete an object of the List class. */
  deleteList?: Maybe<DeleteListPayload>;
  /** The createPresence mutation can be used to create a new object of the Presence class. */
  createPresence?: Maybe<CreatePresencePayload>;
  /** The updatePresence mutation can be used to update an object of the Presence class. */
  updatePresence?: Maybe<UpdatePresencePayload>;
  /** The deletePresence mutation can be used to delete an object of the Presence class. */
  deletePresence?: Maybe<DeletePresencePayload>;
  /** The createSentence mutation can be used to create a new object of the Sentence class. */
  createSentence?: Maybe<CreateSentencePayload>;
  /** The updateSentence mutation can be used to update an object of the Sentence class. */
  updateSentence?: Maybe<UpdateSentencePayload>;
  /** The deleteSentence mutation can be used to delete an object of the Sentence class. */
  deleteSentence?: Maybe<DeleteSentencePayload>;
  /** The createVocabulary mutation can be used to create a new object of the Vocabulary class. */
  createVocabulary?: Maybe<CreateVocabularyPayload>;
  /** The updateVocabulary mutation can be used to update an object of the Vocabulary class. */
  updateVocabulary?: Maybe<UpdateVocabularyPayload>;
  /** The deleteVocabulary mutation can be used to delete an object of the Vocabulary class. */
  deleteVocabulary?: Maybe<DeleteVocabularyPayload>;
  /** The createFile mutation can be used to create and upload a new file. */
  createFile?: Maybe<CreateFilePayload>;
  /** The signUp mutation can be used to create and sign up a new user. */
  signUp?: Maybe<SignUpPayload>;
  /** The logInWith mutation can be used to signup, login user with 3rd party authentication system. This mutation create a user if the authData do not correspond to an existing one. */
  logInWith?: Maybe<LogInWithPayload>;
  /** The logIn mutation can be used to log in an existing user. */
  logIn?: Maybe<LogInPayload>;
  /** The logOut mutation can be used to log out an existing user. */
  logOut?: Maybe<LogOutPayload>;
  /** The resetPassword mutation can be used to reset the password of an existing user. */
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** The sendVerificationEmail mutation can be used to send the verification email again. */
  sendVerificationEmail?: Maybe<SendVerificationEmailPayload>;
  /** The callCloudCode mutation can be used to invoke a cloud code function. */
  callCloudCode?: Maybe<CallCloudCodePayload>;
  /** The createClass mutation can be used to create the schema for a new object class. */
  createClass?: Maybe<CreateClassPayload>;
  /** The updateClass mutation can be used to update the schema for an existing object class. */
  updateClass?: Maybe<UpdateClassPayload>;
  /** The deleteClass mutation can be used to delete an existing object class. */
  deleteClass?: Maybe<DeleteClassPayload>;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeleteSessionArgs = {
  input: DeleteSessionInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateHanziArgs = {
  input: CreateHanziInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdateHanziArgs = {
  input: UpdateHanziInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeleteHanziArgs = {
  input: DeleteHanziInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateListArgs = {
  input: CreateListInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdateListArgs = {
  input: UpdateListInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeleteListArgs = {
  input: DeleteListInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreatePresenceArgs = {
  input: CreatePresenceInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdatePresenceArgs = {
  input: UpdatePresenceInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeletePresenceArgs = {
  input: DeletePresenceInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateSentenceArgs = {
  input: CreateSentenceInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdateSentenceArgs = {
  input: UpdateSentenceInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeleteSentenceArgs = {
  input: DeleteSentenceInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateVocabularyArgs = {
  input: CreateVocabularyInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdateVocabularyArgs = {
  input: UpdateVocabularyInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeleteVocabularyArgs = {
  input: DeleteVocabularyInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateFileArgs = {
  input: CreateFileInput;
};

/** Mutation is the top level type for mutations. */
export type MutationSignUpArgs = {
  input: SignUpInput;
};

/** Mutation is the top level type for mutations. */
export type MutationLogInWithArgs = {
  input: LogInWithInput;
};

/** Mutation is the top level type for mutations. */
export type MutationLogInArgs = {
  input: LogInInput;
};

/** Mutation is the top level type for mutations. */
export type MutationLogOutArgs = {
  input: LogOutInput;
};

/** Mutation is the top level type for mutations. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

/** Mutation is the top level type for mutations. */
export type MutationSendVerificationEmailArgs = {
  input: SendVerificationEmailInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCallCloudCodeArgs = {
  input: CallCloudCodeInput;
};

/** Mutation is the top level type for mutations. */
export type MutationCreateClassArgs = {
  input: CreateClassInput;
};

/** Mutation is the top level type for mutations. */
export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};

/** Mutation is the top level type for mutations. */
export type MutationDeleteClassArgs = {
  input: DeleteClassInput;
};

export type GetCurrentUserQueryVariables = Exact<{[key: string]: never}>;

export type GetCurrentUserQuery = {__typename?: "Query"} & {
  viewer: {__typename?: "Viewer"} & {
    user: {__typename?: "User"} & Pick<User, "id" | "objectId" | "name"> & {
        role?: Maybe<{__typename?: "Role"} & Pick<Role, "name">>;
        avatar?: Maybe<{__typename?: "FileInfo"} & Pick<FileInfo, "url">>;
      };
  };
};

export type HanziFragmentFragment = {__typename?: "Hanzi"} & Pick<
  Hanzi,
  | "id"
  | "objectId"
  | "character"
  | "status"
  | "lastUpdatedAt"
  | "lastApprovedAt"
> & {
    medians?: Maybe<
      Array<
        Maybe<
          | ({__typename?: "Element"} & Pick<Element, "value">)
          | {__typename?: "Role"}
          | {__typename?: "Session"}
          | {__typename?: "User"}
          | {__typename?: "Hanzi"}
          | {__typename?: "List"}
          | {__typename?: "Presence"}
          | {__typename?: "Sentence"}
          | {__typename?: "Vocabulary"}
        >
      >
    >;
    strokes?: Maybe<
      Array<
        Maybe<
          | ({__typename?: "Element"} & Pick<Element, "value">)
          | {__typename?: "Role"}
          | {__typename?: "Session"}
          | {__typename?: "User"}
          | {__typename?: "Hanzi"}
          | {__typename?: "List"}
          | {__typename?: "Presence"}
          | {__typename?: "Sentence"}
          | {__typename?: "Vocabulary"}
        >
      >
    >;
    approvedBy?: Maybe<
      {__typename?: "User"} & Pick<User, "name"> & {
          avatar?: Maybe<{__typename?: "FileInfo"} & Pick<FileInfo, "url">>;
        }
    >;
    lastUpdatedBy?: Maybe<
      {__typename?: "User"} & Pick<User, "name"> & {
          avatar?: Maybe<{__typename?: "FileInfo"} & Pick<FileInfo, "url">>;
        }
    >;
  };

export type GetHanzisQueryVariables = Exact<{
  filter?: Maybe<HanziWhereInput>;
  cursor?: Maybe<Scalars["String"]>;
}>;

export type GetHanzisQuery = {__typename?: "Query"} & {
  viewer: {__typename?: "Viewer"} & {
    user: {__typename?: "User"} & Pick<User, "objectId"> & {
        role?: Maybe<{__typename?: "Role"} & Pick<Role, "name">>;
      };
  };
  hanzis: {__typename?: "HanziConnection"} & Pick<HanziConnection, "count"> & {
      pageInfo: {__typename?: "PageInfo"} & Pick<
        PageInfo,
        "hasNextPage" | "endCursor"
      >;
      edges?: Maybe<
        Array<
          Maybe<
            {__typename?: "HanziEdge"} & {
              node?: Maybe<{__typename?: "Hanzi"} & HanziFragmentFragment>;
            }
          >
        >
      >;
    };
};

export type UpdateHanziMutationVariables = Exact<{
  id: Scalars["ID"];
  fields?: Maybe<UpdateHanziFieldsInput>;
}>;

export type UpdateHanziMutation = {__typename?: "Mutation"} & {
  updateHanzi?: Maybe<
    {__typename?: "UpdateHanziPayload"} & Pick<
      UpdateHanziPayload,
      "clientMutationId"
    > & {hanzi: {__typename?: "Hanzi"} & HanziFragmentFragment}
  >;
};

export type CreateHanziMutationVariables = Exact<{
  fields: CreateHanziFieldsInput;
}>;

export type CreateHanziMutation = {__typename?: "Mutation"} & {
  createHanzi?: Maybe<
    {__typename?: "CreateHanziPayload"} & Pick<
      CreateHanziPayload,
      "clientMutationId"
    > & {hanzi: {__typename?: "Hanzi"} & HanziFragmentFragment}
  >;
};

export type SentenceFragmentFragment = {__typename?: "Sentence"} & Pick<
  Sentence,
  | "id"
  | "objectId"
  | "english"
  | "audio"
  | "status"
  | "lastUpdatedAt"
  | "lastApprovedAt"
> & {
    words?: Maybe<
      Array<
        Maybe<
          | ({__typename?: "Element"} & Pick<Element, "value">)
          | {__typename?: "Role"}
          | {__typename?: "Session"}
          | {__typename?: "User"}
          | {__typename?: "Hanzi"}
          | {__typename?: "List"}
          | {__typename?: "Presence"}
          | {__typename?: "Sentence"}
          | {__typename?: "Vocabulary"}
        >
      >
    >;
    vocabularies: {__typename?: "VocabularyConnection"} & {
      edges?: Maybe<
        Array<
          Maybe<
            {__typename?: "VocabularyEdge"} & {
              node?: Maybe<
                {__typename?: "Vocabulary"} & Pick<
                  Vocabulary,
                  "hanzi" | "objectId" | "english" | "pinyin"
                > & {
                    lists?: Maybe<
                      Array<
                        Maybe<
                          | {__typename?: "Element"}
                          | {__typename?: "Role"}
                          | {__typename?: "Session"}
                          | {__typename?: "User"}
                          | {__typename?: "Hanzi"}
                          | ({__typename?: "List"} & Pick<
                              List,
                              "objectId" | "name"
                            >)
                          | {__typename?: "Presence"}
                          | {__typename?: "Sentence"}
                          | {__typename?: "Vocabulary"}
                        >
                      >
                    >;
                  }
              >;
            }
          >
        >
      >;
    };
    approvedBy?: Maybe<
      {__typename?: "User"} & Pick<User, "name"> & {
          avatar?: Maybe<{__typename?: "FileInfo"} & Pick<FileInfo, "url">>;
        }
    >;
    lastUpdatedBy?: Maybe<
      {__typename?: "User"} & Pick<User, "name"> & {
          avatar?: Maybe<{__typename?: "FileInfo"} & Pick<FileInfo, "url">>;
        }
    >;
  };

export type GetSentencesQueryVariables = Exact<{
  filter?: Maybe<SentenceWhereInput>;
  cursor?: Maybe<Scalars["String"]>;
}>;

export type GetSentencesQuery = {__typename?: "Query"} & {
  sentences: {__typename?: "SentenceConnection"} & Pick<
    SentenceConnection,
    "count"
  > & {
      pageInfo: {__typename?: "PageInfo"} & Pick<
        PageInfo,
        "hasNextPage" | "endCursor"
      >;
      edges?: Maybe<
        Array<
          Maybe<
            {__typename?: "SentenceEdge"} & {
              node?: Maybe<
                {__typename?: "Sentence"} & SentenceFragmentFragment
              >;
            }
          >
        >
      >;
    };
};

export type UpdateSentenceMutationVariables = Exact<{
  id: Scalars["ID"];
  fields?: Maybe<UpdateSentenceFieldsInput>;
}>;

export type UpdateSentenceMutation = {__typename?: "Mutation"} & {
  updateSentence?: Maybe<
    {__typename?: "UpdateSentencePayload"} & Pick<
      UpdateSentencePayload,
      "clientMutationId"
    > & {sentence: {__typename?: "Sentence"} & SentenceFragmentFragment}
  >;
};

export type CreateSentenceMutationVariables = Exact<{
  fields: CreateSentenceFieldsInput;
}>;

export type CreateSentenceMutation = {__typename?: "Mutation"} & {
  createSentence?: Maybe<
    {__typename?: "CreateSentencePayload"} & Pick<
      CreateSentencePayload,
      "clientMutationId"
    > & {sentence: {__typename?: "Sentence"} & SentenceFragmentFragment}
  >;
};

export type VocabularyFragmentFragment = {__typename?: "Vocabulary"} & Pick<
  Vocabulary,
  | "id"
  | "objectId"
  | "hanzi"
  | "pinyin"
  | "english"
  | "audio"
  | "instagram"
  | "frequency"
  | "status"
  | "lastUpdatedAt"
  | "lastApprovedAt"
> & {
    hanzis?: Maybe<
      Array<
        Maybe<
          | {__typename?: "Element"}
          | {__typename?: "Role"}
          | {__typename?: "Session"}
          | {__typename?: "User"}
          | ({__typename?: "Hanzi"} & Pick<Hanzi, "objectId" | "character"> & {
                medians?: Maybe<
                  Array<
                    Maybe<
                      | ({__typename?: "Element"} & Pick<Element, "value">)
                      | {__typename?: "Role"}
                      | {__typename?: "Session"}
                      | {__typename?: "User"}
                      | {__typename?: "Hanzi"}
                      | {__typename?: "List"}
                      | {__typename?: "Presence"}
                      | {__typename?: "Sentence"}
                      | {__typename?: "Vocabulary"}
                    >
                  >
                >;
                strokes?: Maybe<
                  Array<
                    Maybe<
                      | ({__typename?: "Element"} & Pick<Element, "value">)
                      | {__typename?: "Role"}
                      | {__typename?: "Session"}
                      | {__typename?: "User"}
                      | {__typename?: "Hanzi"}
                      | {__typename?: "List"}
                      | {__typename?: "Presence"}
                      | {__typename?: "Sentence"}
                      | {__typename?: "Vocabulary"}
                    >
                  >
                >;
              })
          | {__typename?: "List"}
          | {__typename?: "Presence"}
          | {__typename?: "Sentence"}
          | {__typename?: "Vocabulary"}
        >
      >
    >;
    speechPart?: Maybe<
      Array<
        Maybe<
          | ({__typename?: "Element"} & Pick<Element, "value">)
          | {__typename?: "Role"}
          | {__typename?: "Session"}
          | {__typename?: "User"}
          | {__typename?: "Hanzi"}
          | {__typename?: "List"}
          | {__typename?: "Presence"}
          | {__typename?: "Sentence"}
          | {__typename?: "Vocabulary"}
        >
      >
    >;
    lists?: Maybe<
      Array<
        Maybe<
          | {__typename?: "Element"}
          | {__typename?: "Role"}
          | {__typename?: "Session"}
          | {__typename?: "User"}
          | {__typename?: "Hanzi"}
          | ({__typename?: "List"} & Pick<
              List,
              "objectId" | "name" | "list" | "sublist"
            >)
          | {__typename?: "Presence"}
          | {__typename?: "Sentence"}
          | {__typename?: "Vocabulary"}
        >
      >
    >;
    sentences?: Maybe<
      Array<
        Maybe<
          | {__typename?: "Element"}
          | {__typename?: "Role"}
          | {__typename?: "Session"}
          | {__typename?: "User"}
          | {__typename?: "Hanzi"}
          | {__typename?: "List"}
          | {__typename?: "Presence"}
          | ({__typename?: "Sentence"} & Pick<
              Sentence,
              "english" | "objectId"
            > & {
                words?: Maybe<
                  Array<
                    Maybe<
                      | ({__typename?: "Element"} & Pick<Element, "value">)
                      | {__typename?: "Role"}
                      | {__typename?: "Session"}
                      | {__typename?: "User"}
                      | {__typename?: "Hanzi"}
                      | {__typename?: "List"}
                      | {__typename?: "Presence"}
                      | {__typename?: "Sentence"}
                      | {__typename?: "Vocabulary"}
                    >
                  >
                >;
              })
          | {__typename?: "Vocabulary"}
        >
      >
    >;
    approvedBy?: Maybe<
      {__typename?: "User"} & Pick<User, "name"> & {
          avatar?: Maybe<{__typename?: "FileInfo"} & Pick<FileInfo, "url">>;
        }
    >;
    lastUpdatedBy?: Maybe<
      {__typename?: "User"} & Pick<User, "name"> & {
          avatar?: Maybe<{__typename?: "FileInfo"} & Pick<FileInfo, "url">>;
        }
    >;
  };

export type GetVocabulariesQueryVariables = Exact<{
  filter?: Maybe<VocabularyWhereInput>;
  cursor?: Maybe<Scalars["String"]>;
}>;

export type GetVocabulariesQuery = {__typename?: "Query"} & {
  vocabularies: {__typename?: "VocabularyConnection"} & Pick<
    VocabularyConnection,
    "count"
  > & {
      pageInfo: {__typename?: "PageInfo"} & Pick<
        PageInfo,
        "hasNextPage" | "endCursor"
      >;
      edges?: Maybe<
        Array<
          Maybe<
            {__typename?: "VocabularyEdge"} & {
              node?: Maybe<
                {__typename?: "Vocabulary"} & VocabularyFragmentFragment
              >;
            }
          >
        >
      >;
    };
};

export type UpdateVocabularyMutationVariables = Exact<{
  id: Scalars["ID"];
  fields?: Maybe<UpdateVocabularyFieldsInput>;
}>;

export type UpdateVocabularyMutation = {__typename?: "Mutation"} & {
  updateVocabulary?: Maybe<
    {__typename?: "UpdateVocabularyPayload"} & Pick<
      UpdateVocabularyPayload,
      "clientMutationId"
    > & {vocabulary: {__typename?: "Vocabulary"} & VocabularyFragmentFragment}
  >;
};

export type CreateVocabularyMutationVariables = Exact<{
  fields: CreateVocabularyFieldsInput;
}>;

export type CreateVocabularyMutation = {__typename?: "Mutation"} & {
  createVocabulary?: Maybe<
    {__typename?: "CreateVocabularyPayload"} & Pick<
      CreateVocabularyPayload,
      "clientMutationId"
    > & {vocabulary: {__typename?: "Vocabulary"} & VocabularyFragmentFragment}
  >;
};

export type GetPageInfoQueryVariables = Exact<{[key: string]: never}>;

export type GetPageInfoQuery = {__typename?: "Query"} & {
  viewer: {__typename?: "Viewer"} & {
    user: {__typename?: "User"} & Pick<User, "objectId"> & {
        role?: Maybe<{__typename?: "Role"} & Pick<Role, "name">>;
      };
  };
  lists: {__typename?: "ListConnection"} & {
    edges?: Maybe<
      Array<
        Maybe<
          {__typename?: "ListEdge"} & {
            node?: Maybe<
              {__typename?: "List"} & Pick<
                List,
                "objectId" | "name" | "list" | "sublist"
              >
            >;
          }
        >
      >
    >;
  };
};

export const HanziFragmentFragmentDoc = gql`
  fragment hanziFragment on Hanzi {
    id
    objectId
    character
    medians {
      ... on Element {
        value
      }
    }
    strokes {
      ... on Element {
        value
      }
    }
    status
    lastUpdatedAt
    lastApprovedAt
    approvedBy {
      name
      avatar {
        url
      }
    }
    lastUpdatedBy {
      name
      avatar {
        url
      }
    }
  }
`;
export const SentenceFragmentFragmentDoc = gql`
  fragment sentenceFragment on Sentence {
    id
    objectId
    english
    audio
    status
    words {
      ... on Element {
        value
      }
    }
    vocabularies {
      edges {
        node {
          hanzi
          objectId
          english
          pinyin
          lists {
            ... on List {
              objectId
              name
            }
          }
        }
      }
    }
    lastUpdatedAt
    lastApprovedAt
    approvedBy {
      name
      avatar {
        url
      }
    }
    lastUpdatedBy {
      name
      avatar {
        url
      }
    }
  }
`;
export const VocabularyFragmentFragmentDoc = gql`
  fragment vocabularyFragment on Vocabulary {
    id
    objectId
    hanzi
    pinyin
    english
    audio
    instagram
    frequency
    hanzis {
      ... on Hanzi {
        objectId
        character
        medians {
          ... on Element {
            value
          }
        }
        strokes {
          ... on Element {
            value
          }
        }
      }
    }
    speechPart {
      ... on Element {
        value
      }
    }
    lists {
      ... on List {
        objectId
        name
        list
        sublist
      }
    }
    sentences {
      ... on Sentence {
        english
        words {
          ... on Element {
            value
          }
        }
        objectId
      }
    }
    status
    lastUpdatedAt
    lastApprovedAt
    approvedBy {
      name
      avatar {
        url
      }
    }
    lastUpdatedBy {
      name
      avatar {
        url
      }
    }
  }
`;
export const GetCurrentUserDocument = gql`
  query getCurrentUser {
    viewer {
      user {
        id
        objectId
        name
        role {
          name
        }
        avatar {
          url
        }
      }
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    baseOptions,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    baseOptions,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetHanzisDocument = gql`
  query getHanzis($filter: HanziWhereInput, $cursor: String) {
    viewer {
      user {
        objectId
        role {
          name
        }
      }
    }
    hanzis(where: $filter, after: $cursor) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...hanziFragment
        }
      }
    }
  }
  ${HanziFragmentFragmentDoc}
`;

/**
 * __useGetHanzisQuery__
 *
 * To run a query within a React component, call `useGetHanzisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHanzisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHanzisQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetHanzisQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHanzisQuery,
    GetHanzisQueryVariables
  >,
) {
  return Apollo.useQuery<GetHanzisQuery, GetHanzisQueryVariables>(
    GetHanzisDocument,
    baseOptions,
  );
}
export function useGetHanzisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHanzisQuery,
    GetHanzisQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetHanzisQuery, GetHanzisQueryVariables>(
    GetHanzisDocument,
    baseOptions,
  );
}
export type GetHanzisQueryHookResult = ReturnType<typeof useGetHanzisQuery>;
export type GetHanzisLazyQueryHookResult = ReturnType<
  typeof useGetHanzisLazyQuery
>;
export type GetHanzisQueryResult = Apollo.QueryResult<
  GetHanzisQuery,
  GetHanzisQueryVariables
>;
export const UpdateHanziDocument = gql`
  mutation updateHanzi($id: ID!, $fields: UpdateHanziFieldsInput) {
    updateHanzi(input: {id: $id, fields: $fields}) {
      clientMutationId
      hanzi {
        ...hanziFragment
      }
    }
  }
  ${HanziFragmentFragmentDoc}
`;
export type UpdateHanziMutationFn = Apollo.MutationFunction<
  UpdateHanziMutation,
  UpdateHanziMutationVariables
>;

/**
 * __useUpdateHanziMutation__
 *
 * To run a mutation, you first call `useUpdateHanziMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHanziMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHanziMutation, { data, loading, error }] = useUpdateHanziMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateHanziMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHanziMutation,
    UpdateHanziMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateHanziMutation, UpdateHanziMutationVariables>(
    UpdateHanziDocument,
    baseOptions,
  );
}
export type UpdateHanziMutationHookResult = ReturnType<
  typeof useUpdateHanziMutation
>;
export type UpdateHanziMutationResult = Apollo.MutationResult<UpdateHanziMutation>;
export type UpdateHanziMutationOptions = Apollo.BaseMutationOptions<
  UpdateHanziMutation,
  UpdateHanziMutationVariables
>;
export const CreateHanziDocument = gql`
  mutation CreateHanzi($fields: CreateHanziFieldsInput!) {
    createHanzi(input: {fields: $fields}) {
      clientMutationId
      hanzi {
        ...hanziFragment
      }
    }
  }
  ${HanziFragmentFragmentDoc}
`;
export type CreateHanziMutationFn = Apollo.MutationFunction<
  CreateHanziMutation,
  CreateHanziMutationVariables
>;

/**
 * __useCreateHanziMutation__
 *
 * To run a mutation, you first call `useCreateHanziMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHanziMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHanziMutation, { data, loading, error }] = useCreateHanziMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateHanziMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHanziMutation,
    CreateHanziMutationVariables
  >,
) {
  return Apollo.useMutation<CreateHanziMutation, CreateHanziMutationVariables>(
    CreateHanziDocument,
    baseOptions,
  );
}
export type CreateHanziMutationHookResult = ReturnType<
  typeof useCreateHanziMutation
>;
export type CreateHanziMutationResult = Apollo.MutationResult<CreateHanziMutation>;
export type CreateHanziMutationOptions = Apollo.BaseMutationOptions<
  CreateHanziMutation,
  CreateHanziMutationVariables
>;
export const GetSentencesDocument = gql`
  query getSentences($filter: SentenceWhereInput, $cursor: String) {
    sentences(where: $filter, after: $cursor) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...sentenceFragment
        }
      }
    }
  }
  ${SentenceFragmentFragmentDoc}
`;

/**
 * __useGetSentencesQuery__
 *
 * To run a query within a React component, call `useGetSentencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSentencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSentencesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetSentencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSentencesQuery,
    GetSentencesQueryVariables
  >,
) {
  return Apollo.useQuery<GetSentencesQuery, GetSentencesQueryVariables>(
    GetSentencesDocument,
    baseOptions,
  );
}
export function useGetSentencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSentencesQuery,
    GetSentencesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetSentencesQuery, GetSentencesQueryVariables>(
    GetSentencesDocument,
    baseOptions,
  );
}
export type GetSentencesQueryHookResult = ReturnType<
  typeof useGetSentencesQuery
>;
export type GetSentencesLazyQueryHookResult = ReturnType<
  typeof useGetSentencesLazyQuery
>;
export type GetSentencesQueryResult = Apollo.QueryResult<
  GetSentencesQuery,
  GetSentencesQueryVariables
>;
export const UpdateSentenceDocument = gql`
  mutation updateSentence($id: ID!, $fields: UpdateSentenceFieldsInput) {
    updateSentence(input: {id: $id, fields: $fields}) {
      clientMutationId
      sentence {
        ...sentenceFragment
      }
    }
  }
  ${SentenceFragmentFragmentDoc}
`;
export type UpdateSentenceMutationFn = Apollo.MutationFunction<
  UpdateSentenceMutation,
  UpdateSentenceMutationVariables
>;

/**
 * __useUpdateSentenceMutation__
 *
 * To run a mutation, you first call `useUpdateSentenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSentenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSentenceMutation, { data, loading, error }] = useUpdateSentenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateSentenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSentenceMutation,
    UpdateSentenceMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateSentenceMutation,
    UpdateSentenceMutationVariables
  >(UpdateSentenceDocument, baseOptions);
}
export type UpdateSentenceMutationHookResult = ReturnType<
  typeof useUpdateSentenceMutation
>;
export type UpdateSentenceMutationResult = Apollo.MutationResult<UpdateSentenceMutation>;
export type UpdateSentenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateSentenceMutation,
  UpdateSentenceMutationVariables
>;
export const CreateSentenceDocument = gql`
  mutation CreateSentence($fields: CreateSentenceFieldsInput!) {
    createSentence(input: {fields: $fields}) {
      clientMutationId
      sentence {
        ...sentenceFragment
      }
    }
  }
  ${SentenceFragmentFragmentDoc}
`;
export type CreateSentenceMutationFn = Apollo.MutationFunction<
  CreateSentenceMutation,
  CreateSentenceMutationVariables
>;

/**
 * __useCreateSentenceMutation__
 *
 * To run a mutation, you first call `useCreateSentenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSentenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSentenceMutation, { data, loading, error }] = useCreateSentenceMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateSentenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSentenceMutation,
    CreateSentenceMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateSentenceMutation,
    CreateSentenceMutationVariables
  >(CreateSentenceDocument, baseOptions);
}
export type CreateSentenceMutationHookResult = ReturnType<
  typeof useCreateSentenceMutation
>;
export type CreateSentenceMutationResult = Apollo.MutationResult<CreateSentenceMutation>;
export type CreateSentenceMutationOptions = Apollo.BaseMutationOptions<
  CreateSentenceMutation,
  CreateSentenceMutationVariables
>;
export const GetVocabulariesDocument = gql`
  query getVocabularies($filter: VocabularyWhereInput, $cursor: String) {
    vocabularies(where: $filter, after: $cursor) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...vocabularyFragment
        }
      }
    }
  }
  ${VocabularyFragmentFragmentDoc}
`;

/**
 * __useGetVocabulariesQuery__
 *
 * To run a query within a React component, call `useGetVocabulariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVocabulariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVocabulariesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetVocabulariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVocabulariesQuery,
    GetVocabulariesQueryVariables
  >,
) {
  return Apollo.useQuery<GetVocabulariesQuery, GetVocabulariesQueryVariables>(
    GetVocabulariesDocument,
    baseOptions,
  );
}
export function useGetVocabulariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVocabulariesQuery,
    GetVocabulariesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetVocabulariesQuery,
    GetVocabulariesQueryVariables
  >(GetVocabulariesDocument, baseOptions);
}
export type GetVocabulariesQueryHookResult = ReturnType<
  typeof useGetVocabulariesQuery
>;
export type GetVocabulariesLazyQueryHookResult = ReturnType<
  typeof useGetVocabulariesLazyQuery
>;
export type GetVocabulariesQueryResult = Apollo.QueryResult<
  GetVocabulariesQuery,
  GetVocabulariesQueryVariables
>;
export const UpdateVocabularyDocument = gql`
  mutation updateVocabulary($id: ID!, $fields: UpdateVocabularyFieldsInput) {
    updateVocabulary(input: {id: $id, fields: $fields}) {
      clientMutationId
      vocabulary {
        ...vocabularyFragment
      }
    }
  }
  ${VocabularyFragmentFragmentDoc}
`;
export type UpdateVocabularyMutationFn = Apollo.MutationFunction<
  UpdateVocabularyMutation,
  UpdateVocabularyMutationVariables
>;

/**
 * __useUpdateVocabularyMutation__
 *
 * To run a mutation, you first call `useUpdateVocabularyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVocabularyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVocabularyMutation, { data, loading, error }] = useUpdateVocabularyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateVocabularyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVocabularyMutation,
    UpdateVocabularyMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateVocabularyMutation,
    UpdateVocabularyMutationVariables
  >(UpdateVocabularyDocument, baseOptions);
}
export type UpdateVocabularyMutationHookResult = ReturnType<
  typeof useUpdateVocabularyMutation
>;
export type UpdateVocabularyMutationResult = Apollo.MutationResult<UpdateVocabularyMutation>;
export type UpdateVocabularyMutationOptions = Apollo.BaseMutationOptions<
  UpdateVocabularyMutation,
  UpdateVocabularyMutationVariables
>;
export const CreateVocabularyDocument = gql`
  mutation CreateVocabulary($fields: CreateVocabularyFieldsInput!) {
    createVocabulary(input: {fields: $fields}) {
      clientMutationId
      vocabulary {
        ...vocabularyFragment
      }
    }
  }
  ${VocabularyFragmentFragmentDoc}
`;
export type CreateVocabularyMutationFn = Apollo.MutationFunction<
  CreateVocabularyMutation,
  CreateVocabularyMutationVariables
>;

/**
 * __useCreateVocabularyMutation__
 *
 * To run a mutation, you first call `useCreateVocabularyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVocabularyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVocabularyMutation, { data, loading, error }] = useCreateVocabularyMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateVocabularyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVocabularyMutation,
    CreateVocabularyMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateVocabularyMutation,
    CreateVocabularyMutationVariables
  >(CreateVocabularyDocument, baseOptions);
}
export type CreateVocabularyMutationHookResult = ReturnType<
  typeof useCreateVocabularyMutation
>;
export type CreateVocabularyMutationResult = Apollo.MutationResult<CreateVocabularyMutation>;
export type CreateVocabularyMutationOptions = Apollo.BaseMutationOptions<
  CreateVocabularyMutation,
  CreateVocabularyMutationVariables
>;
export const GetPageInfoDocument = gql`
  query getPageInfo {
    viewer {
      user {
        objectId
        role {
          name
        }
      }
    }
    lists {
      edges {
        node {
          objectId
          name
          list
          sublist
        }
      }
    }
  }
`;

/**
 * __useGetPageInfoQuery__
 *
 * To run a query within a React component, call `useGetPageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPageInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPageInfoQuery,
    GetPageInfoQueryVariables
  >,
) {
  return Apollo.useQuery<GetPageInfoQuery, GetPageInfoQueryVariables>(
    GetPageInfoDocument,
    baseOptions,
  );
}
export function useGetPageInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPageInfoQuery,
    GetPageInfoQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetPageInfoQuery, GetPageInfoQueryVariables>(
    GetPageInfoDocument,
    baseOptions,
  );
}
export type GetPageInfoQueryHookResult = ReturnType<typeof useGetPageInfoQuery>;
export type GetPageInfoLazyQueryHookResult = ReturnType<
  typeof useGetPageInfoLazyQuery
>;
export type GetPageInfoQueryResult = Apollo.QueryResult<
  GetPageInfoQuery,
  GetPageInfoQueryVariables
>;
