import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type Sentence = {
  id: string;
  hanzi: string[];
  pinyin: string[];
  english: string;
};

type State = {
  videoUrls: string[];
  vocab: {
    objectId: string;
    hanzi: string;
    lists: any;
    pinyin: string;
    english: string;
    audio: string;
    speechPart: string[];
    sentences: Sentence[];
    instagram: boolean;
    strokes: {
      strokes: string[];
      medians: [number, number][][];
    }[];
  } | null;
};

const initialState: State = {
  vocab: null,
  videoUrls: [],
};

/**
 * Reducers
 */
const instagramSlice = createSlice({
  name: "instagram",
  initialState,
  reducers: {
    addVideoUrl(state, action: PayloadAction<string>) {
      state.videoUrls = [...state.videoUrls, action.payload];
    },
    resetVideoUrls(state) {
      state.videoUrls = [];
    },
    publish(state, action: PayloadAction<boolean>) {
      state.vocab!.instagram = action.payload;
    },
    setVocabulary(state, action: PayloadAction<any>) {
      const {
        objectId,
        audio,
        hanzi,
        pinyin,
        english,
        lists,
        hanzis,
        speechPart,
        sentences,
        instagram,
      } = action.payload;

      const listsFormatted = lists.reduce((acc: any, list: any) => {
        const listId = list.list;
        const sublist = list.sublist;
        if (!acc[listId]) {
          acc[listId] = [];
        }
        acc[listId] = [...acc[listId], sublist];
        return acc;
      }, {} as any);

      state.vocab = {
        objectId,
        hanzi,
        pinyin,
        audio,
        english,
        speechPart,
        sentences,
        instagram: instagram || false,
        lists: listsFormatted,
        strokes: hanzis.map((hanzi: any) => ({
          strokes: hanzi.strokes,
          medians: hanzi.medians,
        })),
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  setVocabulary,
  addVideoUrl,
  resetVideoUrls,
  publish: publishInstagram,
} = instagramSlice.actions;

/**
 * Thunks
 */

export default instagramSlice.reducer;
