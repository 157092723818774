import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import AudioButton from "components/AudioButton";
import {FormInputs} from "..";
import ProgressBar from "components/ProgressBar";

interface Props {
  className?: string;
  data: FormInputs;
  lists: any;
}

const SentencePreview: FC<Props> = ({className, data, lists}) => {
  const words = data?.words || [];
  const vocabs = words.filter((word) => word.vocab);

  const stats = vocabs.reduce((acc, vocab) => {
    const lists = vocab.vocab.lists;

    if (lists.length === 0) {
      if (!acc.others) {
        acc.others = 1;
      } else {
        acc.others += 1;
      }
    }
    lists.forEach((list: any) => {
      const id = list.objectId;
      if (!acc[id]) {
        acc[id] = 1;
      } else {
        acc[id] += 1;
      }
    });
    return acc;
  }, {} as any);

  const pinyinString =
    words
      .map((word) => {
        return word.vocab
          ? word?.pinyins?.map((pinyin) => pinyin.label).join(" ") || ""
          : word.label;
      })
      .join(" ") || "";

  const hanziString = words.map((word) => word.label).join(" ") || "";

  const englishString = data?.english || "";
  console.log(lists);

  return (
    <div className={concatStyles([styles.container, className])}>
      <div className={styles.sentenceContainer}>
        <AudioButton className={styles.audioButton} url={data.audio} />
        <div className={styles.sentence}>
          <span className={styles.pinyin}>{pinyinString}</span>
          <span className={styles.hanzi}>{hanziString}</span>
          <span className={styles.english}>{englishString}</span>
        </div>
      </div>
      <div className={styles.statsContainer}>
        <div>
          {Object.entries(stats).map(([key, value]: any) => (
            <div className={styles.progressRow} key={key}>
              <ProgressBar
                className={styles.progressBar}
                progress={value / vocabs.length}
              />
              <span className={styles.statsValue}>
                {Math.round((value / vocabs.length) * 100)}%
              </span>
              <span className={styles.statsList}>
                {" "}
                · {lists[key]?.name || "Others"}
              </span>
            </div>
          ))}
        </div>
        <div className={styles.statsCount}>
          <span className={styles.vocabCount}>{vocabs.length} Words</span>
        </div>
      </div>
    </div>
  );
};

export default SentencePreview;
