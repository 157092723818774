import React, {FC, useEffect, useState} from "react";
import {v4 as uuid} from "uuid";
import {ActionMeta} from "react-select";
import Parse from "parse";
import {Controller, useForm, useWatch} from "react-hook-form";

import styles from "./index.module.scss";
import BrowserPanelHeader from "components/Browser/BrowserPanelHeader";
import {UserPresence} from "state/presence";
import FormRow from "components/FormRow";
import {TextInput} from "components/Input";
import SelectInput from "components/Input/SelectInput";
import getKeyValue from "helpers/getKeyValue";
import pinyins from "helpers/pinyin";
import SentencePreview from "./SentencePreview";
import concatStyles from "helpers/concatStyles";
import UploadInput from "components/Input/UploadInput";

const DEFAULT_OPTIONS = [
  {
    value: uuid(),
    label: "。",
    vocab: null,
    pinyins: null,
  },
  {
    value: uuid(),
    label: "!",
    vocab: null,
    pinyins: null,
  },
  {
    value: uuid(),
    label: ",",
    vocab: null,
    pinyins: null,
  },
  {
    value: uuid(),
    label: ":",
    vocab: null,
    pinyins: null,
  },
  {
    value: uuid(),
    label: "?",
    vocab: null,
    pinyins: null,
  },
  {
    value: uuid(),
    label: "A",
    vocab: null,
    pinyins: null,
  },
  {
    value: uuid(),
    label: "B",
    vocab: null,
    pinyins: null,
  },
];

type Word = {
  value: string;
  label: string;
  vocab: any;
  pinyins: {value: string; label: string}[] | null;
};

export type FormInputs = {
  english: string;
  words: Word[];
  audio: string;
};

interface Props {
  data: any;
  role: string | null;
  loading?: boolean;
  isCreateMode?: boolean;
  presences: UserPresence[];
  lists: {
    objectId: string;
    name: string;
    list: string;
    sublist: string;
  }[];
  onUpdate: (form: FormInputs) => void;
  onSave: (form: FormInputs) => void;
  onApproval: (approved: boolean) => void;
  onUploadAudio: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Panel: FC<Props> = ({
  data,
  role,
  loading = false,
  isCreateMode = false,
  presences,
  lists,
  onSave,
  onUpdate,
  onApproval,
  onUploadAudio,
}) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState,
    errors,
  } = useForm<FormInputs>();
  const [selectedWord, setSelectedWord] = useState<string | null>(null);

  const formData = useWatch({
    control,
  });

  useEffect(() => {
    reset({
      words:
        data?.words?.map((word: any) => ({
          value: uuid(),
          label: word.hanzi,
          pinyins:
            word?.pinyins?.map((pinyin: string) => ({
              value: uuid(),
              label: pinyin,
            })) || [],
          vocab: word.vocabulary,
        })) || [],
      english: data?.english || "",
      audio:
        data?.audio && data?.audio !== ""
          ? data.audio + `?v=${+new Date()}`
          : "",
    });
  }, [data, reset]);

  const onSubmitHandler = (form: FormInputs) => {
    if (isCreateMode) {
      onSave(form);
    } else {
      onUpdate(form);
    }
  };

  const onLoadOptionsHandler = async (value: string, callback: any) => {
    if (!value || value === "") return callback(undefined);

    if (DEFAULT_OPTIONS.map((opt) => opt.label).includes(value)) {
      callback(DEFAULT_OPTIONS.filter((opt) => opt.label === value));
    }

    const query = new Parse.Query("Vocabulary")
      .equalTo("hanzi", value)
      .include("lists");
    const vocabs = await query.find();

    if (!vocabs) return callback(undefined);

    const options = vocabs.map((vocab) => {
      const pinyins = vocab
        .get("pinyin")
        .split(" ")
        .map((pinyin: string) => ({value: uuid(), label: pinyin}));

      const vocabFormatted = {
        objectId: vocab.id,
        pinyin: vocab.get("pinyin").split(" "),
        hanzi: vocab.get("hanzi"),
        english: vocab.get("english"),
        lists: vocab.get("lists").map((list: any) => ({
          objectId: list.id,
          name: list.get("name"),
        })),
      };

      return {
        value: uuid(),
        label: vocab.get("hanzi"),
        pinyins,
        vocab: vocabFormatted,
      };
    });

    return callback(options);
  };

  const onMultiSelectChange = (onChange: any, value: any) => (
    _: any,
    option: ActionMeta<any>,
  ) => {
    if (option.action === "select-option") {
      const options = [...value, {...option.option, value: uuid()}];
      onChange(options);
    } else if (
      option.action === "remove-value" ||
      option.action === "pop-value"
    ) {
      const options = value.filter(
        (opt: any) => opt.value !== option.removedValue.value,
      );
      onChange(options);
    }
  };

  const onSelectWordHandler = (value: any) => {
    setSelectedWord(value.value);
  };

  const onLoadPinyinOptionsHandler = async (value: string, callback: any) => {
    const pinyin = (getKeyValue(pinyins, value as any) as string[]) || [];
    callback(pinyin.map((item: string) => ({value: uuid(), label: item})));
  };

  const onPinyinChangeHandler = (onChange: any, originalWords: Word[]) => (
    pinyins: any[],
  ) => {
    const newWords = originalWords.map((word) => {
      if (selectedWord !== word.value) return word;
      return {
        ...word,
        pinyins,
      };
    });

    onChange(newWords);
  };

  const activePresences =
    (data && presences.filter((presence) => presence.item === data.objectId)) ||
    [];

  const selectedWordData =
    formData?.words?.filter((word: any) => word.value === selectedWord)[0] ||
    undefined;

  const listsMap = lists.reduce((acc, list) => {
    acc[list.objectId] = list;
    return acc;
  }, {} as any);

  if (!data && !isCreateMode) {
    return <div className={styles.container}></div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <BrowserPanelHeader
        data={data}
        role={role}
        presences={activePresences}
        onApproval={onApproval}
        loading={loading}
        buttonDisabled={formState.isDirty}
        isCreateMode={isCreateMode}
      />
      <SentencePreview
        className={styles.sentencePreview}
        lists={listsMap}
        data={formData as any}
      />
      <div className={styles.wordEditor}>
        <Controller
          defaultValue={""}
          control={control}
          name="words"
          render={({onChange, value}) => (
            <>
              <SelectInput
                className={styles.wordEditorSelect}
                async
                isMulti
                placeholder="Type..."
                hideSelector
                hideSelectedOptions={false}
                value={value}
                defaultOptions={DEFAULT_OPTIONS}
                loadOptions={onLoadOptionsHandler}
                onChange={(val, option) => {
                  setSelectedWord(null);
                  onMultiSelectChange(onChange, value)(val, option);
                }}
                onSort={onChange}
                onTagClick={onSelectWordHandler}
              />
              <div className={styles.wordEditorContent}>
                {selectedWordData && selectedWord ? (
                  <div>
                    {selectedWordData.vocab ? (
                      <>
                        <FormRow label="Pinyin">
                          <SelectInput
                            async
                            isMulti
                            placeholder="Type..."
                            hideSelector
                            hideSelectedOptions={false}
                            value={selectedWordData.pinyins}
                            loadOptions={onLoadPinyinOptionsHandler}
                            onChange={onMultiSelectChange(
                              onPinyinChangeHandler(onChange, value),
                              selectedWordData.pinyins,
                            )}
                            onSort={onPinyinChangeHandler(onChange, value)}
                          />
                        </FormRow>
                        <FormRow label="English">
                          <span className={styles.selectRowBody}>
                            {selectedWordData.vocab.english}{" "}
                          </span>
                        </FormRow>
                        <FormRow label="Lists">
                          <span className={styles.selectRowBody}>
                            {selectedWordData.vocab.lists
                              .map((list: any) => list.name)
                              .join(", ")}
                          </span>
                        </FormRow>
                      </>
                    ) : (
                      <>
                        <FormRow label="Value">
                          {selectedWordData.label}
                        </FormRow>
                      </>
                    )}
                  </div>
                ) : (
                  <span className={styles.selectWordLabel}>
                    Please select a word
                  </span>
                )}
              </div>
            </>
          )}
        />
      </div>
      <FormRow label="Audio">
        <Controller
          defaultValue={""}
          control={control}
          name="audio"
          render={({onChange, value}) => (
            <div className={styles.audioRow}>
              <TextInput
                className={concatStyles([styles.input, styles.audioInput])}
                name="audio"
                placeholder="Audio"
                value={value}
                onChange={onChange}
              />
              <UploadInput
                icon="audioFile"
                onChange={(event) => {
                  onChange(URL.createObjectURL(event.target!.files![0]));
                  onUploadAudio(event);
                }}
              />
            </div>
          )}
        />
      </FormRow>
      <FormRow label="English">
        <TextInput
          className={styles.input}
          name="english"
          defaultValue=""
          placeholder="English"
          error={errors.english?.message}
          ref={register({})}
        />
      </FormRow>
    </form>
  );
};

export default Panel;
