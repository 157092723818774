import React, {FC} from "react";

import styles from "./index.module.scss";
import Navbar from "components/Navbar";

const Layout: FC = ({children}) => {
  return (
    <>
      <Navbar />
      <div className={styles.content}>{children}</div>
    </>
  );
};

export default Layout;
