const speechParts: {[key: string]: string} = {
  n: "Noun",
  v: "Verb",
  m: "Measure",
  int: "Interjection",
  adj: "Adjective",
  pron: "Pronoun",
  adv: "Adverb",
  conj: "Conjunction",
  prep: "Preposition",
  part: "Particle",
  num: "Numberal",
  onom: "Onomatopoeia",
  aux: "Auxiliary",
  "-": "-",
};

export default speechParts;
