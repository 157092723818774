import {FC} from "react";

import styles from "./index.module.scss";
import Spinner from "components/Spinner";
import concatStyles from "helpers/concatStyles";
import colors from "styles/Color";

interface Props {
  text: string;
  loading?: boolean;
  error?: any;
  dark?: boolean;
  disabled?: boolean;
  className?: string;
}

const Button: FC<Props> = ({
  text,
  loading = false,
  error = false,
  dark = false,
  disabled = false,
  className,
}) => {
  const backgroundColor = dark ? colors.white : colors.primary;
  const color = dark ? colors.primary : colors.white;

  return loading ? (
    <div
      style={{backgroundColor, color}}
      className={concatStyles([styles.input, styles.loading, className])}>
      <Spinner />
    </div>
  ) : (
    <input
      disabled={disabled}
      type="submit"
      value={text}
      style={{backgroundColor, color}}
      className={concatStyles([
        styles.input,
        error ? styles.error : undefined,
        disabled ? styles.disabled : undefined,
        className,
      ])}
    />
  );
};

export default Button;
