import {useEffect} from "react";

function useKeyboardEvent(key: string, callback: any, dep?: any) {
  useEffect(() => {
    const handler = function (event: any) {
      if (event.key === key) {
        callback();
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [dep]);
}

export default useKeyboardEvent;
