import React, {useEffect, useRef} from "react";
import * as path from "svg-path-properties";

import useDrag from "hooks/useDrag";
import Handle from "components/Handle";
import useAnimation from "hooks/useAnimation";
import {extendSegment, pointsToBezierPath} from "helpers/svgUtils";
import styles from "./index.module.scss";

interface Props {
  strokes: string[];
  medians: number[][][];
  activeStroke: number;
  activePoint: number;
  size: number;
  onPointSelect: (id: number) => void;
  onPointDrag: (x: number, y: number) => void;
  onPointDragEnd: () => void;
}

const HanziCanva = React.forwardRef<any, Props>(
  (
    {
      strokes,
      medians,
      activeStroke,
      activePoint,
      size,
      onPointSelect,
      onPointDrag,
      onPointDragEnd,
    },
    ref,
  ) => {
    const {animation, trigger} = useAnimation(1000, true);

    useEffect(() => {
      trigger();
    }, []);

    const activeMedians = medians[activeStroke];

    const strokesPaths = strokes.map((d, i) => (
      <path
        className={`${styles.strokes} ${
          activeStroke === i ? styles.activeStroke : ""
        }`}
        d={d}
      />
    ));

    const svgLines = medians[activeStroke].reduce((acc, point, i): any[] => {
      if (i === 0) return acc;

      const [x1, y1] = medians[activeStroke][i - 1];
      const [x2, y2] = point;

      const svg = (
        <line
          markerEnd="url(#arrowhead)"
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          className={styles.line}
        />
      );

      return [...acc, svg];
    }, []);

    // move active stroke to top
    strokesPaths.unshift(strokesPaths.splice(activeStroke, 1)[0]);

    const onHandleDown = useDrag({
      onDrag: (x, y) => onPointDrag(x, y),
      onDragStart: () => {},
      onDragEnd: () => onPointDragEnd(),
    });

    const currentPath = pointsToBezierPath(
      extendSegment(medians[activeStroke], 110) || medians[activeStroke],
    );
    const pathLength = path.svgPathProperties(currentPath).getTotalLength();

    return (
      <div ref={ref} className={styles.container}>
        <div className={styles.hanzi}>
          <svg
            version="1.1"
            width={size}
            height={size}
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
              {strokes[activeStroke] && (
                <clipPath id={`stroke-active`}>
                  <path d={strokes[activeStroke]} />
                </clipPath>
              )}
              <marker
                id="arrowhead"
                markerWidth="8"
                markerHeight="8"
                refX="13"
                refY="4"
                orient="auto"
                fill="#808080">
                <polygon points="0 0, 10 3.5, 0 7" />
              </marker>
            </defs>
            {strokesPaths.reverse()}
            {medians[activeStroke] && (
              <path
                strokeDasharray={pathLength}
                strokeDashoffset={pathLength - animation * pathLength}
                clipPath={`url(#stroke-active)`}
                fill="none"
                className={styles.current}
                stroke="black"
                d={currentPath}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={100}
              />
            )}
            {svgLines}
            {activeMedians.map((point, i) => (
              <Handle
                key={`hanzi-editor-${i}`}
                id={i}
                active={activePoint == i}
                x={point[0]}
                y={point[1]}
                scale={1024 / size}
                onSelect={onPointSelect}
                onMouseDown={onHandleDown}
              />
            ))}
          </svg>
        </div>
      </div>
    );
  },
);

export default HanziCanva;
