import React, {ChangeEvent, FC, useEffect} from "react";

import styles from "./index.module.scss";
import {UseFormMethods} from "react-hook-form";
import {TextInput} from "components/Input";
import HanziViewer from "components/HanziViewer";
import stringToJson from "helpers/stringToJson";
import BrowserPanelHeader from "components/Browser/BrowserPanelHeader";
import FormRow from "components/FormRow";
import {UserPresence} from "state/presence";
import {IconButton} from "components/Button";
import concatStyles from "helpers/concatStyles";
import UploadInput from "components/Input/UploadInput";

export type FormInputs = {
  character: string;
  strokes: string;
  medians: string;
};

interface Props {
  data: any;
  role: string | null;
  loading?: boolean;
  isCreateMode?: boolean;
  presences: UserPresence[];
  form: UseFormMethods<FormInputs>;
  onUpdate: (form: FormInputs) => void;
  onSave: (form: FormInputs) => void;
  onApproval: (approved: boolean) => void;
  onModeClick: () => void;
  onUploadSvg: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const Panel: FC<Props> = ({
  data,
  role,
  loading = false,
  isCreateMode = false,
  presences,
  form,
  onSave,
  onUpdate,
  onApproval,
  onModeClick,
  onUploadSvg,
}) => {
  const {register, reset, handleSubmit, watch, formState, errors} = form;

  useEffect(() => {
    reset({
      character: data?.character || "",
      strokes: (data?.strokes !== null && JSON.stringify(data?.strokes)) || "",
      medians: (data?.medians !== null && JSON.stringify(data?.medians)) || "",
    });
  }, [data, reset]);

  const onSubmitHandler = (form: FormInputs) => {
    if (isCreateMode) {
      onSave(form);
    } else {
      onUpdate(form);
    }
  };

  const strokes = stringToJson(watch("strokes"));
  const medians = stringToJson(watch("medians")) || [];

  if (!data && !isCreateMode) {
    return <div className={styles.container}></div>;
  }

  const activePresences =
    (data && presences.filter((presence) => presence.item === data.objectId)) ||
    [];

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <BrowserPanelHeader
        data={data}
        role={role}
        presences={activePresences}
        onApproval={onApproval}
        loading={loading}
        buttonDisabled={formState.isDirty}
        isCreateMode={isCreateMode}
      />
      {strokes && (
        <HanziViewer data={{strokes, medians}} className={styles.viewer} />
      )}
      <FormRow label="Character">
        <TextInput
          className={styles.input}
          name="character"
          placeholder="Character"
          defaultValue=""
          error={errors.character?.message}
          ref={register({
            required: {value: true, message: "Required"},
            maxLength: {value: 1, message: "Only 1 character allowed."},
          })}
        />
      </FormRow>
      <FormRow label="Strokes">
        <div className={styles.editRow}>
          <TextInput
            className={concatStyles([styles.input, styles.editInput])}
            name="strokes"
            defaultValue=""
            placeholder="Strokes"
            error={errors.strokes?.message}
            ref={register({})}
          />
          <UploadInput
            className={styles.input}
            icon="add"
            onChange={onUploadSvg}
          />
        </div>
      </FormRow>
      <FormRow label="Medians">
        <div className={styles.editRow}>
          <TextInput
            className={concatStyles([styles.input, styles.editInput])}
            name="medians"
            defaultValue=""
            placeholder="Medians"
            error={errors.medians?.message}
            ref={register({})}
          />
          <IconButton
            icon="edit"
            onClick={(event: any) => {
              event.preventDefault();
              onModeClick();
            }}
          />
        </div>
      </FormRow>
    </form>
  );
};

export default Panel;
