import {useMemo} from "react";

import {useGetSentencesQuery} from "queries";

const useSentencePageData = (query: any) => {
  const {data, loading, fetchMore} = useGetSentencesQuery({
    variables: {
      ...query,
      cursor: null,
    },
  });

  const formattedData = useMemo(() => {
    if (!data)
      return {
        count: 0,
        hasNextPage: false,
        endCursor: null,
        currentUser: null,
        items: [],
      };

    const items =
      data?.sentences?.edges?.map(({node}: any) => {
        const vocabularies = node?.vocabularies?.edges?.map(({node}: any) => {
          const lists =
            node?.lists?.map((list: any) => ({
              objectId: list.objectId,
              name: list.name,
            })) || [];

          return {
            objectId: node.objectId,
            pinyin: node.pinyin.split(" "),
            english: node.english,
            hanzi: node.hanzi,
            lists,
          };
        });

        const words =
          node?.words?.map(({value}: any) => {
            return {
              hanzi: value.hanzi,
              pinyins: value.pinyins,
              vocabulary:
                vocabularies.filter(
                  (vocabulary: any) => vocabulary.objectId === value.vocabulary,
                )[0] || null,
            };
          }) || [];

        const chinese = words.map((word: any) => word.hanzi).join("");

        return {
          ...node,
          vocabularies: vocabularies.map((item: any) => item.objectId),
          words,
          chinese,
        };
      }) || [];

    return {
      count: data.sentences.count,
      hasNextPage: data.sentences.pageInfo.hasNextPage,
      endCursor: data.sentences.pageInfo.endCursor,
      items,
    };
  }, [data]);

  const loadMore = () => {
    return fetchMore({
      variables: {
        ...query,
        cursor: formattedData.endCursor,
      },
    });
  };

  return {data: formattedData, loading, loadMore};
};

export default useSentencePageData;
