import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import Icon, {Icons} from "components/Icon";
import colors from "styles/Color";

interface Props {
  icon: Icons;
  className?: string;
  active?: boolean;
  onClick: (event: any) => void;
}

const IconButton: FC<Props> = ({icon, className, active = false, onClick}) => {
  return (
    <button
      onClick={onClick}
      className={concatStyles([
        styles.button,
        active ? styles.active : undefined,
        className,
      ])}>
      <Icon
        name={icon}
        fill={active ? colors.primary : colors.mediumGrey}
        size={18}
      />
    </button>
  );
};

export default IconButton;
