import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";

interface Props {
  className?: string;
  progress: number;
}

const Logo: FC<Props> = ({className, progress}) => {
  const value = Math.min(Math.max(0, progress), 1);

  return (
    <div className={concatStyles([styles.track, className])}>
      <div style={{width: `${value * 100}%`}} className={styles.activeTrack} />
    </div>
  );
};

export default Logo;
