import {PayloadAction, SerializedError} from "@reduxjs/toolkit";

// reducer helpers
export const pendingReducer = (state: {
  loading: boolean;
  error: string | null;
}) => {
  state.loading = true;
  state.error = null;
};

export const fulfilledReducer = (state: {
  loading: boolean;
  error: string | null;
}) => {
  state.loading = false;
  state.error = null;
};

export const rejectedReducer = (
  state: {loading: boolean; error: string | null},
  action: PayloadAction<any, any, any, SerializedError>,
) => {
  state.loading = false;
  state.error = action.error.message!;
};
