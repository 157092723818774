import React, {FC} from "react";

import Spinner from "components/Spinner";
import styles from "./index.module.scss";

interface Props {
  count: number;
  loading: boolean;
}

const BrowserFooter: FC<Props> = ({count, loading}) => {
  return (
    <div className={styles.container}>
      <div>{loading && <Spinner size={20} />}</div>
      <span className={styles.count}>Count {count}</span>
    </div>
  );
};

export default BrowserFooter;
