import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import Icon from "components/Icon";
import colors from "styles/Color";

interface Props {
  className?: string;
  size?: number;
}

const Logo: FC<Props> = ({className, size = 64}) => {
  const width = size * 0.9 - ((size * 0.9) % 2);
  return (
    <div
      style={{width: size, height: size, borderRadius: size}}
      className={concatStyles([styles.container, className])}>
      <Icon name="logo" size={width} fill={colors.primary} />
    </div>
  );
};

export default Logo;
