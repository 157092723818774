import {useState} from "react";

const useSearch = () => {
  const [term, setTerm] = useState("");

  const onSearch = (value: string) => {
    setTerm(value);
  };

  return {search: term, onSearch};
};

export default useSearch;
