import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import colors from "styles/Color";
import Icon from "components/Icon";

interface Props {
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const SearchInput: FC<Props> = ({placeholder, value, className, onChange}) => {
  return (
    <div className={concatStyles([styles.container, className])}>
      <Icon
        className={styles.icon}
        name="search"
        size={20}
        fill={colors.border}
      />
      <input
        value={value}
        onChange={onChange}
        type="text"
        autoComplete="none"
        aria-label="Search"
        placeholder={placeholder}
        className={styles.input}
      />
    </div>
  );
};

export default SearchInput;
