import React, {FC} from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import Icon from "components/Icon";
import colors from "styles/Color";

interface Props {
  className?: string;
  size?: number;
  progress: number;
}

const ProgressBadge: FC<Props> = ({className, size = 24, progress}) => {
  if (progress === 0) {
    return (
      <div
        style={{
          width: size,
          height: size,
          borderRadius: size,
          backgroundColor: "#454545",
        }}
        className={concatStyles([styles.container, className])}
      />
    );
  } else if (progress >= 1) {
    return (
      <div
        style={{
          width: size,
          height: size,
          borderRadius: size,
          background: "rgba(88, 195, 34, 0.2)",
        }}
        className={concatStyles([styles.container, className])}>
        <Icon name="checkmark" size={size * 0.6} fill={colors.success} />
      </div>
    );
  }

  return (
    <div style={{width: size, height: size}} className={className}>
      <CircularProgressbar
        value={progress}
        minValue={0}
        maxValue={1}
        strokeWidth={16}
        styles={{
          path: {stroke: colors.primary},
          trail: {stroke: "#454545"},
        }}
      />
    </div>
  );
};

export default ProgressBadge;
