import {getTones} from "helpers/pinyin";
import Parse from "parse";

const exportVocabulary = async (
  exportVocabsMobileJsonFile: (getData: () => Promise<any>) => Promise<void>,
  exportListsJsonFile: (getData: () => Promise<any>) => Promise<void>,
) => {
  const List = Parse.Object.extend("List");
  const listResults = await new Parse.Query(List).find();

  const listsData = listResults.reduce(
    (acc, list) => {
      const listId = list.get("list");
      const sublist = list.get("sublist");
      const name = list.get("name");
      if (!acc.byId[listId]) {
        acc.byId[listId] = {};
      }
      acc.byId[listId][sublist] = {
        id: `${listId}-${sublist}`,
        name,
        count: 0,
        vocabIds: [],
      };
      return acc;
    },
    {
      order: {
        hsk: ["1", "2", "3", "4", "5", "6"],
        theme: ["food", "numbers"],
      },
      byId: {},
    } as any,
  );

  await exportVocabsMobileJsonFile(async () => {
    const Vocabulary = Parse.Object.extend("Vocabulary");
    const query = new Parse.Query(Vocabulary).limit(999999);
    const results = await query.find();

    const data = results.map((result) => {
      const id = result.id;
      const hanzi = result.get("hanzi");
      const pinyin = result.get("pinyin");
      const audio = result.get("audio");
      const tones = getTones(pinyin);
      const speechPart = result.get("speechPart");
      const lists = result
        .get("lists")
        .map((list: any) => `${list.get("list")}-${list.get("sublist")}`);

      const english = result
        .get("english")
        .split("; ")
        .map((part: string) => {
          return part.split(", ");
        });

      const definitions = speechPart.reduce(
        (acc: any[], part: string, i: number) => {
          if (!english[i]) return acc;
          acc = [
            ...acc,
            {
              speechPart: part,
              definitions: english[i],
            },
          ];
          return acc;
        },
        [],
      );

      return {
        id,
        hanzi,
        pinyin,
        audio,
        tones,
        speechPart,
        lists,
        definition: result.get("english"),
        frequency: result.get("frequency"),
        definitions,
      };
    });

    return data;
  });

  await exportListsJsonFile(() => listsData);
};

export default exportVocabulary;
