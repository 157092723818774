import React, {FC, memo, useEffect, useMemo, useState} from "react";
import * as path from "svg-path-properties";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import Icon from "components/Icon";
import colors from "styles/Color";
import Guide from "./Guide";
import {extendSegment, pointsToBezierPath} from "helpers/svgUtils";
import useAnimation from "../../hooks/useAnimation";

interface Props {
  className?: string;
  data: {
    strokes: any;
    medians: number[][][];
  };
}

const HanziViewer: FC<Props> = ({data, className}) => {
  const [currentStep, setCurentStep] = useState(1);
  const {animation, trigger} = useAnimation(1000);
  const strokesCount = data.strokes.length;

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    setCurentStep(1);
  }, [data]);

  const getStrokeDashOffset = (i: number, pathLength: number) => {
    if (i < currentStep - 1) {
      // done
      return 0;
    } else if (i > currentStep - 1) {
      // hidden
      return pathLength;
    } else {
      // active
      return pathLength - animation * pathLength;
    }
  };

  const onNextHandler = () => {
    trigger();
    if (currentStep !== strokesCount) {
      setCurentStep(currentStep + 1);
    } else {
      setCurentStep(1);
    }
  };

  const onPrevHandler = () => {
    if (currentStep > 1) {
      setCurentStep(currentStep - 1);
    } else {
      setCurentStep(strokesCount);
    }
  };

  const medians = useMemo(() => {
    return data.medians.map((median) => {
      const extendedMedian = extendSegment(median, 110) || median;
      const d = pointsToBezierPath(extendedMedian);
      const pathLength = path.svgPathProperties(d).getTotalLength();
      return {d, pathLength};
    });
  }, [data]);

  return (
    <div className={concatStyles([styles.container, className])}>
      <div className={styles.viewer}>
        <svg
          className={styles.svg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1024 1024">
          <g>
            {data.strokes.map((path: string, i: number) => (
              <path
                fill="#e9edf0"
                key={`$guide-${i}`}
                id={`guide-${i}`}
                d={path}
              />
            ))}
          </g>
          {medians.map((median, i) => {
            return (
              <path
                key={`median-${i}`}
                data-testid={`median-${i}`}
                strokeDasharray={median.pathLength}
                strokeDashoffset={getStrokeDashOffset(i, median.pathLength)}
                clipPath={`url(#stroke-${i})`}
                fill="none"
                className={
                  i === currentStep - 1 ? styles.current : styles.normal
                }
                stroke="black"
                d={median.d}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={100}
              />
            );
          })}
          <defs>
            {data.strokes.map((path: string, i: number) => (
              <clipPath key={`stroke-${i}`} id={`stroke-${i}`}>
                <path d={path} />
              </clipPath>
            ))}
          </defs>
        </svg>
        <Guide className={styles.guide} />
      </div>
      <div className={styles.controls}>
        <Icon
          onClick={() => onPrevHandler()}
          className={styles.stepper}
          name="chevronLeft"
          size={18}
          fill={colors.primary}
        />
        <span className={styles.steps}>
          {currentStep} / {strokesCount}
        </span>
        <Icon
          onClick={() => onNextHandler()}
          className={styles.stepper}
          name="chevronRight"
          size={18}
          fill={colors.primary}
        />
      </div>
    </div>
  );
};

const propsAreEqual = (prev: Props, next: Props) => {
  return (
    prev.data.strokes === next.data.strokes &&
    prev.data.medians === next.data.medians
  );
};

export default memo(HanziViewer, propsAreEqual);
