import React, {FC} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import {FixedSizeList as List} from "react-window";

import {BrowserRow, Columns} from "components/Browser";
import styles from "./index.module.scss";
import SkeletonRow from "../SkeletonRow";

interface Props {
  data: any[];
  columns: Columns;
  selectedRow?: number | null;
  count: number;
  hasNextPage: boolean;
  loading: boolean;
  onLoadMore: () => void;
  onRowClick: (index: number) => void;
}

const BrowserContent: FC<Props> = ({
  data,
  columns,
  selectedRow = null,
  hasNextPage,
  loading,
  onLoadMore,
  onRowClick,
}) => {
  const onLoadMoreHandler: any = loading ? () => {} : onLoadMore;

  const count = hasNextPage ? data.length + 1 : data.length;
  const isRowLoaded = (index: number) => !hasNextPage || index < data.length;

  return (
    <>
      <div className={styles.header}>
        {columns.map(({label, width}) => (
          <div key={label} style={{width: `${width * 100}%`}}>
            <span>{label}</span>
          </div>
        ))}
      </div>
      <div className={styles.content}>
        {data.length > 0 || !loading ? (
          <AutoSizer>
            {({height, width}) => (
              <InfiniteLoader
                isItemLoaded={isRowLoaded}
                itemCount={count}
                loadMoreItems={onLoadMoreHandler}>
                {({onItemsRendered, ref}) => (
                  <List
                    overscanCount={10}
                    height={height}
                    itemCount={count}
                    itemSize={32}
                    ref={ref}
                    onItemsRendered={onItemsRendered}
                    itemData={{
                      data,
                      columns,
                      selectedRow,
                      isRowLoaded,
                      onClick: onRowClick,
                    }}
                    width={width}>
                    {BrowserRow}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        ) : (
          <>
            <SkeletonRow columns={columns} style={{height: 32}} />
          </>
        )}
      </div>
    </>
  );
};

export default BrowserContent;
