import React, {FC, useState} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import {SearchInput} from "components/Input";
import {IconButton} from "components/Button";
import {BrowserFilter} from "components/Browser";
import {Filter} from "hooks/useFilter";
import Presence from "components/Presence";

interface Props {
  className?: string;
  role: string | null;
  search: string;
  title: string;
  filter: Filter;
  usersPresence?: {id: string; avatar: string; page: string; item: string}[];
  onSearch: (value: string) => void;
  onExportClick?: () => void;
  onCreateClick?: () => void;
}

const BrowserHeader: FC<Props> = ({
  className,
  search,
  role,
  title,
  filter,
  usersPresence,
  onSearch,
  onExportClick,
  onCreateClick,
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const onSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <div className={concatStyles([styles.container, className])}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>{title}</h1>
        {usersPresence && <Presence usersPresence={usersPresence} />}
      </div>
      <div className={styles.toolbar}>
        <SearchInput
          placeholder="Search"
          value={search}
          className={styles.search}
          onChange={onSearchHandler}
        />
        <IconButton
          active={isFilterVisible}
          className={styles.toolbarButton}
          onClick={() => setIsFilterVisible(!isFilterVisible)}
          icon="filter"
        />
        {role === "admin" && onExportClick && (
          <IconButton
            className={styles.toolbarButton}
            onClick={onExportClick}
            icon="download"
          />
        )}
        {onCreateClick && (
          <IconButton
            className={styles.toolbarButton}
            onClick={onCreateClick}
            icon="add"
          />
        )}
      </div>
      {isFilterVisible && <BrowserFilter filter={filter} />}
    </div>
  );
};

export default BrowserHeader;
