import React, {FC, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Mustache from "mustache";
import copy from "copy-to-clipboard";

import styles from "./index.module.scss";
import Layout from "components/Layout";
import {RootState} from "state";
import Icon from "components/Icon";
import colors from "styles/Color";
import {captionTemplate} from "./templates";
import {hashtags, baseHashtags} from "./hashtags";
import shuffle from "helpers/shuffle";
import concatStyles from "helpers/concatStyles";
import {useUpdateVocabularyMutation} from "queries";
import {publishInstagram} from "state/instagram";

const CAPTION_MAX = 2200;
const HASHTAG_MAX = 30;
const HASHTAGS_SELECTION = 15;

const Post: FC = () => {
  const vocab = useSelector((state: RootState) => state.instagram.vocab);
  const [currentVideo, setCurrentVideo] = useState(0);
  const dispatch = useDispatch();
  const [updateVocabulary] = useUpdateVocabularyMutation({});
  const urls = useSelector((state: RootState) => state.instagram.videoUrls);

  const tags = useMemo(() => {
    if (!vocab) return [];
    const selectedHashtags = shuffle(hashtags).slice(0, HASHTAGS_SELECTION);
    const tags = [
      ...baseHashtags,
      `#hsk${vocab.lists.hsk[0]}`,
      `#${vocab.hanzi}`,
      "#wordoftheday",
      ...selectedHashtags,
    ];
    return tags;
  }, [vocab]);

  const getDefinition = () => {
    if (!vocab) return [];
    const definitions = vocab.english.split("; ");
    return (
      vocab?.speechPart?.map((speechPart: string, i: number) => ({
        speechPart,
        definition: definitions[i],
      })) || []
    );
  };

  const getSentence = () => {
    if (!vocab || vocab?.sentences.length <= 0) return;
    const sentence = shuffle([...vocab.sentences])[0];
    return {
      pinyin: sentence.pinyin.join(" "),
      hanzi: sentence.hanzi.join(""),
      english: sentence.english,
    };
  };

  const onCopyHandler = () => {
    copy(caption);
  };

  const onCommandCopyHandler = () => {
    copy(
      'for i in *.webm; do ffmpeg -i "$i" -c:v copy -strict experimental "${i%.*}.mp4"; done',
    );
  };

  const onPublishClickHandler = () => {
    if (!vocab) return;
    const value = vocab.instagram || false;
    updateVocabulary({
      variables: {
        id: vocab.objectId,
        fields: {
          instagram: !value,
        },
      },
    });
    dispatch(publishInstagram(!value));
  };

  const caption = Mustache.render(captionTemplate, {
    vocab,
    hsk: vocab && vocab.lists.hsk[0],
    sentence: getSentence(),
    definitions: getDefinition(),
    hashtags: tags.join(" "),
  });

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTitleContainer}>
            <h1 className={styles.headerTitle}>Instagram</h1>
            <Icon name="chevronRight" size={18} fill={colors.mediumGrey} />
            <span className={styles.headerSubtitle}>Post</span>
          </div>
          <div className={styles.publishContainer}>
            <span className={styles.publishLabel}>Published</span>
            <div
              onClick={onPublishClickHandler}
              className={concatStyles([
                styles.doneContainer,
                vocab?.instagram ? styles.doneActive : undefined,
              ])}>
              <Icon
                className={styles.doneIcon}
                name="checkmark"
                size={18}
                fill={colors.black}
              />
            </div>
          </div>
        </div>
        <div className={styles.innerContainer}>
          <div className={styles.captionCol}>
            <div className={styles.panel}>
              <button
                onClick={onCopyHandler}
                className={concatStyles([styles.button, styles.copy])}>
                <Icon name="copy" fill={colors.primary} size={16} />
              </button>
              <p className={styles.caption}>
                <b>hanpathchinese</b> {caption}
              </p>
              <div className={styles.metadataContainer}>
                <span className={styles.metadata}>
                  {`Remaining: ${CAPTION_MAX - caption.length} characters ${
                    HASHTAG_MAX - tags.length
                  } hashtags`}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.mediasCol}>
            <div className={styles.panel}>
              <video
                className={styles.preview}
                src={urls && urls[currentVideo]}
                loop
                controls
                preload="auto"
                height="343"
                width="343"></video>
              <ul className={styles.mediaList}>
                {urls.map((url, i) => (
                  <li
                    onClick={() => setCurrentVideo(i)}
                    style={{
                      borderBottom:
                        i !== urls.length - 1
                          ? `1px solid ${colors.border}`
                          : "none",
                    }}
                    className={styles.mediaRow}>
                    <span
                      style={{
                        color:
                          i === currentVideo
                            ? colors.primary
                            : colors.mediumGrey,
                      }}
                      className={styles.mediaTitle}>
                      {i + 1}.{" "}
                      {i === 0
                        ? `Vocabulary - ${vocab && vocab.hanzi}`
                        : `Hanzi - ${vocab && vocab.hanzi.split("")[i - 1]}`}
                    </span>
                    <a
                      download={
                        vocab && `${vocab.hanzi}-${i + 1}-instagram.webm`
                      }
                      href={url}
                      className={styles.button}>
                      <Icon name="download" size={16} fill={colors.primary} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.command}>
              <span>Webm to MP4 Shortcut</span>
              <button
                onClick={onCommandCopyHandler}
                className={concatStyles([styles.button])}>
                <Icon name="copy" fill={colors.primary} size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Post;
