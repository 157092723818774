import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import getKeyValue from "helpers/getKeyValue";
import AudioButton from "components/AudioButton";
import getFrequency from "helpers/getFrequency";

const GRADIENTS = {
  fFDgWDUpn2: ["#61BAFF", "#9AF3FF"],
  rEdj165dJq: ["#40C1B6", "#9AFFA1"],
  UaYXHW8FHX: ["#B561FF", "#FF9AC2"],
  lf0eMZR0aL: ["#FFBC61", "#FFEE9A"],
  CnCXQbtvsZ: ["#A4B3C3", "#CBF1F6"],
  buDnVg0oKX: ["#FF8C6B", "#FFB872"],
  other: ["#6D7385", "#3A3F55"],
};

interface Props {
  className?: string;
  data: any;
}

const VocabularyPreview: FC<Props> = ({className, data}) => {
  const hskList =
    data?.lists !== "" &&
    data?.lists?.filter((list: any) => getKeyValue(GRADIENTS, list.value))[0];
  const gradient = (hskList && getKeyValue(GRADIENTS, hskList.value)) || [
    "#6D7385",
    "#3A3F55",
  ];

  const formattedEnglish =
    data?.english?.reduce((acc: any, row: any) => {
      const id = (row.speechPart && row.speechPart.value) || null;
      if (!id) return acc;
      acc[id] = acc[id] ? [...acc[id], row.definition] : [row.definition];
      return acc;
    }, {} as any) || {};

  const hanzi = data?.hanzis.map((row: any) => row.label).join("");
  const frequency = getFrequency(hanzi);

  return (
    <div className={concatStyles([styles.container, className])}>
      <div
        style={{
          backgroundImage: `linear-gradient(135deg, ${gradient[0]}, ${gradient[1]})`,
        }}
        className={styles.gradient}>
        <div className={styles.hanzis}>
          {data?.hanzis?.map((hanzi: any, i: number) => (
            <div className={styles.hanzi} key={hanzi.value}>
              <span className={styles.pinyin}>
                {data?.pinyin[i]?.val || ""}
              </span>
              <svg className={styles.hanziSvg} viewBox="0 0 1024 1024">
                {hanzi?.strokes?.map((d: string, j: number) => (
                  <path d={d} key={`${hanzi.value}-${j}`} />
                ))}
              </svg>
            </div>
          ))}
        </div>
        {data?.audio && (
          <AudioButton className={styles.audioButton} url={data.audio} />
        )}
      </div>
      <div>
        <span className={styles.definitionTitle}>Definitions</span>
        <div>
          {Object.entries(formattedEnglish).map((row: any) => (
            <div className={styles.defRow} key={row[0]}>
              <span className={styles.speechPart}>{row[0]}</span>
              <span className={styles.def}>{row[1].join(", ")}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        <span className={styles.definitionTitle}>Frequency</span>
        <span className={styles.frequency}>{frequency}</span>
      </div>
    </div>
  );
};

export default VocabularyPreview;
