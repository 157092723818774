import React, {FC} from "react";

import styles from "./index.module.scss";

interface Props {
  id: number;
  x: number;
  y: number;
  active: boolean;
  scale: number;
  onSelect: (id: number) => void;
  onMouseDown: (e: any) => void;
}

const Handle: FC<Props> = ({
  id,
  x,
  y,
  active = false,
  scale,
  onMouseDown,
  onSelect,
}) => {
  const onMouseDownHandler = (e: any) => {
    onMouseDown(e);
    onSelect(id);
  };
  return (
    <>
      {active && <circle cx={x} cy={y} r={50} className={styles.activeBg} />}
      <circle
        cx={x}
        cy={y}
        r={active ? 5 * scale : 4 * scale}
        className={`${styles.circle} ${active ? styles.active : ""}`}
        onMouseDown={onMouseDownHandler}
      />
    </>
  );
};

export default Handle;
