import React, {FC} from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";
import colors from "styles/Color";

interface Props {
  status: "approved" | "rejected" | "pending";
  className?: string;
}

const Status: FC<Props> = ({className, status}) => {
  let backgroundColor: string;

  switch (status) {
    case "approved":
      backgroundColor = colors.success;
      break;
    case "rejected":
      backgroundColor = colors.error;
      break;
    default:
      backgroundColor = "#B9C0C6";
      break;
  }
  return (
    <div
      style={{backgroundColor}}
      className={concatStyles([styles.container, className])}
    />
  );
};

export default Status;
