import React from "react";

import styles from "./index.module.scss";
import concatStyles from "helpers/concatStyles";

interface Props {
  placeholder: string;
  name: string;
  type?: string;
  className?: string;
  dark?: boolean;
  error?: string;
}

const TextInput = React.forwardRef<any, Props>(
  ({placeholder, type = "text", name, error = false, className}, ref) => (
    <div className={className}>
      <input
        ref={ref}
        autoComplete="none"
        type={type}
        aria-label={name}
        name={name}
        placeholder={placeholder}
        className={concatStyles([styles.input, error ? styles.error : ""])}
      />
      {error && <span className={styles.errorLabel}>{error}</span>}
    </div>
  ),
);

export default TextInput;
