import React, {FC} from "react";

interface Props {
  className?: string;
}

const Guide: FC<Props> = ({className}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 326 326"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g
        id="backgroundLines"
        stroke="n one"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeDasharray="5,6"
        strokeLinecap="round"
        strokeLinejoin="round">
        <line
          x1="163"
          y1="326"
          x2="163"
          y2="166"
          id="bottom"
          stroke="#EBEBEB"></line>
        <line
          x1="160"
          y1="163"
          x2="0"
          y2="163"
          id="left"
          stroke="#EBEBEB"></line>
        <line
          x1="163"
          y1="160"
          x2="163"
          y2="-2.85882429e-14"
          id="top"
          stroke="#EBEBEB"></line>
        <line
          x1="0"
          y1="0"
          x2="160"
          y2="160"
          id="topLeft"
          stroke="#EBEBEB"></line>
        <line
          x1="166"
          y1="166"
          x2="326"
          y2="326"
          id="bottomRight"
          stroke="#EBEBEB"></line>
        <line
          x1="0"
          y1="326"
          x2="160"
          y2="166"
          id="BottomLeft"
          stroke="#EBEBEB"></line>
        <line
          x1="326"
          y1="163"
          x2="166"
          y2="163"
          id="right"
          stroke="#EBEBEB"></line>
        <line
          x1="166"
          y1="160"
          x2="326"
          y2="0"
          id="topRight"
          stroke="#EBEBEB"></line>
      </g>
    </svg>
  );
};

export default Guide;
