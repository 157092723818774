import React, {FC, useEffect, useState} from "react";

import Logo from "components/Logo";
import styles from "./index.module.scss";
import ListStatus from "../ListStatus";
import {ListItem} from "..";

interface Props {
  list: ListItem[];
  onStartPress: () => void;
}

const Start: FC<Props> = ({list, onStartPress}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={styles.container} style={{opacity: isVisible ? 1 : 0}}>
      <Logo size={64} />
      <h1 className={styles.title}>Generate Instagram Videos</h1>
      <ListStatus list={list} />
      <button onClick={onStartPress} className={styles.button}>
        Start
      </button>
    </div>
  );
};

export default Start;
