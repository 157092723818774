export const colors = {
  white: "#FFF",
  black: "#000",
  mediumGrey: "#7A8086",
  primary: "#2EB1EC",
  primaryLight: "#E7F5FB",
  background: "#F5F5F5",
  border: "#E6E6E6",
  success: "#58C322",
  error: "#d9001a",
};

export default colors;
